import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Select, message, Form, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import palette from "../constants/pallete";

interface Item {
  id: number;
  name: string;
}

interface Field {
  name: string;
  label: string;
  required?: boolean;
}

interface CustomDropdownWithAddByIdProps {
  label: string;
  placeholder: string;
  fetchItems: (parentId?: number) => Promise<any>;
  addItem: (data: Record<string, any>) => Promise<void>;
  onSelect: (item: Item) => void;
  modalFields: Field[];
  formItemName: string;
  required?: boolean;
  form: any;
  parentId: number;
  value: any;
  showAddNew?: boolean;
}

const CustomDropdownWithAddById: React.FC<CustomDropdownWithAddByIdProps> = ({
  label,
  placeholder,
  fetchItems,
  addItem,
  onSelect,
  modalFields,
  formItemName,
  required = false,
  form,
  parentId,
  value,
  showAddNew = true,
}) => {
  const { t }: any = useTranslation();
  const [items, setItems] = useState<Item[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const fetchAndSetItems = async () => {
    setLoading(true);
    try {
      const response = await fetchItems(parentId);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        const transformedData = responseData.result.map((i: any) => ({
          id: i.id,
          name: i.name,
        }));
        setItems(transformedData);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(
        t("components.custom_dropdown_with_add_by_id.errors.fetch_failed"),
        error
      );
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = async () => {
    if (!parentId) {
      message.error(
        t("components.custom_dropdown_with_add_by_id.errors.parent_id_missing")
      );
      return;
    }

    const newErrors: Record<string, string> = {};
    modalFields.forEach((field) => {
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = t(
          "components.custom_dropdown_with_add_by_id.errors.field_required",
          { field: field.label }
        );
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const payload = { ...formData, categoryId: parentId };
      await addItem(payload);
      setFormData({});
      setErrors({});
      setShowModal(false);
      fetchAndSetItems();
    } catch (error: any) {
      message.error(
        error?.response?.data?.message ??
          t("components.custom_dropdown_with_add_by_id.errors.add_failed", {
            label,
          })
      );
      console.error("Error adding item:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFormData((prev) => ({ ...prev, [fieldName]: e.target.value }));
    setErrors((prev) => ({ ...prev, [fieldName]: "" }));
  };

  useEffect(() => {
    fetchAndSetItems();
  }, [parentId]);

  const handleSelect = (value: number) => {
    const selectedItem = items.find((item) => item.id === value);
    if (selectedItem) {
      onSelect(selectedItem);
      form.setFieldsValue({ [formItemName]: value });
    }
  };

  const onModalClose = () => {
    setShowModal(false);
    setErrors({});
  };

  return (
    <Form.Item
      label={label}
      name={formItemName}
      rules={[
        {
          required,
          message: t(
            "components.custom_dropdown_with_add_by_id.errors.field_required",
            { field: label }
          ),
        },
      ]}
      className="w-full"
    >
      <Select
        showSearch
        placeholder={placeholder}
        onSelect={handleSelect}
        value={value}
        onDropdownVisibleChange={(open) => open && fetchAndSetItems()}
        notFoundContent={
          loading ? (
            <Skeleton active />
          ) : (
            <div>
              {t("components.custom_dropdown_with_add_by_id.messages.no_data")}
            </div>
          )
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            {showAddNew ? (
              <div className="border-t p-2 text-center">
                <Button
                  type="link"
                  style={{ color: palette.secondary, fontWeight: "bold" }}
                  onClick={() => setShowModal(true)}
                >
                  {t(
                    "components.custom_dropdown_with_add_by_id.buttons.add_new",
                    { label }
                  )}
                </Button>
              </div>
            ) : null}
          </>
        )}
        style={{ width: "100%", height: "40px" }}
      >
        {items.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      <Modal
        title={t("components.custom_dropdown_with_add_by_id.modal.title", {
          label,
        })}
        open={showModal}
        onCancel={onModalClose}
        onOk={handleAddItem}
        onClose={onModalClose}
      >
        {modalFields.map((field) => (
          <div key={field.name} className="mb-2">
            <label className="block text-gray-700">{field.label}</label>
            <Input
              placeholder={t(
                "components.custom_dropdown_with_add_by_id.modal.placeholder",
                { field: field.label }
              )}
              value={formData[field.name] || ""}
              onChange={(e) => handleInputChange(e, field.name)}
              required={field.required}
            />
            {errors[field.name] && (
              <p className="text-red-500 text-xs mt-1">{errors[field.name]}</p>
            )}
          </div>
        ))}
      </Modal>
    </Form.Item>
  );
};

export default CustomDropdownWithAddById;
