import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
const trimUrl = ORDER_SERVICE_API.TRIM;

export const getTrimList = async () => {
  return orderApi.get(`${trimUrl}/list`);
};

export const getInternalTrimList = async () => {
  return orderApi.get(`${trimUrl}/internal`);
};

export const createTrim = async (payload: any) => {
  return orderApi.post(`${trimUrl}/create`, payload);
};

export const getTrimColorList = async () => {
  return orderApi.get(`${trimUrl}/color-list`);
};

export const createTrimColor = async (payload: {
  name: string;
  hexCode: string;
}) => {
  return orderApi.post(`${trimUrl}/color-create`, payload);
};

export const getTrimSizeList = async () => {
  return orderApi.get(`${trimUrl}/size-list`);
};
export const createTrimSize = async (payload: { name: string }) => {
  return orderApi.post(`${trimUrl}/size-create`, payload);
};
