import { CreatePaymentTermDto } from "../../types/paymentTerm";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
const paymentTermUrl = ORDER_SERVICE_API.PAYMENT_TERM;

export const getPaymentTerms = async () => {
  return orderApi.get(`${paymentTermUrl}`);
};

export const createPaymentTerm = async (payload: CreatePaymentTermDto) => {
  return orderApi.post(`${paymentTermUrl}`, payload);
};

export const bulkUploadPaymentTermes = async (
  paymentTerms: CreatePaymentTermDto[]
) => {
  return orderApi.post(`${paymentTermUrl}/bulk`, paymentTerms);
};

export const getInternalPaymentTerms = async () => {
  return orderApi.get(`${paymentTermUrl}/internal`);
};
