import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { configureStore } from "@reduxjs/toolkit";
import bomPendingOrdersSlice from "./groyyo_plus/bom/bomPendingOrdersSlice";
import createBomSlice from "./groyyo_plus/bom/createBomSlice";
import dispatchSlice from "./groyyo_plus/dispatch/dispatchSlice";
import createSalesOrderSlice from "./groyyo_plus/order/createSalesOrderSlice";
import singleSalesOrderSlice from "./groyyo_plus/order/singleSalesOrderSlice";
import tnaTemplateSlice from "./groyyo_plus/tna/tnaTemplateSlice";
import userPermissionSlice from "./User/PermissionSlice";
import internalMerchandiserSalesOrderSlice from "./groyyo_merchendaiser/internalMerchandiserSalesOrderSlice";
import createInternalBOMSlice from "./groyyo_merchendaiser/createInternalBOMSlice";
import internalSingleSalesOrderSlice from "./groyyo_merchendaiser/internalSingleSalesOrderSlice";

export const store = configureStore({
  reducer: {
    createSalesOrder: createSalesOrderSlice,
    singleSalesOrder: singleSalesOrderSlice,
    bomPendingOrders: bomPendingOrdersSlice,
    createBom: createBomSlice,
    dispatch: dispatchSlice,
    tnaTemplate: tnaTemplateSlice,
    userPermissions: userPermissionSlice,
    internalMerchandiserSalesOrderSlice: internalMerchandiserSalesOrderSlice,
    createInternalBOM: createInternalBOMSlice,
    internalSingleSalesOrder: internalSingleSalesOrderSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
