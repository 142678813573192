import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CreateSalesOrderTabEnum } from "../../constants/enums";
import { Item } from "../../types/groyyo_plus/salesOrderItem";
import { BillingFields } from "../groyyo_plus/order/createSalesOrderSlice";

export interface InternalOrderDetail {
    buyer: { id: number; name: string } | undefined;
    reference: string;
    expectedMargin: number;
    merchandiser: { id: number; name: string } | undefined;
    approver: { id: number; name: string } | undefined;
    soDate: string;
    expectedDate: string;
    paymentTerm: { id: number; name: string } | undefined;
    deliveryType: { id: number; name: string } | undefined;
    productType: { id: number; name: string } | undefined;
    currency: { id: number; name: string } | undefined;
    groyyoUniqueId: string;
    codeAsPerZoho: string;
    buyerPoNumber: string;
}

interface IInternalMerchandiserSalesOrderSlice {
    orderDetail: InternalOrderDetail;
    activeSalesOrderTab: CreateSalesOrderTabEnum;
    submissionStatus: 'idle' | 'loading' | 'success' | 'error';
    itemDetail: {
        itemListDetail: {
            itemList: Item[],
        },
        item: {
            unit: { id: number; name: string } | undefined;
            category: { id: number; name: string } | undefined;
            subCategory: { id: number; name: string } | undefined;
            name: string;
            hsnCode: string;
            imageUrl: string | null;
        }
    };
    billingDetail: BillingFields & {
        attachements: Array<{
            url: string
            name: string
            mime: string
        }>
    };
}

const initialState: IInternalMerchandiserSalesOrderSlice = {
    activeSalesOrderTab: CreateSalesOrderTabEnum.ORDER_DETAILS,
    orderDetail: {
        buyer: undefined,
        reference: "",
        expectedMargin: 0,
        merchandiser: undefined,
        approver: undefined,
        soDate: "",
        expectedDate: "",
        paymentTerm: undefined,
        deliveryType: undefined,
        productType: undefined,
        currency: undefined,
        groyyoUniqueId: "",
        codeAsPerZoho: "",
        buyerPoNumber: "",
    },
    itemDetail: {
        itemListDetail: {
            itemList: [],
        },
        item: {
            unit: undefined,
            category: undefined,
            subCategory: undefined,
            name: "",
            hsnCode: "",
            imageUrl: null,
        }
    },
    billingDetail: {
        subTotal: 0,
        discount: 0,
        shipmentCharges: 0,
        total: 0,
        customerNote: "",
        termsAndCondition: "",
        attachements: []
    },
    submissionStatus: 'idle'
};

const internalMerchandiserSalesOrderSlice = createSlice({
    name: "internalMerchandiserSalesOrder",
    initialState,
    reducers: {
        setActiveSalesOrderTab: (state, action: PayloadAction<CreateSalesOrderTabEnum>) => {
            state.activeSalesOrderTab = action.payload;
        },
        setBuyer: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.buyer = action.payload;
        },
        setReference: (state, action: PayloadAction<string>) => {
            state.orderDetail.reference = action.payload;
        },
        setExpectedMargin: (state, action: PayloadAction<number>) => {
            state.orderDetail.expectedMargin = action.payload;
        },
        setMerchandiser: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.merchandiser = action.payload;
        },
        setApprover: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.approver = action.payload;
        },
        setSoDate: (state, action: PayloadAction<string>) => {
            state.orderDetail.soDate = action.payload;
        },
        setExpectedDate: (state, action: PayloadAction<string>) => {
            state.orderDetail.expectedDate = action.payload;
        },
        setPaymentTerm: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.paymentTerm = action.payload;
        },
        setDeliveryType: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.deliveryType = action.payload;
        },
        setProductType: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.productType = action.payload;
        },
        setCurrency: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.currency = action.payload;
        },
        setGroyyoUniqueId: (state, action: PayloadAction<string>) => {
            state.orderDetail.groyyoUniqueId = action.payload;
        },
        setCodeAsPerZoho: (state, action: PayloadAction<string>) => {
            state.orderDetail.codeAsPerZoho = action.payload;
        },
        setBuyerPoNumber: (state, action: PayloadAction<string>) => {
            state.orderDetail.buyerPoNumber = action.payload;
        },
        resetSubmissionStatus: (state) => {
            state.orderDetail = initialState.orderDetail;
        },
        setBillingFields: (state, action: PayloadAction<{ value: BillingFields[keyof BillingFields]; field: keyof BillingFields }>) => {
            const { field, value } = action.payload;
            state.billingDetail[field] = value as never;

            const { subTotal, discount, shipmentCharges } = state.billingDetail;
            const discountAmount = (subTotal || 0) * ((discount || 0) / 100);
            state.billingDetail.total = (subTotal || 0) - discountAmount + (shipmentCharges || 0);
        },
        setBillingAttachments: (state, action: PayloadAction<Array<{ url: string; name: string; mime: string }>>) => {
            state.billingDetail.attachements = action.payload;
        },
        clearBillingDetails: (state) => {
            state.billingDetail = initialState.billingDetail;
        },
        setItemWithId: (state, action: PayloadAction<{ id: number; name: string; field: "unit" | "category" | "subCategory" }>) => {
            const { id, name, field } = action.payload;
            state.itemDetail.item[field] = { id, name };
        },
        setItem: (state, action: PayloadAction<{ name: string; field: "name" | "hsnCode" | "imageUrl" }>) => {
            const { name, field } = action.payload;
            state.itemDetail.item[field] = name;
        },
        resetItem: (state) => {
            state.itemDetail.item = initialState.itemDetail.item
        },
        setItemList: (state, action: PayloadAction<Item>) => {
            state.itemDetail.itemListDetail.itemList.push(action.payload)
        },
        clearItemList: (state) => {
            state.itemDetail.itemListDetail = initialState.itemDetail.itemListDetail
        },
        setItemListDetail: (
            state,
            action: PayloadAction<{ itemId: number; field: "quantity" | "rate" | "tax"; value: any }>
        ) => {
            const { itemId, field, value } = action.payload;

            const itemIndex = state.itemDetail.itemListDetail.itemList.findIndex((i) => i.id === itemId);

            if (itemIndex !== -1) {
                if (field === "tax" && value.id && value.name) {
                    state.itemDetail.itemListDetail.itemList[itemIndex].tax = {
                        id: value.id,
                        name: value.name,
                        percentage: value.percentage
                    };
                } else {
                    state.itemDetail.itemListDetail.itemList[itemIndex][field] = value;
                }

                const item = state.itemDetail.itemListDetail.itemList[itemIndex];
                const quantity = item.quantity || 0;
                const rate = item.rate || 0;
                const baseTotal = quantity * rate;

                const taxPercentage = item.tax?.percentage || 0;
                item.total = baseTotal + (baseTotal * (taxPercentage / 100));
            }
        },
    }
});

export const {
    setActiveSalesOrderTab,
    setBuyer,
    setReference,
    setExpectedMargin,
    setMerchandiser,
    setApprover,
    setSoDate,
    setExpectedDate,
    setPaymentTerm,
    setDeliveryType,
    setProductType,
    setCurrency,
    setGroyyoUniqueId,
    setCodeAsPerZoho,
    setBuyerPoNumber,
    resetSubmissionStatus,
    setBillingAttachments,
    setBillingFields,
    clearBillingDetails,
    setItem,
    setItemList,
    setItemListDetail,
    setItemWithId,
    resetItem,
    clearItemList
} = internalMerchandiserSalesOrderSlice.actions;

export default internalMerchandiserSalesOrderSlice.reducer;