import { CreateBuyerDto } from "../../types/buyers";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
const customerUrl = ORDER_SERVICE_API.BUYER;
export const getCustomers = async () => {
  return orderApi.get(`${customerUrl}`);
};

export const createCustomer = async (payload: CreateBuyerDto) => {
  return orderApi.post(`${customerUrl}`, payload);
};

export const bulkUploadCustomers = async (customers: CreateBuyerDto[]) => {
  return orderApi.post(`${customerUrl}/bulk`, customers);
};

export const getInternalCustomers = async () => {
  return orderApi.get(`${customerUrl}/internal`);
};
