import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISingleSalesOrderResponse } from "../../types/groyyo_plus/salesOrder";


interface IInternalSingleSalesOrderResponse extends ISingleSalesOrderResponse {
    internalNotes?: string;
    internalApprover?: { id: number; name: string } | null;
}

const initialState: IInternalSingleSalesOrderResponse | null = {} as IInternalSingleSalesOrderResponse | null;


const internalSingleSalesOrderSlice = createSlice({
    name: "internalSingleSalesOrder",
    initialState,
    reducers: {

        setInternalSingleSalesOrder: (state, action: PayloadAction<IInternalSingleSalesOrderResponse>) => {
            return action.payload;
        },


        clearInternalSingleSalesOrder: () => {
            return null;
        },


        setSoDateForOrderDetail: (state, action: PayloadAction<string>) => {
            if (state) {
                state.soDate = action.payload;
            }
        },

        setExpectedDateForOrderDetail: (state, action: PayloadAction<string>) => {
            if (state) {
                state.expectedDate = action.payload;
            }
        },

        setReferenceForOrderDetail: (state, action: PayloadAction<string>) => {
            if (state) {
                state.reference = action.payload;
            }
        },

        setExpectedMarginForOrderDetail: (state, action: PayloadAction<number>) => {
            if (state) {
                state.expectedMargin = action.payload;
            }
        },


        setInternalNotes: (state, action: PayloadAction<string>) => {
            if (state) {
                state.internalNotes = action.payload;
            }
        },

        setInternalApprover: (state, action: PayloadAction<{ id: number; name: string }>) => {
            if (state) {
                state.internalApprover = action.payload;
            }
        },

        clearInternalApprover: (state) => {
            if (state) {
                state.internalApprover = null;
            }
        },
    }
});


export const {
    setInternalSingleSalesOrder,
    clearInternalSingleSalesOrder,
    setSoDateForOrderDetail,
    setExpectedDateForOrderDetail,
    setReferenceForOrderDetail,
    setExpectedMarginForOrderDetail,
    setInternalNotes,
    setInternalApprover,
    clearInternalApprover,
} = internalSingleSalesOrderSlice.actions;


export default internalSingleSalesOrderSlice.reducer;