import React from "react";
import { Layout, Menu, Button } from "antd";
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoneyCollectOutlined,
  ProductOutlined,
  BranchesOutlined,
  CustomerServiceOutlined,
  AppstoreOutlined,
  TableOutlined,
  TruckOutlined,
  BankOutlined,
  ReconciliationOutlined,
  ProfileOutlined,
  HomeOutlined,
  OrderedListOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  CarryOutOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import palette from "../constants/pallete";
import "./Sidebar.css";
import { useTranslation } from "react-i18next";
import useRolePermission from "../utilities/RolePermission/RolePermissionHook";
import { PERMISSION_MODULE } from "../utilities/helper";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SidebarProps {
  selectedModule: number;
  collapsed: boolean;
  onToggle: () => void;
  headerHeight: string;
}

interface iRouteDetail {
  key: string;
  label: string;
  icon?: JSX.Element;
  path?: string;
}

interface iSideBarRoutes extends iRouteDetail {
  children?: iRouteDetail[];
}

const Sidebar: React.FC<SidebarProps> = ({
  selectedModule,
  collapsed,
  onToggle,
  headerHeight,
}) => {
  const location = useLocation();
  const { t }: any = useTranslation();
  const showSalesOrder = useRolePermission("SALES_ORDERS", "VIEW");
  const showBOM = useRolePermission("BOM", "VIEW");
  const showMaterialReceivable = useRolePermission(
    "MATERIAL_RECEIVABLE",
    "VIEW"
  );
  const showMaterialIssued = useRolePermission("MATERIAL_ISSUE", "VIEW");
  const showMaterialTracking = useRolePermission("MATERIAL_TRACKING", "VIEW");
  const showWip = useRolePermission("WIP", "VIEW");
  const showDispatch = useRolePermission("DISPATCH", "VIEW");
  const showUser = useRolePermission("USER_MANAGEMENT", "VIEW");

  const getSourcingRoutes = () => {
    const routes: iSideBarRoutes[] = [
      {
        key: `/sourcing/dashboard`,
        label: t("sidebar.module2.home"),
        icon: <HomeOutlined />,
      },
    ];
    // check for order and bom view
    if (showSalesOrder || showBOM) {
      const children = [];
      if (showSalesOrder)
        children.push({
          key: `/sourcing/sales-order`,
          label: t("sidebar.module2.sales_order"),
          path: `/sourcing/sales-order`,
        });
      if (showBOM)
        children.push({
          key: `/sourcing/bom`,
          label: t("sidebar.module2.bom"),
          path: `/sourcing/bom`,
        });
      routes?.push({
        key: "order",
        label: t("sidebar.module2.order"),
        icon: <OrderedListOutlined />,
        children,
      });
    }

    //material section
    if (showMaterialReceivable || showMaterialIssued || showMaterialTracking) {
      const children = [];
      if (showMaterialReceivable)
        children.push({
          key: `/sourcing/material-receive`,
          label: t("sidebar.module2.receivable"),
          path: `/sourcing/material-receive`,
        });

      if (showMaterialIssued)
        children?.push({
          key: `/sourcing/material-issued`,
          label: t("sidebar.module2.issue"),
          path: `/sourcing/material-issued`,
        });

      if (showMaterialTracking)
        children?.push({
          key: `/sourcing/material-tracking`,
          label: t("sidebar.module2.material_tracking"),
          path: `/sourcing/material-tracking`,
        });

      routes?.push({
        key: "material",
        label: t("sidebar.module2.material"),
        icon: <FileDoneOutlined />,
        children,
      });
    }

    //production section
    if (showWip) {
      routes?.push({
        key: "production",
        label: t("sidebar.module2.production"),
        icon: <ContainerOutlined />,
        children: [
          {
            key: `/sourcing/production/wip`,
            label: t("sidebar.module2.wip"),
            path: `/sourcing/production/wip`,
          },
        ],
      });
    }
    //pushing TNA as there no permission for this
    routes?.push({
      key: `/tna`,
      label: t("sidebar.module2.tna"),
      icon: <CarryOutOutlined />,
      path: `/tna`,
    });

    if (showDispatch) {
      routes?.push({
        key: `/dispatch`,
        label: t("sidebar.module2.dispatch"),
        icon: <GlobalOutlined />,
        path: `/dispatch`,
      });

      if (showUser) {
        routes?.push({
          key: `/sourcing/users`,
          label: t("sidebar.module2.users"),
          icon: <UserOutlined />,
          path: `/sourcing/users`,
        });
      }
    }

    return routes;
  };

  const getInternalMerchandiserRoutes = (): iSideBarRoutes[] => {
    const routes: iSideBarRoutes[] = [];
    const children: iSideBarRoutes[] = [];
  
    children.push({
      key: `/internal/sales-order`,
      label: t('sidebar.module2.sales_order'),
      path: `/internal/sales-order`,
    });
  
    children.push({
      key: `/internal/bom`,
      label: t('sidebar.module2.bom'),
      path: `/internal/bom`,
    });
  
    routes.push({
      key: 'order',
      label: t('sidebar.module2.order'),
      icon: <OrderedListOutlined />,
      children,
    });
  
    return routes;
  };

  const getMenuItems = (): iSideBarRoutes[] => {
    if (selectedModule === 1) {
      return [
        {
          key: `/onBoarding/users`,
          label: t("sidebar.module1.go_to_users"),
          icon: <UserOutlined />,
        },
        {
          key: `/onBoarding/branches`,
          label: t("sidebar.module1.branches"),
          icon: <BranchesOutlined />,
        },
        {
          key: `/onBoarding/product`,
          label: t("sidebar.module1.product_type"),
          icon: <ProductOutlined />,
        },
        {
          key: `/onBoarding/delivery`,
          label: t("sidebar.module1.delivery_type"),
          icon: <TruckOutlined />,
        },
        {
          key: `/onBoarding/payment`,
          label: t("sidebar.module1.payment_term"),
          icon: <BankOutlined />,
        },
        {
          key: `/onBoarding/customers`,
          label: t("sidebar.module1.customers"),
          icon: <CustomerServiceOutlined />,
        },
        {
          key: `/onBoarding/categories`,
          label: t("sidebar.module1.categories"),
          icon: <AppstoreOutlined />,
        },
        {
          key: `/onBoarding/sub-categories`,
          label: t("sidebar.module1.subcategories"),
          icon: <TableOutlined />,
        },
        {
          key: `/onBoarding/job-work`,
          label: t("sidebar.module1.job_work_supplier"),
          icon: <ReconciliationOutlined />,
        },
        {
          key: `/onBoarding/taxes`,
          label: t("sidebar.module1.taxes"),
          icon: <MoneyCollectOutlined />,
        },
      ];
    } else if (selectedModule === 2) {
      return getSourcingRoutes();
    } else if (selectedModule === 3) {
      return getInternalMerchandiserRoutes();
    }
    return [];
  };

  const getDefaultOpenKeys = () => {
    const items = getMenuItems();
    const openKeys: string[] = [];

    items.forEach((item) => {
      if (item.children) {
        const match = item.children.find((subItem: any) =>
          location.pathname.startsWith(subItem.key)
        );
        if (match) {
          openKeys.push(item.key);
        }
      }
    });

    return openKeys;
  };

  const menuItems: iSideBarRoutes[] = getMenuItems();
  const defaultOpenKeys = getDefaultOpenKeys();

  const renderMenuItems = (items: any) => {
    return items.map((item: any) => {
      const isParentActive = item.children?.some((subItem: any) =>
        location.pathname.startsWith(subItem.key)
      );

      if (item.children) {
        return (
          <SubMenu
            key={item.key}
            icon={
              <span
                style={{
                  color: isParentActive ? "#CC6A4C" : undefined,
                }}
              >
                {item.icon}
              </span>
            }
            title={
              <span
                style={{
                  color: isParentActive ? "#CC6A4C" : undefined,
                }}
              >
                {item.label}
              </span>
            }
          >
            {item.children.map((subItem: any) => (
              <Menu.Item
                key={subItem.key}
                className={`rounded-lg ${
                  location.pathname.startsWith(subItem.key)
                    ? "menuItemSelected"
                    : ""
                }`}
              >
                <Link to={subItem.path || subItem.key}>{subItem.label}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          key={item.key}
          className={`rounded-lg ${
            location.pathname === item.key ? "menuItemSelected" : ""
          }`}
          icon={item.icon}
        >
          <Link to={item.path || item.key}>{item.label}</Link>
        </Menu.Item>
      );
    });
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="sidebar"
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: palette.white,
        paddingTop: headerHeight,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={defaultOpenKeys}
        className={`menu`}
      >
        {renderMenuItems(menuItems)}
      </Menu>
      <div className="flex justify-center items-center">
        <Button
          type="text"
          className="text-white my-1"
          onClick={onToggle}
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
