import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { resetTemplateState, TNAProcess, updateProcess } from '../../../redux/groyyo_plus/tna/tnaTemplateSlice';
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
    KeyboardSensor,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GrDrag } from "react-icons/gr";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { createTna } from '../../../services/orderServiceApi/tnaApi';
import { TNACreationRequestBody } from '../../../types/groyyo_plus/tna';
import { useNavigate, useParams } from 'react-router-dom';
import SortableItem from '../../../components/SortableItem';

interface RearrangeProcessesModalProps {
    isOpen: boolean;
    processes: TNAProcess[];
    onClose: () => void;
    dragProcesses: TNAProcess[]
    setDragProcesses: React.Dispatch<React.SetStateAction<TNAProcess[]>>
}


const RearrangeProcessesModal: React.FC<RearrangeProcessesModalProps> = ({
    isOpen,
    processes,
    onClose,
    dragProcesses, 
    setDragProcesses
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { salesOrderItemId } = useParams();

    useEffect(() => {
        setDragProcesses([...processes]);
    }, []);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = ({ active, over }: any) => {
        if (active.id !== over.id) {
            const oldIndex = dragProcesses.findIndex((process) => String(process.id) === active.id);
            const newIndex = dragProcesses.findIndex((process) => String(process.id) === over.id);

            const updatedProcesses = arrayMove(dragProcesses, oldIndex, newIndex).map(
                (process, index) => ({
                    ...process,
                    sequence: index + 1,
                })
            );
            setDragProcesses(updatedProcesses);
        }
    };

    const handleSave = async () => {
        dragProcesses.forEach((process) => {
            dispatch(updateProcess({ id: process.id, data: { sequence: process.sequence } }));
        });
        try {
            const payload:TNACreationRequestBody[] = processes?.map((process) => {
                return {
                    processId:process?.process?.id,
                    dependentProcessId: process?.dependencyOn?.id || null,
                    plannedDate: moment(process?.planDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    sequence: process?.sequence,
                    isManualEntry: process?.isManualEntry,

                }
            })

            const resposne = await createTna({salesOrderItemId: +salesOrderItemId!, tnaProcessMapping:payload});
            const responseData = resposne?.data;

            if(responseData?.status){
                message.success('Process sequence updated successfully!');
                onClose();
                navigate(`/tna`);
                dispatch(resetTemplateState());
                setDragProcesses([])
            }
        } catch (error) {
            console.error(error);
        }
       
    };

    return (
        <Modal
            title="Rearrange Processes"
            open={isOpen}
            onCancel={onClose}
            onOk={handleSave}
            okText="Save"
        >
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={dragProcesses.map((process) => String(process.id))}
                    strategy={verticalListSortingStrategy}
                >
                    <div>
                        {dragProcesses.map((process, index) => (
                            <SortableItem 
                            key={process.id}
                            process={process}
                            index={index}
                            getProcessName={(proc) => proc.process?.name || 'Untitled Process'}
                            getDependencyName={(proc) => proc.dependencyOn?.name || 'None'}
                            getAdditionalDetails={(proc) => (
                                <p>
                                    <strong>Planned Date:</strong> {proc.planDate || 'Not Set'}
                                </p>
                            )}
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
        </Modal>
    );
};

export default RearrangeProcessesModal;