import React, { useCallback, useEffect, useState } from "react";
import { Button, message, Skeleton } from "antd";
import {
  IBomItemForm,
  IBomPoDetail,
  ICreateBomRequestBody,
} from "../../../../types/groyyo_plus/bomItem";
import BOMListItem from "./BOMListItem";
import {
  calculateIndividualBomTotal,
  formatDate,
} from "../../../../utilities/helper";
import {
  createBom,
  getBomDetail,
} from "../../../../services/orderServiceApi/bomApi";
import { sourcingRoute } from "../../../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generateDocDefinition } from "../../../../utilities/generateBomHTML";
import RolePermission from "../../../../utilities/RolePermission/RolePermission";
import { useTranslation } from "react-i18next";
import palette from "../../../../constants/pallete";
import { clearSelectedBom } from "../../../../redux/groyyo_plus/bom/createBomSlice";

// @ts-ignore
pdfMake.vfs = pdfFonts?.pdfMake?.vfs;

interface BomListProps {
  bomItems: IBomItemForm[];
  setBomItems: React.Dispatch<React.SetStateAction<Array<IBomItemForm>>>;
  loading: boolean;
}

const BomList: React.FC<BomListProps> = ({
  bomItems,
  setBomItems,
  loading,
}) => {
  const { salesOrderId } = useParams();

  const { selectedBom } = useSelector((state: RootState) => state.createBom);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t }: any = useTranslation();

  const handleFieldValidation = (
    field: string,
    value: any,
    bomItemId: number | string
  ) => {
    setBomItems((prevForms) =>
      prevForms.map((item) =>
        item.id === bomItemId
          ? {
              ...item,
              error: {
                ...item.error,
                [field]:
                  !value ||
                  ((field === "unit" ||
                    field === "currency" ||
                    field === "supplier") &&
                    value?.id === -1),
              },
            }
          : item
      )
    );
  };

  const onValuesChange = (changedValues: any, bomItemId: number | string) => {
    const [field, value] = Object.entries(changedValues)[0];
    setBomItems((prevForms) =>
      prevForms.map((data) => {
        if (data.id === bomItemId) {
          const updatedData = { ...data, [field]: value };
          return {
            ...updatedData,
            total: calculateIndividualBomTotal(updatedData),
          };
        }
        return data;
      })
    );

    handleFieldValidation(field, value, bomItemId);
  };

  const validateForms = async () => {
    let isValid = true;
    setBomItems((prev) =>
      prev.map((data) => {
        const newErrors = {
          quantity: !data?.quantity,
          unit: !data?.unit?.name,
          supplier: !data?.supplier?.name,
          extra: !data?.extra,
          average: !data?.average,
          currency: !data?.currency.name,
          rate: !data?.rate,
          inHouseDate: !data?.inHouseDate,
        };

        isValid = isValid && !Object.values(newErrors).some((error) => error);

        return { ...data, error: newErrors };
      })
    );
    return isValid;
  };

  const handleBomItemSubmit = async () => {
    const isValid = await validateForms();
    if (!isValid) {
      message.error(t("sales_order_bom.bom_list.validation_failed"));
      return;
    }
    let combinedTotal = 0;
    const bomItemCollection = bomItems?.map((data) => {
      combinedTotal += +data?.total || 0;
      return {
        id: typeof data?.id === "string" ? undefined : data?.id,
        supplierId: data?.supplier?.id,
        average: +data?.average,
        unitId: data?.unit?.id,
        materialId: data?.material?.id,
        quantity: +data?.quantity,
        rate: +data?.rate,
        extra: +data?.extra,
        total: +data?.total || 0,
        inHouseDate: formatDate(data?.inHouseDate as any),
        currencyId: data?.currency?.id,
      };
    });

    const payload: ICreateBomRequestBody = {
      bomItems: bomItemCollection,
      bomId: selectedBom?.bom?.id,
      salesOrderItemIds: [selectedBom?.id!],
      total: combinedTotal,
    };

    try {
      const response = await createBom(payload);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(t("sales_order_bom.bom_list.submission_success"));
        dispatch(clearSelectedBom());
        setBomItems([]);
        navigate(`${sourcingRoute}/bom/create-bom/${salesOrderId}`);
      }
    } catch (error) {
      console.error(t("sales_order_bom.bom_list.submission_error"), error);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        Array.from({ length: 2 })?.map((_, index) => (
          <Skeleton.Input
            key={index}
            active
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50vh",
            }}
          />
        ))
      ) : (
        <>
          {bomItems.map((bomItem, index) => {
            const isDisabled = Boolean(bomItem?.materialReceivable);
            const bomItemErrors = bomItem.error || {};

            return (
              <BOMListItem
                key={bomItem?.id}
                bomItemForm={bomItem}
                isDisabled={isDisabled}
                onValuesChange={(changedValues) =>
                  onValuesChange(changedValues, bomItem.id)
                }
                errors={bomItemErrors}
              />
            );
          })}
          {bomItems.length > 0 && (
            <>
              <RolePermission action="EDIT" module="BOM">
                <Button
                  type="primary"
                  className="w-full mt-4"
                  onClick={handleBomItemSubmit}
                  style={{
                    backgroundColor: palette.secondary,
                    borderColor: palette.secondary,
                  }}
                >
                  {t("sales_order_bom.bom_list.submit_button")}
                </Button>
              </RolePermission>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BomList;
