import { Button, ConfigProvider, Dropdown, MenuProps, Space, Tabs } from "antd";
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getCurrentLocale } from "../../../../utilities/language";
import i18n from "../../../../internalization/i18n";
import palette from "../../../../constants/pallete";
import {
  CreateSalesOrderTabEnum,
  SalesOrderCreationStatus,
} from "../../../../constants/enums";
import { internalRoute } from "../../../../routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetSalesOrder } from "../../../../types/groyyo_plus/salesOrder";
import { ColumnsType } from "antd/es/table";
import { useDispatch } from "react-redux";
import { setActiveSalesOrderTab } from "../../../../redux/groyyo_merchendaiser/internalMerchandiserSalesOrderSlice";
import { roundNumber } from "../../../../utilities/helper";
import { DownOutlined } from "@ant-design/icons";
import { ItemType } from "antd/es/menu/interface";
import PaginatedTable from "../../../../components/PaginatedTable";
import { getInternalSalesOrder } from "../../../../services/orderServiceApi/salesOrderApi";
import AssignFactory from "./AssignFactory";

const InternalSalesOrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t }: any = useTranslation();
  const [salesOrders, setSalesOrders] = useState<any>({
    pending: [],
    completed: [],
    running: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);

  const [activeTab, setActiveTab] = useState<string>("1");
  const [tabs] = useState(["Pending", "Running", "Completed"]);
  const createSalesOrderOption: ItemType[] = [
    { label: t("sales_order.actions.create_single_order"), key: "1" },
    { label: t("sales_order.actions.create_bulk_order"), key: "2" },
  ];

  const handleClose = () => {
    setVisible(false);
  };

  const fetchSalesOrder = useCallback(async () => {
    setLoading(true);
    try {
      const currentTab = tabs[parseInt(activeTab) - 1];
      const response = await getInternalSalesOrder(currentTab?.toUpperCase());
      const responseData = response?.data;
      if (responseData?.status) {
        setSalesOrders({
          ...salesOrders,
          [currentTab.toLowerCase()]: responseData?.result,
        });
      }
    } catch (error) {
      console.error(t("sales_order.list.messages.fetch_error"), error);
    } finally {
      setLoading(false);
    }
  }, [activeTab, t, tabs]);

  useEffect(() => {
    fetchSalesOrder();
  }, [fetchSalesOrder]);

  const handleSalesOrderCreation = () => {
    navigate(`${internalRoute}/sales-order/create`, {
      state: { status: SalesOrderCreationStatus.ORDER_DETAIL_PENDING },
    });
  };

  const onMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "1") {
      handleSalesOrderCreation();
    } else {
      navigate(`${internalRoute}/sales-order/bulk`);
    }
  };

  const checkAndRedirectToOrderDetail = (
    e: BaseSyntheticEvent,
    record: GetSalesOrder
  ) => {
    e.stopPropagation();
    if (record.status === SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING) {
      navigate(`${internalRoute}/sales-order/create`, {
        state: {
          status: SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING,
          id: record?.id,
        },
      });
      dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.ITEM_DETAILS));
    } else if (
      record.status === SalesOrderCreationStatus.SALES_ORDER_BILLING_PENDING
    ) {
      navigate(`${internalRoute}/sales-order/create`, {
        state: {
          status: SalesOrderCreationStatus.SALES_ORDER_BILLING_PENDING,
          id: record?.id,
        },
      });
      dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.BILLING_DETAILS));
    } else {
      navigate(`${internalRoute}/sales-order/${record.id}`);
    }
  };

  const columns: ColumnsType<GetSalesOrder> = [
    {
      title: t("groyyo unique ID"),
      dataIndex: "internalOrder",
      key: "internalOrder",
      render: (text, record) => (
        <a
          className="text-blue-400 font-bold"
          onClick={(e) => checkAndRedirectToOrderDetail(e, record)}
        >
          {text?.groyyoUniqueId}
        </a>
      ),
    },
    {
      title: t("sales_order.list.columns.buyer_name"),
      dataIndex: ["buyer", "name"],
      key: "buyerName",
    },
    {
      title: t("sales_order.list.columns.merchandiser"),
      dataIndex: "merchandiserName",
      key: "merchandiserName",
    },
    {
      title: t("sales_order.list.columns.reference"),
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: t("sales_order.list.columns.so_date"),
      dataIndex: "soDate",
      key: "soDate",
    },
    {
      title: t("sales_order.list.columns.expected_date"),
      dataIndex: "expectedDate",
      key: "expectedDate",
    },
    {
      title: t("sales_order.list.columns.total_amount"),
      key: "totalAmount",
      align: "center",
      render: (_, record) => (
        <span className="font-bold flex justify-end gap-4">
          <p>{roundNumber(record.total)}</p> <p>{record.currency?.code}</p>
        </span>
      ),
    },
  ];

  const actions = {
    title: "Action",
    key: "action",
    render: (_: any, record: { status: string }) => (
      <Space size="middle">
        <Button
          disabled={record.status !== "WAITING_FOR_MATERIAL"}
          type="primary"
          onClick={() => {
            setSelected(record);
            setVisible(true);
          }}
          style={{ backgroundColor: palette.secondary }}
        >
          Assign Factory
        </Button>
      </Space>
    ),
  };

  const status = {
    title: "Status",
    key: "status",
    render: (_: any, record: any) => (
      <Space size="middle">
        <a>{record.status?.replace(/_/g, " ")}</a>
      </Space>
    ),
  };

  const getTable = (tab: string) => {
    return (
      <PaginatedTable<GetSalesOrder>
        columns={[...columns, ...(tab === "Pending" ? [actions] : [status])]}
        dataSource={salesOrders[tab?.toLowerCase()]}
        rowKey="id"
        loading={loading}
      />
    );
  };

  return (
    <ConfigProvider locale={getCurrentLocale(i18n.language)}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">{t("sales_order.title")}</h1>

        <div className="flex ">
          <Dropdown.Button
            buttonsRender={(buttons) =>
              buttons?.map((defaultButton, index) =>
                index === 0 ? (
                  <Button
                    type="text"
                    htmlType="submit"
                    style={{
                      backgroundColor: palette.secondary,
                      borderWidth: 0,
                      color: "white",
                    }}
                    onClick={handleSalesOrderCreation}
                  >
                    {t("sales_order.actions.create_sales_order")}
                  </Button>
                ) : (
                  <Button
                    type="text"
                    htmlType="submit"
                    style={{
                      backgroundColor: palette.secondary,
                      borderWidth: 0,
                      color: "white",
                    }}
                  >
                    <DownOutlined />
                  </Button>
                )
              )
            }
            type="text"
            onClick={handleSalesOrderCreation}
            menu={{ items: createSalesOrderOption, onClick: onMenuClick }}
          >
            {t("sales_order.actions.create_sales_order")}
          </Dropdown.Button>
        </div>
      </div>
      <Tabs
        activeKey={activeTab}
        type="card"
        onTabClick={(tab) => setActiveTab(tab)}
        size={"large"}
        style={{
          color: palette.secondary,
          backgroundColor: "white",
        }}
        animated
        items={tabs.map((tab, i) => {
          const id = String(i + 1);

          return {
            label: (
              <p
                style={{
                  color: activeTab === id ? palette.secondary : palette.text,
                }}
              >
                {tab}
              </p>
            ),
            key: id,
            children: getTable(tab),
          };
        })}
      />
      <AssignFactory
        visible={visible}
        salesOrderId={selected?.id}
        handleClose={handleClose}
      />
    </ConfigProvider>
  );
};

export default InternalSalesOrderList;
