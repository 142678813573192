import React from "react";
import { Form, Input, Button, DatePicker, message, Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import moment from "moment";
import palette from "../../../constants/pallete";
import { IDispatchRequest } from "../../../types/groyyo_plus/dispatch";
import { addDisaptch } from "../../../services/orderServiceApi/dispatchApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { fetchAvailableForDispatch } from "../../../redux/groyyo_plus/dispatch/dispatchSlice";

const StartDispatch = () => {
  const { t }: any = useTranslation();
  const navigate = useNavigate();
  const { dispatchId, salesOrderItemId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const onFinish = async (values: any) => {
    const formattedValues: IDispatchRequest = {
      ...values,
      dispatchedQuantity: +values?.dispatchQuantity,
      rejectedQuantity: 0,
      passQuantity: 0,
      salesOrderItemId: +salesOrderItemId!,
      dispatchDate: values.dispatchDate
        ? moment(values.dispatchDate).format("DD/MM/YYYY")
        : null,
    };
    try {
      const response = await addDisaptch(formattedValues);
      if (response?.data?.status) {
        dispatch(fetchAvailableForDispatch({ dispatchId: +dispatchId! }));
        message.success(t("dispatch.startDispatch.success.submitSuccess"));
        navigate(`/dispatch/available-dispatch/${dispatchId}/${+salesOrderItemId!}`);
      } else {
        message.error(
          response?.data?.message || t("dispatch.startDispatch.errors.submitError")
        );
      }
    } catch (error) {
      console.error("API Error:", error);
      message.error(t("dispatch.startDispatch.errors.submitError"));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Form submission failed:", errorInfo);
    message.error(t("dispatch.startDispatch.errors.requiredFields"));
  };

  return (
    <div style={{ margin: "auto" }} className="w-full">
      <div className="flex justify-between items-center mb-4">
        <div className="text-2xl font-bold flex flex-row gap-2 items-center w-full">
          <span
            onClick={() =>
              navigate(`/dispatch/available-dispatch/${dispatchId}/${+salesOrderItemId!}`)
            }
            className="cursor-pointer"
          >
            <IoChevronBackOutline style={{ fontWeight: "bold" }} />
          </span>
          <p>{t("dispatch.startDispatch.title")}</p>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          dispatchQuantity: 0,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t("dispatch.startDispatch.form.dispatchQuantity.label")}
              name="dispatchQuantity"
              rules={[
                {
                  required: true,
                  message: t("dispatch.startDispatch.form.dispatchQuantity.required"),
                },
              ]}
            >
              <Input
                type="number"
                min={0}
                placeholder={t("dispatch.startDispatch.form.dispatchQuantity.placeholder")}
                autoFocus
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("dispatch.startDispatch.form.dispatchDate.label")}
              name="dispatchDate"
              rules={[
                {
                  required: true,
                  message: t("dispatch.startDispatch.form.dispatchDate.required"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("dispatch.startDispatch.form.dispatchDate.placeholder")}
                format="DD/MM/YYYY"
                disabledDate={(current) => current && current < moment().startOf("day")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ backgroundColor: palette.secondary }}
          >
            {t("dispatch.startDispatch.submitButton")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StartDispatch;