import React, { useState } from 'react'
import { BOMCreationType } from '../../../../constants/enums'
import CustomFloatButton from '../../../../components/CustomFloatButton'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IBomItemForm } from '../../../../types/groyyo_plus/bomItem'
import palette from '../../../../constants/pallete'
import CollectInternalNewMaterials from './CollectInternalNewMaterials'
import AddMaterialModal from '../../../groyyo_plus/Order/bom/AddMaterialModal'
import InternalBOMList from './InternalBomList'

const CreateInternalBOMItem = () => {
    const { t }: any = useTranslation();

    const [searchParams] = useSearchParams();
    const type = searchParams.get("type");

    const { salesOrderId } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [bomItems, setBomItems] = useState<IBomItemForm[]>([]);
    const [collectNewMaterials, setCollectNewMaterials] = useState<
        IBomItemForm[]
    >([]);

    const handleAddMaterial = (selectedMaterial: IBomItemForm) => {
        if (type === BOMCreationType.CREATED) {
          setBomItems((prev: IBomItemForm[]) => [...prev, selectedMaterial]);
        } else if (type === BOMCreationType.YET_TO_CREATE) {
          setCollectNewMaterials((prev: IBomItemForm[]) => [
            ...prev,
            selectedMaterial,
          ]);
        }
        setIsModalVisible(false);
      };

    return (
        <div>
            {type === BOMCreationType.CREATED && (
                <InternalBOMList
                    bomItems={bomItems}
                    setBomItems={setBomItems}
                    loading={loading}
                />
            )}
            {type === BOMCreationType.YET_TO_CREATE && (
                <CollectInternalNewMaterials
                    collectNewMaterials={collectNewMaterials}
                    setCollectNewMaterials={setCollectNewMaterials}
                />
            )}
            <CustomFloatButton
                icon={<PlusOutlined style={{ fontSize: "24px" }} />}
                onClick={() => setIsModalVisible(true)}
                backgroundColor={palette.secondary}
                color={palette.white}
                bottom={32}
                right={32}
                size={60}
                shadow={true}
            />

            <AddMaterialModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onAdd={handleAddMaterial}
                bomType={type as BOMCreationType}
            />
        </div>
    )
}

export default CreateInternalBOMItem