export const hi = {
    global: {
        no_data: "कोई डेटा उपलब्ध नहीं है।"
    },
    components: {
        custom_dropdown: {
            errors: {
                fetch_failed: "आइटम लाने में विफल",
                required: "{{label}} आवश्यक है"
            }
        },
        custom_dropdown_with_add: {
            errors: {
                fetch_failed: "आइटम लाने में विफल",
                required: "{{label}} आवश्यक है",
                add_failed: "{{label}} जोड़ने में त्रुटि"
            },
            no_data: "कोई डेटा उपलब्ध नहीं है",
            add_new: "नया {{label}} जोड़ें",
            modal_title: "नया {{label}} जोड़ें",
            modal_placeholder: "{{label}} दर्ज करें"
        },
        custom_dropdown_with_add_by_id: {
            errors: {
                fetch_failed: "आइटम लाने में विफल।",
                parent_id_missing: "कृपया एक मूल ID चुनें और फिर आइटम बनाएं।",
                field_required: "{{field}} आवश्यक है।",
                add_failed: "{{label}} बनाते समय त्रुटि।"
            },
            messages: {
                no_data: "कोई डेटा उपलब्ध नहीं है।"
            },
            buttons: {
                add_new: "नया {{label}} जोड़ें"
            },
            modal: {
                title: "नया {{label}} जोड़ें",
                placeholder: "{{field}} दर्ज करें"
            }
        },
        custom_dropdown_with_input: {
            no_matching_options: "कोई मेल खाने वाले विकल्प नहीं",
            placeholder: "चयन करें या मान दर्ज करें"
        },
        factory_select: {
            placeholder: "फैक्टरी चुनें",
            no_factories: "कोई फैक्टरी नहीं मिली",
            errors: {
                load_failed: "फैक्टरी लोड करने में विफल।",
                fetch_error: "फैक्टरी लाने में त्रुटि।"
            }
        },
        footer: {
            copy: "ग्रोयो प्लस ©2024"
        },
        header: {
            title: "ग्रोयो प्लस",
            errors: {
                parse_user_details: "उपयोगकर्ता विवरण पार्स करने में विफल"
            },
            language: {
                switch_success: "भाषा {{lang}} में बदली गई"
            },
            menu: {
                profile: "प्रोफ़ाइल",
                factories: "फैक्ट्रियां",
                logout: "लॉगआउट",
                default_user: "उपयोगकर्ता"
            }
        },
        sortable_item: {
            untitled_process: "शीर्षकहीन प्रक्रिया",
            dependency_on: "पर निर्भरता",
            none: "कोई नहीं"
        }
    },
    home: {
        dashboard_title: "डैशबोर्ड",
        total_styles: "कुल शैलियाँ",
        total_quantity: "कुल मात्रा",
        avg_delivery_days: "औसत डिलीवरी दिन",
        total_values: "कुल मूल्य",
        completion_progress: "पूर्णता प्रगति",
        dispatch_progress: "डिस्पैच प्रगति",
        delayed_materials: "विलंबित सामग्री",
        dispatched_quantity: "भेजी गई मात्रा",
        delayed_orders: "विलंबित आदेश",
        completed_styles: "पूर्ण शैलियाँ",
        days: "दिन",
        no_delayed_materials: "कोई विलंबित सामग्री नहीं मिली।",
        no_delayed_orders: "कोई विलंबित आदेश नहीं मिला।",
        delayed_message: "{{days}} दिनों की देरी",
        filter: {
            filter: "फ़िल्टर",
            title: "फ़िल्टर विकल्प",
            clear: "फ़िल्टर साफ़ करें",
            apply: "लागू करें",
            buyer: "खरीदार",
            style: "शैली",
            sales_order: "बिक्री आदेश",
            product: "उत्पाद",
            date: "तारीख़",
        },
    },
    sidebar: {
        module1: {
            go_to_users: "उपयोगकर्ताओं पर जाएं",
            branches: "शाखाएं",
            product_type: "उत्पाद प्रकार",
            delivery_type: "डिलीवरी प्रकार",
            payment_term: "भुगतान शर्तें",
            customers: "ग्राहक",
            categories: "श्रेणियाँ",
            subcategories: "उपश्रेणियाँ",
            job_work_supplier: "कार्य आपूर्ति प्रदाता",
            taxes: "कर"
        },
        module2: {
            home: "मुख्य पृष्ठ",
            order: "आदेश",
            sales_order: "बिक्री आदेश",
            bom: "बीओएम",
            material: "सामग्री",
            receivable: "प्राप्त करने योग्य",
            issue: "जारी",
            material_tracking: "सामग्री ट्रैकिंग",
            production: "उत्पादन",
            wip: "कार्य प्रगति पर",
            tna: "टीएनए",
            dispatch: "प्रेषण",
            users: "उपयोगकर्ता"
        },
        module3: {
            item1: "मॉड्यूल 3 आइटम 1",
            item2: "मॉड्यूल 3 आइटम 2"
        }
    },

    currentFactoryUser: {
        factory_members: "फैक्ट्री सदस्य",
        name: "नाम",
        email: "ईमेल",
        mobile_number: "मोबाइल नंबर",
        role: "भूमिका",
        unexpected_response: "अप्रत्याशित प्रतिक्रिया प्रारूप।",
        fetch_error: "उपयोगकर्ताओं को प्राप्त करने में विफल।",
    },
    createUserControl: {
        create_user: "उपयोगकर्ता बनाएँ",
        user_created_success: "उपयोगकर्ता सफलतापूर्वक बनाया गया!",
        user_creation_failed: "उपयोगकर्ता बनाने में विफल।",
        createUser: {
            name: "नाम",
            email: "ईमेल",
            mobile_no: "मोबाइल नंबर",
            role: "भूमिका",
            please_input_name: "कृपया नाम दर्ज करें!",
            please_input_mobile: "कृपया मोबाइल नंबर दर्ज करें!",
            please_select_role: "कृपया एक भूमिका चुनें!",
            select_role_placeholder: "एक भूमिका चुनें",
            user_created_success: "उपयोगकर्ता सफलतापूर्वक बनाया गया।",
            user_creation_failed: "उपयोगकर्ता बनाने में विफल।",
            create_button: "बनाएँ",
        },
    },
    readyForDispatch: {
        title: "डिस्पैच के लिए उपलब्ध",
        columns: {
            so_number: "एसओ नंबर",
            buyer_name: "खरीदार का नाम",
            merchandiser: "मर्चेंडाइजर",
            so_date: "एसओ दिनांक",
            expected_date: "अनुमानित दिनांक"
        },
    },
    dispatch: {
        title: "प्रेषण",
        no_available_data: "कोई उपलब्ध प्रेषण डेटा नहीं",
        so_no: "एसओ नंबर:",
        buyer: "खरीदार:",
        so_date: "एसओ तिथि:",
        expected_delivery: "अनुमानित डिलीवरी:",
        ready_for_dispatch: "प्रेषण के लिए तैयार",
        dispatched: "प्रेषित",
        overage: "अधिकता",
        view_history: "इतिहास देखें",
        start_inspection: "निरीक्षण शुरू करें",
        dispatch: "प्रेषण करें",
        mark_as_delivered: "डिलीवर के रूप में चिह्नित करें",
        modal: {
            title: "रुको",
            message: "अभी भी {{remainingOrders}} आदेश उपलब्ध हैं! क्या आप वाकई इस आदेश को डिलीवर के रूप में चिह्नित करना चाहते हैं?",
            cancel: "रद्द करें",
            confirm: "हाँ",
        },
        messages: {
            marked_success: "ऑर्डर को सफलतापूर्वक डिलीवर के रूप में चिह्नित किया गया!",
            marked_failed: "ऑर्डर को डिलीवर के रूप में चिह्नित करने में विफल।",
            delivery_error: "ऑर्डर को डिलीवर के रूप में चिह्नित करते समय त्रुटि हुई।",
            fetch_status_failed: "डिलीवरी स्थिति लाने में विफल।",
            status_error: "डिलीवरी स्थिति की जांच करते समय त्रुटि हुई।",
        },
        dispatchHistory: {
            title: "प्रेषण इतिहास",
            no_data: "कोई प्रेषण इतिहास उपलब्ध नहीं है।",
            dispatched_quantity: "प्रेषण मात्रा:",
            received_quantity: "प्राप्त मात्रा:",
            rejected_quantity: "अस्वीकृत मात्रा:",
            error_fetching: "प्रेषण इतिहास प्राप्त करने में त्रुटि।",
        },
        startInspection: {
            title: "निरीक्षण शुरू करें",
            errors: {
                missingDispatchId: "डिस्पैच आईडी गायब है। कृपया पुनः प्रयास करें।",
                quantityMismatch:
                    "निरीक्षित मात्रा को निरीक्षण पास और विफल मात्रा के योग के बराबर होना चाहिए।",
                submitError: "निरीक्षण डेटा सबमिट करते समय एक त्रुटि हुई।",
                requiredFields: "कृपया सभी आवश्यक फ़ील्ड भरें!",
            },
            success: {
                submitSuccess: "निरीक्षण डेटा सफलतापूर्वक सबमिट किया गया!",
            },
            form: {
                inspectedQuantity: {
                    label: "निरीक्षित मात्रा",
                    placeholder: "निरीक्षित मात्रा दर्ज करें",
                    required: "कृपया निरीक्षित मात्रा दर्ज करें",
                },
                passedQuantity: {
                    label: "निरीक्षण पास मात्रा",
                    placeholder: "पास मात्रा दर्ज करें",
                    required: "कृपया पास मात्रा दर्ज करें",
                },
                failedQuantity: {
                    label: "निरीक्षण विफल मात्रा",
                    placeholder: "विफल मात्रा दर्ज करें",
                    required: "कृपया विफल मात्रा दर्ज करें",
                },
                date: {
                    label: "निरीक्षण तिथि",
                    placeholder: "तिथि चुनें",
                    required: "कृपया निरीक्षण तिथि चुनें",
                },
            },
            submitButton: "निरीक्षण सबमिट करें",
        },
        startDispatch: {
            title: "डिस्पैच शुरू करें",
            errors: {
                requiredFields: "कृपया सभी आवश्यक फ़ील्ड भरें!",
                submitError: "डिस्पैच डेटा सबमिट करते समय एक त्रुटि हुई।",
            },
            success: {
                submitSuccess: "डिस्पैच डेटा सफलतापूर्वक सबमिट किया गया!",
            },
            form: {
                dispatchQuantity: {
                    label: "डिस्पैच मात्रा",
                    placeholder: "डिस्पैच मात्रा दर्ज करें",
                    required: "कृपया डिस्पैच मात्रा दर्ज करें",
                },
                dispatchDate: {
                    label: "डिस्पैच तिथि",
                    placeholder: "तिथि चुनें",
                    required: "कृपया एक डिस्पैच तिथि चुनें",
                },
            },
            submitButton: "डिस्पैच सबमिट करें",
        },
    },
    tnaPage: {
        title: "टीएनए",
        loadingMessage: "टीएनए आदेश लोड हो रहे हैं...",
        noOrders: "कोई टीएनए आदेश उपलब्ध नहीं हैं।",
        tnaCard: {
            selectTemplate: "टेम्पलेट चुनें",
            searchTemplate: "टेम्पलेट खोजें",
            createTNA: "टीएनए बनाएं",
            loadingTemplates: "टेम्पलेट लोड हो रहे हैं...",
            custom: "कस्टम",
            add: "जोड़ें",
            soDate: "एसओ तिथि",
            expectedDeliveryDate: "अपेक्षित डिलीवरी तिथि",
            processes: "प्रक्रियाएँ",
        },
        tnaDetails: {
            title: "टीएनए प्रक्रिया समयरेखा",
            loading: "लोड हो रहा है...",
            noDetails: "टीएनए विवरण नहीं मिला",
            plannedDate: "निर्धारित तिथि",
            actualDate: "वास्तविक तिथि",
            selectActualDate: "वास्तविक तिथि चुनें",
            save: "सहेजें",
            addActualDate: "वास्तविक तिथि जोड़ें",
            dependencyOn: "निर्भरता",
            updateSuccess: "वास्तविक तिथि सफलतापूर्वक अपडेट की गई!",
            updateFailed: "वास्तविक तिथि अपडेट करने में विफल।",
            updateError: "वास्तविक तिथि अपडेट करने में त्रुटि हुई।",
            selectDateError: "कृपया एक वास्तविक तिथि चुनें!",
        },
        create: {
            title: "टीएनए बनाएँ",
            add_new_process: "नई प्रक्रिया जोड़ें",
            process: "प्रक्रिया",
            process_name: "प्रक्रिया का नाम",
            process_placeholder: "प्रक्रिया का नाम खोजें या चुनें",
            dependency_on: "निर्भरता",
            dependency_on_placeholder: "निर्भरता खोजें या चुनें",
            plan_date: "योजना तिथि",
            manual_entry: "वास्तविक तिथि के लिए मैनुअल प्रविष्टि",
            save: "सहेजें",
            errors: {
                fix_before_save: "सहेजने से पहले त्रुटियों को ठीक करें।",
                plan_date_required: "योजना तिथि आवश्यक है",
            },
        },
        create_tna_template: {
            title: "टीएनए टेम्पलेट बनाएं",
            template_name: "टेम्पलेट नाम",
            template_name_placeholder: "टेम्पलेट नाम दर्ज करें",
            process: "प्रक्रिया",
            process_name: "प्रक्रिया का नाम",
            process_name_placeholder: "प्रक्रिया का नाम खोजें या चुनें",
            dependency_on: "निर्भरता",
            dependency_on_placeholder: "निर्भरता खोजें या चुनें",
            manual_entry: "वास्तविक तिथि के लिए मैनुअल प्रविष्टि",
            add_process: "प्रक्रिया जोड़ें",
            save: "सहेजें",
            rearrange_processes: "प्रक्रियाओं को पुनर्व्यवस्थित करें",
            errors: {
                template_name_required: "टेम्पलेट नाम आवश्यक है",
                at_least_one_process: "कम से कम एक प्रक्रिया आवश्यक है!",
                validation_errors: "प्रक्रियाओं को पुनर्व्यवस्थित करने से पहले कृपया सत्यापन त्रुटियों को ठीक करें।",
                save_failed: "टेम्पलेट को सहेजने में विफल। कृपया पुनः प्रयास करें।",
                save_success: "टीएनए टेम्पलेट सफलतापूर्वक बनाया गया!"
            }
        }
    },
    production: {
        wip: {
            title: "वर्क इन प्रोग्रेस (WIP)",
            clear_all: "सभी साफ करें",
            no_data: "कोई डेटा उपलब्ध नहीं है।",
            download_selected: "चयनित डाउनलोड करें",
            file_downloaded: "फ़ाइल सफलतापूर्वक डाउनलोड की गई!",
            no_cards_selected: "डाउनलोड के लिए कोई कार्ड चयनित नहीं है।",
            no_data_for_selected_cards: "चयनित कार्ड के लिए कोई डेटा नहीं मिला।",
            excel_sheet_name: "प्रोडक्शन WIP",
            excel_headers: {
                buyer: "खरीदार",
                so_number: "SO नंबर",
                style_number: "स्टाइल नंबर",
                so_date: "SO तिथि",
                order_quantity: "ऑर्डर मात्रा",
                expected_delivery_date: "अपेक्षित डिलीवरी तिथि",
                fabric_in_house_date: "फैब्रिक इन-हाउस तिथि",
                cutting_date: "कटिंग तिथि",
                cutting_quantity: "कटिंग मात्रा",
                sewing_date: "सिलाई तिथि",
                sewing_quantity: "सिलाई मात्रा",
                finishing_date: "फिनिशिंग तिथि",
                finishing_quantity: "फिनिशिंग मात्रा",
                dispatch_date: "डिस्पैच तिथि",
                dispatch_quantity: "डिस्पैच मात्रा"
            },
            wip_card: {
                icon_alt: "ऑर्डर आइकन",
                department: "विभाग",
                so_date: "एसओ दिनांक",
                expected_delivery_date: "अपेक्षित डिलीवरी दिनांक",
                expected_quantity: "अपेक्षित मात्रा",
                completed: "पूरा हुआ",
                rejected: "अस्वीकृत",
                see_more_info: "अधिक जानकारी देखें",
                see_less_info: "कम जानकारी देखें",
                departments: {
                    SEWING: "सिलाई",
                    FINISHING: "फिनिशिंग",
                    CUTTING: "काटना"
                }
            }
        }
    },
    material: {
        history_modal: {
            title: "इतिहास",
            no_data: "कोई डेटा उपलब्ध नहीं है।",
            labels: {
                received_quantity: "प्राप्त मात्रा",
                rejected_quantity: "अस्वीकृत मात्रा",
                issued_quantity: "जारी मात्रा"
            }
        },
        receivable: {
            recievable_list: {
                title: "सामग्री प्राप्त करने योग्य",
                loading: "लोड हो रहा है...",
                error_fetching_list: "प्राप्त सूची लाने में त्रुटि:"
            },
            list: {
                title: "सामग्री प्राप्त करने योग्य",
                columns: {
                    so_number: "एसओ नंबर",
                    buyer_name: "खरीदार का नाम",
                    merchandiser: "मर्चेंडाइजर",
                    so_date: "एसओ तिथि",
                    expected_date: "अपेक्षित तिथि"
                },
                loading: "लोड हो रहा है..."
            },
            material_sales_order_item: {
                back_button_tooltip: "सूची पर वापस जाएं",
                page_title: "आइटम चुनें",
                loading: "लोड हो रहा है...",
                no_items_available: "कोई आइटम उपलब्ध नहीं है",
                item_details: {
                    amount: "राशि",
                    unit: "इकाई",
                    category_separator: "/"
                }
            },
            recievable_list_item: {
                fields: {
                    accepted_quantity: "स्वीकृत मात्रा",
                    rejected_quantity: "अस्वीकृत मात्रा",
                    received_date: "प्राप्त तिथि"
                },
                validation_messages: {
                    accepted_quantity_required: "स्वीकृत मात्रा आवश्यक है!",
                    rejected_quantity_required: "अस्वीकृत मात्रा आवश्यक है!",
                    received_date_required: "प्राप्त तिथि आवश्यक है!"
                },
                buttons: {
                    save: "सहेजें"
                },
                labels: {
                    expected_delivery_date: "अपेक्षित वितरण तिथि",
                    total_ordered_quantity: "कुल आदेशित मात्रा",
                    received_quantity: "प्राप्त मात्रा",
                    pending_quantity: "लंबित मात्रा",
                    view_history: "इतिहास देखें"
                },
                messages: {
                    success: "सामग्री प्राप्ति सफलतापूर्वक असाइन की गई!",
                    error: "सामग्री प्राप्ति सहेजने में एक त्रुटि हुई।",
                    failed_to_save: "सामग्री प्राप्ति सहेजने में विफल।"
                },
                form_errors: {
                    validation_failed: "कृपया सभी आवश्यक फ़ील्ड सही ढंग से भरें।"
                }
            }
        },
        issued: {
            title: "सामग्री जारी",
            table: {
                so_number: "एसओ संख्या",
                buyer_name: "खरीदार का नाम",
                merchandiser: "मर्चेंडाइजर",
                so_date: "एसओ तारीख",
                expected_date: "अपेक्षित तिथि"
            },
            list: {
                title: "सामग्री जारी",
                errors: {
                    fetch_failed: "जारी सूची प्राप्त करने में त्रुटि:"
                },
                no_data: "कोई आइटम उपलब्ध नहीं है"
            },
            list_item: {
                expected_delivery_date: "अपेक्षित वितरण तिथि",
                total_ordered_quantity: "कुल आदेशित मात्रा",
                issued_quantity: "जारी की गई मात्रा",
                pending_quantity: "लंबित मात्रा",
                view_history: "इतिहास देखें",
                department: "विभाग",
                select_department: "विभाग का चयन करें",
                issued_quantity_placeholder: "जारी की गई मात्रा दर्ज करें",
                issue_date: "जारी करने की तिथि",
                issue_date_placeholder: "जारी करने की तिथि चुनें",
                save: "सहेजें",
                messages: {
                    issue_success: "सामग्री सफलतापूर्वक जारी की गई!",
                    issue_failed: "सामग्री जारी करने में विफल.",
                    unexpected_error: "अप्रत्याशित त्रुटि हुई.",
                    validation_failed: "प्रपत्र मान्यकरण विफल.",
                    fill_required_fields: "कृपया सभी आवश्यक फ़ील्ड सही से भरें.",
                    quantity_required: "जारी की गई मात्रा आवश्यक है!",
                    date_required: "जारी करने की तिथि आवश्यक है!"
                },
                selected_department: "चयनित विभाग:"
            }
        },
        tracking: {
            no_data: "कोई डेटा उपलब्ध नहीं है।",
            title: "सामग्री ट्रैकिंग",
            clear_all: "सभी साफ करें",
            download_selected: "चयनित डाउनलोड करें",
            excel_sheet_name: "सामग्री",
            file_name_prefix: "कार्य प्रगति सामग्री",
            messages: {
                no_cards_selected: "डाउनलोड के लिए कोई कार्ड चयनित नहीं।",
                material_not_found: "आईडी {{id}} के साथ सामग्री नहीं मिली।",
                no_data_found: "चयनित कार्ड के लिए कोई डेटा नहीं मिला।",
                file_downloaded: "डाउनलोड की गई फ़ाइल: {{fileName}}"
            },
            excel_headers: {
                buyer: "खरीदार",
                so_number: "एसओ संख्या",
                style_number: "स्टाइल संख्या",
                so_date: "एसओ तिथि",
                order_quantity: "आदेश मात्रा",
                material: "सामग्री",
                unit: "इकाई",
                type: "प्रकार",
                average: "औसत",
                expected_quantity: "अपेक्षित मात्रा",
                received_quantity: "प्राप्त मात्रा",
                rejected_quantity: "अस्वीकृत मात्रा",
                expected_in_house_date: "अपेक्षित इन-हाउस तिथि",
                actual_in_house_date: "वास्तविक इन-हाउस तिथि"
            },
            card: {
                so_date: "एसओ तिथि",
                expected_delivery: "अपेक्षित वितरण",
                material_delayed: "सामग्री में देरी",
                material_pending: "सामग्री लंबित",
                material_completed: "सामग्री पूरी हुई",
                material_name: "सामग्री का नाम",
                type: "प्रकार",
                in_house_date: "इन-हाउस तिथि",
                actual_date: "वास्तविक तिथि",
                received: "प्राप्त",
                rejected: "अस्वीकृत",
                pending: "लंबित",
                see_more_info: "अधिक जानकारी देखें",
                see_less_info: "कम जानकारी देखें"
            }
        }
    },
    sales_order_bom: {
        bom: {
            title: "बीओएम",
            so_number: "एसओ संख्या",
            buyer_name: "खरीदार का नाम",
            merchandiser: "मर्चेंडाइज़र",
            reference: "संदर्भ",
            so_date: "एसओ तिथि",
            expected_date: "अपेक्षित तिथि"
        },
        create_bom: {
            title: "अपने बिक्री आदेश के लिए बीओएम प्रबंधित करें",
            already_created_title: "पहले से बनाए गए बीओएम आइटम्स",
            yet_to_create_title: "इन आइटम्स के लिए बीओएम निर्माण की आवश्यकता है",
            create_button: "बनाएं",
            error_fetching_items: "बिक्री आदेश आइटम्स प्राप्त करने में त्रुटि।",
            bom_item: {
                messages: {
                    error_fetching_bom: "बीओएम आइटम प्राप्त करने में त्रुटि।",
                },
            }
        },
        bom_list: {
            validation_failed: "मान्यता विफल। कृपया फॉर्म में त्रुटियों की जांच करें।",
            submission_success: "फॉर्म सफलतापूर्वक सबमिट किए गए!",
            submission_error: "सबमिशन के दौरान एक त्रुटि हुई।",
            error_fetching_details: "बीओएम विवरण प्राप्त करने में त्रुटि।",
            download_filename: "खरीद_आदेश.pdf",
            download_button: "डाउनलोड",
            submit_button: "सभी सबमिट करें"
        },
        bom_list_item: {
            supplier_label: "आपूर्तिकर्ता",
            supplier_placeholder: "आपूर्तिकर्ता खोजें या चुनें",
            supplier_help: "कृपया एक आपूर्तिकर्ता चुनें",
            quantity_label: "मात्रा",
            quantity_placeholder: "मात्रा दर्ज करें",
            quantity_help: "कृपया मात्रा दर्ज करें",
            unit_label: "इकाई",
            unit_placeholder: "इकाई खोजें या चुनें",
            unit_name_label: "इकाई का नाम",
            unit_help: "कृपया एक इकाई चुनें",
            currency_label: "मुद्रा",
            currency_placeholder: "मुद्रा चुनें",
            currency_help: "कृपया एक मुद्रा चुनें",
            extra_label: "अतिरिक्त",
            extra_placeholder: "अतिरिक्त दर्ज करें",
            extra_help: "कृपया अतिरिक्त दर्ज करें",
            average_label: "औसत",
            average_placeholder: "औसत दर्ज करें",
            average_help: "कृपया औसत दर्ज करें",
            rate_label: "दर",
            rate_placeholder: "दर दर्ज करें",
            rate_help: "कृपया दर दर्ज करें",
            in_house_date_label: "इन-हाउस तिथि",
            in_house_date_placeholder: "इन-हाउस तिथि चुनें",
            in_house_date_help: "कृपया इन-हाउस तिथि चुनें",
            common: {
                name: "नाम",
                address_line_1: "पता पंक्ति 1",
                address_line_2: "पता पंक्ति 2",
                city: "शहर",
                state: "राज्य",
                country: "देश",
                pin_code: "पिन कोड",
                gst_no: "जीएसटी नंबर",
                contact_person: "संपर्क व्यक्ति",
                contact_no: "संपर्क नंबर",
                email: "ईमेल"
            }
        },
        add_material_modal: {
            title: "सामग्री जोड़ें",
            search_placeholder: "सामग्री खोजें",
            type_placeholder: "प्रकार चुनें",
            type_fabric: "कपड़ा",
            type_trim: "ट्रिम",
            add_new_button: "नया जोड़ें"
        },
        collect_new_materials: {
            validation_failed: "मान्यता असफल रही। कृपया फॉर्म में त्रुटियों की जांच करें।",
            success_message: "फॉर्म सफलतापूर्वक सबमिट हो गए!",
            submit_all: "सभी सबमिट करें",
            list_item: {
                supplier: "आपूर्तिकर्ता",
                supplier_placeholder: "आपूर्तिकर्ता खोजें या चुनें",
                supplier_help: "कृपया एक आपूर्तिकर्ता चुनें",
                quantity: "मात्रा",
                quantity_placeholder: "मात्रा दर्ज करें",
                quantity_help: "कृपया मात्रा दर्ज करें",
                unit: "इकाई",
                unit_placeholder: "इकाई खोजें या चुनें",
                unit_help: "कृपया एक इकाई चुनें",
                currency: "मुद्रा",
                currency_placeholder: "मुद्रा चुनें",
                currency_help: "कृपया एक मुद्रा चुनें",
                extra: "अतिरिक्त",
                extra_placeholder: "अतिरिक्त दर्ज करें",
                extra_help: "कृपया अतिरिक्त दर्ज करें",
                average: "औसत",
                average_placeholder: "औसत दर्ज करें",
                average_help: "कृपया औसत दर्ज करें",
                rate: "दर",
                rate_placeholder: "दर दर्ज करें",
                rate_help: "कृपया दर दर्ज करें",
                in_house_date: "इन-हाउस तिथि",
                in_house_date_placeholder: "तिथि चुनें",
                in_house_date_help: "कृपया इन-हाउस तिथि चुनें"
            },
            supplier_form: {
                name: "नाम",
                address1: "पता पंक्ति 1",
                address2: "पता पंक्ति 2",
                city: "शहर",
                state: "राज्य",
                country: "देश",
                pin_code: "पिन कोड",
                gst_no: "जीएसटी नंबर",
                contact_person: "संपर्क व्यक्ति",
                contact_no: "संपर्क नंबर",
                email: "ईमेल"
            },
            unit_form: {
                name: "इकाई नाम"
            }
        },
        create_new_material: {
            tabs: {
                fabric: "कपड़ा",
                trim: "सजावट"
            },
            form: {
                name: "नाम",
                name_placeholder: "नाम दर्ज करें",
                name_required: "नाम आवश्यक है",
                composition: "संरचना",
                composition_placeholder: "संरचना दर्ज करें",
                composition_required: "संरचना आवश्यक है",
                description: "विवरण",
                description_placeholder: "वैकल्पिक",
                hsn_code: "एचएसएन कोड",
                hsn_code_placeholder: "वैकल्पिक",
                color: "रंग",
                color_placeholder: "रंग खोजें या चुनें",
                color_required: "कृपया एक रंग चुनें",
                color_modal: {
                    name: "रंग का नाम",
                    hex_code: "हेक्स कोड"
                },
                size: "आकार",
                size_placeholder: "आकार खोजें या चुनें",
                size_required: "कृपया एक आकार चुनें",
                size_modal: {
                    name: "आकार का नाम"
                },
                unit: "इकाई",
                unit_placeholder: "इकाई खोजें या चुनें",
                unit_required: "कृपया एक इकाई चुनें",
                unit_modal: {
                    name: "इकाई का नाम"
                }
            },
            image: {
                upload: "छवि अपलोड करें",
                upload_success: "छवि सफलतापूर्वक अपलोड की गई",
                upload_failed: "छवि अपलोड विफल",
                upload_requirement: "छवि 5MB से छोटी होनी चाहिए!",
                image_required: "छवि आवश्यक है।"
            },
            actions: {
                done: "संपन्न",
                cancel: "रद्द करें"
            }
        },
        create_bom_item: {
            tabs: {
                created: "निर्मित बीओएम आइटम",
                yet_to_create: "बनाने के लिए बीओएम आइटम"
            },
            messages: {
                error_fetching_bom: "बीओएम आइटम प्राप्त करने में त्रुटि।",
                navigate_back: "बीओएम निर्माण पृष्ठ पर वापस नेविगेट कर रहे हैं।"
            },
            actions: {
                add_material: "सामग्री जोड़ें"
            }
        }
    },
    sales_order: {
        title: "बिक्री आदेश",
        actions: {
            create_sales_order: "बिक्री आदेश बनाएँ",
            create_single_order: "एकल बिक्री आदेश बनाएँ",
            create_bulk_order: "बल्क बिक्री आदेश बनाएँ"
        },
        list: {
            columns: {
                so_number: "एसओ नंबर",
                buyer_name: "खरीदार का नाम",
                merchandiser: "मरचेंडाइज़र",
                reference: "संदर्भ",
                so_date: "एसओ दिनांक",
                expected_date: "अपेक्षित दिनांक",
                total_amount: "कुल राशि"
            },
            messages: {
                fetch_error: "बिक्री आदेश प्राप्त करने में विफल"
            }
        },
        creation: {
            title: "सेल्स ऑर्डर बनाएं",
            steps: {
                order_detail: "ऑर्डर विवरण",
                item_detail: "आइटम विवरण",
                billing_detail: "बिलिंग विवरण"
            },
            order_detail: {
                fields: {
                    customer_name: "ग्राहक का नाम",
                    branch: "शाखा",
                    so_name: "एसओ नाम",
                    reference: "संदर्भ",
                    expected_margin: "अपेक्षित मार्जिन",
                    merchandiser: "मर्चेंडाइज़र",
                    approver: "स्वीकर्ता",
                    so_date: "एसओ दिनांक",
                    expected_delivery_date: "अपेक्षित डिलीवरी दिनांक",
                    payment_terms: "भुगतान की शर्तें",
                    delivery_type: "डिलीवरी प्रकार",
                    product_type: "उत्पाद प्रकार",
                    currency: "मुद्रा",
                    "groyyo_unique_id": "ग्रॉययो यूनिक आईडी",
                    "code_as_per_zoho": "ज़ोहो के अनुसार कोड",
                    "buyer_po_number": "खरीदार पीओ नंबर"
                },
                placeholders: {
                    customer_name: "ग्राहक खोजें या चुनें",
                    branch: "शाखा खोजें या चुनें",
                    so_name: "शाखा चुनें, एसओ नाम यहाँ प्रदर्शित होगा",
                    reference: "संदर्भ दर्ज करें",
                    expected_margin: "अपेक्षित मार्जिन दर्ज करें",
                    merchandiser: "मर्चेंडाइज़र चुनें",
                    approver: "स्वीकर्ता चुनें",
                    so_date: "एसओ दिनांक चुनें",
                    expected_delivery_date: "अपेक्षित डिलीवरी दिनांक चुनें",
                    payment_terms: "भुगतान की शर्तें खोजें या चुनें",
                    delivery_type: "डिलीवरी प्रकार खोजें या चुनें",
                    product_type: "उत्पाद प्रकार खोजें या चुनें",
                    currency: "मुद्रा चुनें",
                    "groyyo_unique_id": "ग्रॉययो यूनिक आईडी दर्ज करें",
                    "code_as_per_zoho": "ज़ोहो के अनुसार कोड दर्ज करें",
                    "buyer_po_number": "खरीदार पीओ नंबर दर्ज करें"
                },
                errors: {
                    reference_required: "संदर्भ आवश्यक है",
                    expected_margin_required: "अपेक्षित मार्जिन आवश्यक है",
                    so_date_required: "एसओ दिनांक आवश्यक है",
                    expected_delivery_date_required: "अपेक्षित डिलीवरी दिनांक आवश्यक है",
                    "groyyo_unique_id_required": "ग्रॉययो यूनिक आईडी आवश्यक है",
                    "code_as_per_zoho_required": "ज़ोहो के अनुसार कोड आवश्यक है",
                    "buyer_po_number_required": "खरीदार पीओ नंबर आवश्यक है"
                },
                buttons: {
                    save: "सहेजें"
                },
                messages: {
                    success: "ऑर्डर विवरण सफलतापूर्वक बनाया गया"
                }
            },
            item_details: {
                fields: {
                    quantity: "मात्रा",
                    rate: "दर",
                    total: "कुल",
                    tax: "कर",
                    tax_name: "कर का नाम",
                    tax_desc: "विवरण",
                    tax_percentage: "प्रतिशत"
                },
                placeholders: {
                    quantity: "मात्रा दर्ज करें",
                    rate: "दर दर्ज करें",
                    tax: "कर खोजें या चुनें"
                },
                errors: {
                    quantity_required: "मात्रा आवश्यक है",
                    rate_required: "दर आवश्यक है",
                    submit_failed: "आइटम विवरण जमा करने में विफल"
                },
                buttons: {
                    next: "आगे"
                },
                messages: {
                    success: "आइटम विवरण सफलतापूर्वक बनाया गया"
                }
            },
            billing_details: {
                fields: {
                    sub_total: "कुल योग",
                    discount: "छूट",
                    shipment_charges: "शिपमेंट शुल्क",
                    total: "कुल",
                    customer_note: "ग्राहक नोट",
                    terms_and_conditions: "नियम और शर्तें",
                    attachments: "अनुलग्नक"
                },
                placeholders: {
                    discount: "छूट दर्ज करें",
                    shipment_charges: "शिपमेंट शुल्क दर्ज करें",
                    customer_note: "ग्राहक नोट दर्ज करें",
                    terms_and_conditions: "नियम और शर्तें दर्ज करें"
                },
                errors: {
                    file_size: "फाइल {{file}} 5MB से कम होनी चाहिए!",
                    file_format: "फाइल {{file}} का प्रारूप अमान्य है!",
                    upload_failed: "फाइल {{file}} अपलोड करने में विफल। कृपया पुनः प्रयास करें।",
                    upload_network: "फाइल {{file}} अपलोड करते समय त्रुटि। कृपया नेटवर्क जांचें।",
                    attach_po: "PO फाइल संलग्न करें",
                    customer_note_required: "ग्राहक नोट आवश्यक है",
                    terms_conditions_required: "नियम और शर्तें आवश्यक हैं"
                },
                buttons: {
                    upload_files: "फाइलें अपलोड करें",
                    create_order: "ऑर्डर बनाएं"
                },
                messages: {
                    file_uploaded: "{{file}} सफलतापूर्वक अपलोड हो गई!",
                    order_created: "बिक्री आदेश सफलतापूर्वक बनाया गया!"
                }
            }

        },create_bulk_order: {
            heading: {
                create_sales_order: "सेल्स ऑर्डर बनाएँ"
            },
            disclaimer: {
                important_notice: "महत्वपूर्ण सूचना",
                please_ensure_your_data_follows_the_template_format_for_successful_upload: "कृपया सुनिश्चित करें कि आपका डेटा सफल अपलोड के लिए टेम्पलेट प्रारूप का पालन करता है। अधिकतम फ़ाइल आकार 10MB है।"
            },
            template_card: {
                download_template: "टेम्पलेट डाउनलोड करें",
                download_our_standardized_template_to_ensure_proper_data_formatting: "उचित डेटा स्वरूप सुनिश्चित करने के लिए हमारा मानकीकृत टेम्पलेट डाउनलोड करें",
                templateFeatures: {
                    preformatted_columns_with_examples: "उदाहरणों के साथ पूर्व-स्वरूपित कॉलम",
                    data_validation_rules: "डेटा सत्यापन नियम",
                    import_guidelines: "आयात दिशानिर्देश",
                    sample_data_entries: "नमूना डेटा प्रविष्टियाँ"
                }
            },
            upload_card: {
                upload_data: "डेटा अपलोड करें",
                click_or_drag_file_to_this_area_to_upload: "अपलोड करने के लिए फ़ाइल को इस क्षेत्र में क्लिक करें या खींचें",
                support_for_excel_and_CSV_files_only: "केवल Excel और CSV फ़ाइलों के लिए समर्थन",
                upload_file: "फ़ाइल अपलोड करें"
            }
        },
        updation: {
            billing_details: {
                modal: {
                    title: "बिलिंग विवरण अपडेट करें"
                },
                fields: {
                    discount: "छूट (%)",
                    shipment_charges: "शिपमेंट शुल्क",
                    total: "कुल",
                    customer_note: "ग्राहक नोट",
                    terms_and_conditions: "नियम और शर्तें"
                },
                placeholders: {
                    discount: "प्रतिशत में छूट दर्ज करें",
                    shipment_charges: "शिपमेंट शुल्क दर्ज करें",
                    customer_note: "ग्राहक के लिए कोई नोट दर्ज करें",
                    terms_and_conditions: "नियम और शर्तें दर्ज करें"
                },
                errors: {
                    discount_required: "छूट आवश्यक है।",
                    shipment_charges_required: "शिपमेंट शुल्क आवश्यक है।",
                    customer_note_required: "ग्राहक नोट आवश्यक है।",
                    terms_conditions_required: "नियम और शर्तें आवश्यक हैं।"
                },
                messages: {
                    update_success: "आदेश विवरण सफलतापूर्वक अपडेट किया गया!",
                    update_fail: "आदेश विवरण अपडेट करने में विफल। कृपया पुनः प्रयास करें।",
                    validation_error: "कृपया हाइलाइट किए गए फ़ील्ड को सही करें।"
                },
                buttons: {
                    update: "अपडेट करें"
                }
            },
            item_details: {
                title: "सेल्स ऑर्डर आइटम्स को अपडेट करें",
                fields: {
                    quantity: "मात्रा",
                    rate: "मूल्य",
                    total: "कुल",
                    tax: "कर"
                },
                placeholders: {
                    quantity: "मात्रा दर्ज करें",
                    rate: "मूल्य दर्ज करें",
                    tax: "कर चुनें"
                },
                errors: {
                    quantity_required: "मात्रा आवश्यक है।",
                    quantity_min: "मात्रा कम से कम 1 होनी चाहिए।",
                    rate_required: "मूल्य आवश्यक है।",
                    rate_min: "मूल्य एक गैर-नकारात्मक संख्या होनी चाहिए।"
                },
                messages: {
                    update_success: "आइटम विवरण सफलतापूर्वक अपडेट किया गया!",
                    update_fail: "आइटम विवरण अपडेट करने में विफल। कृपया पुनः प्रयास करें।",
                    save_success: "नए आइटम्स सफलतापूर्वक सहेजे गए!",
                    save_fail: "नए आइटम्स सहेजने में विफल। कृपया पुनः प्रयास करें।",
                    validation_error: "कृपया हाइलाइट किए गए फ़ील्ड को सही करें।",
                    duplicate_item_warning: "यह आइटम पहले ही जोड़ा जा चुका है।"
                },
                buttons: {
                    update: "अपडेट करें",
                    save: "सहेजें"
                },
                headers: {
                    new_items: "नए आइटम्स"
                }
            }
        },
        single_order_detail: {
            order_detail: "ऑर्डर विवरण",
            order_details: "ऑर्डर के विवरण",
            buyer: "खरीदार",
            so_number: "SO नंबर",
            reference: "संदर्भ",
            so_date: "SO तारीख",
            expected_delivery_date: "अपेक्षित डिलीवरी तारीख",
            item_details: "आइटम के विवरण",
            style: "स्टाइल",
            quantity: "मात्रा",
            rate: "दर",
            tax: "कर",
            total: "कुल",
            bom_details: "बीओएम विवरण",
            material: "सामग्री",
            extra: "अतिरिक्त",
            received_quantity: "प्राप्त मात्रा",
            rejected_quantity: "अस्वीकृत मात्रा",
            issued_quantity: "जारी की गई मात्रा",
            production_details: "उत्पादन विवरण",
            dispatch_details: "डिस्पैच विवरण",
            flow: "प्रवाह",
            pass_quantity: "पास की गई मात्रा",
            billing_details: "बिलिंग विवरण",
            customer_note: "ग्राहक नोट",
            no_customer_note: "कोई ग्राहक नोट प्रदान नहीं किया गया।",
            sub_total: "उप-योग",
            discount: "छूट",
            shipment_charges: "शिपमेंट शुल्क",
            attachments: "संलग्न पीओ फाइलें",
            upload_files: "फाइलें अपलोड करें",
            max_file_size: "अधिकतम फ़ाइल आकार: 5 एमबी",
            delete_icon_alt: "हटाने का आइकन",
            dispatch_date: "डिस्पैच तारीख",
            dispatch_quantity: "डिस्पैच मात्रा"
        },
        departments: {
            cutting: "काटना",
            sewing: "सिलाई",
            finishing: "फिनिशिंग"
        },
        department_not_found: "विभाग नहीं मिला",
        file: "फाइल",
        file_icon_alt: "फाइल आइकन",
        delete_confirm_title: "क्या आप इस फाइल को हटाना चाहते हैं?",
        deletion_cancelled: "हटाना रद्द किया गया",
        delete_success: "सफलतापूर्वक हटाया गया",
        delete_error: "हटाने में विफल",
        try_again: "कृपया पुनः प्रयास करें",
        upload_success: "सफलतापूर्वक अपलोड किया गया!",
        upload_fail: "अपलोड करने में विफल।",
        upload_error: "अपलोड करने में त्रुटि। कृपया पुनः प्रयास करें।",
        invalid_file_format: "अमान्य फाइल प्रारूप।",
        edit_order_detail: {
            edit_order_detail: "आदेश विवरण संपादित करें",
            fields: {
                customer_name: "ग्राहक का नाम",
                branch: "शाखा",
                so_name: "SO नाम",
                reference: "संदर्भ",
                expected_margin: "अपेक्षित मार्जिन",
                merchandiser: "मर्चेंडाइज़र",
                approver: "स्वीकर्ता",
                so_date: "SO तिथि",
                expected_delivery_date: "अपेक्षित वितरण तिथि",
                payment_terms: "भुगतान की शर्तें",
                delivery_type: "वितरण प्रकार",
                product_type: "उत्पाद प्रकार",
                currency: "मुद्रा"
            },
            placeholder: {
                search_or_select_customer: "ग्राहक खोजें या चुनें",
                search_or_select_branch: "शाखा खोजें या चुनें",
                select_branch_so_name_will_display_here: "शाखा चुनें, SO नाम यहां दिखेगा",
                enter_reference: "संदर्भ दर्ज करें",
                enter_expected_margin: "अपेक्षित मार्जिन दर्ज करें",
                select_merchandiser: "मर्चेंडाइज़र चुनें",
                select_approver: "स्वीकर्ता चुनें",
                search_or_select_payment_terms: "भुगतान की शर्तें खोजें या चुनें",
                search_or_select_delivery_type: "वितरण प्रकार खोजें या चुनें",
                search_or_select_product_type: "उत्पाद प्रकार खोजें या चुनें",
                select_currency: "मुद्रा चुनें",
                expected_delivery_date_is_required: "अपेक्षित वितरण तिथि आवश्यक है",
                so_date_is_required: "SO तिथि आवश्यक है",
                expected_margin_is_required: "अपेक्षित मार्जिन आवश्यक है",
                reference_is_required: "संदर्भ आवश्यक है"
            },
            buttons: {
                save: "सहेजें",
                cancel: "रद्द करें"
            }
        }
        
    }
};