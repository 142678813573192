import { useEffect, useState } from "react";
import { getSalesOrderItems } from "../../../../services/orderServiceApi/salesOrderItemApi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setBomSalesOrderItemList,
  setSelectedBom,
  setSelectedYetToCreateBomList,
} from "../../../../redux/groyyo_plus/bom/createBomSlice";
import { RootState } from "../../../../redux/store";
import { IBomSalesOrderItem } from "../../../../types/groyyo_plus/bomSalesOrderItem";
import { sourcingRoute } from "../../../../routes";
import { Button, Skeleton } from "antd";
import palette from "../../../../constants/pallete";
import { BOMCreationType } from "../../../../constants/enums";
import { FaEdit, FaEye } from "react-icons/fa";
import useRolePermission from "../../../../utilities/RolePermission/RolePermissionHook";
import RolePermission from "../../../../utilities/RolePermission/RolePermission";
import { useTranslation } from "react-i18next";
import { IoChevronBackOutline } from "react-icons/io5";

const CreateBOM = () => {
  const { t }: any = useTranslation();

  const { salesOrderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bomSalesOrderItemList, selectedYetToCreateBomList } = useSelector(
    (state: RootState) => state.createBom
  );
  const canCreateBom = useRolePermission("BOM", "CREATE");
  const canEditBom = useRolePermission("BOM", "EDIT");
  const [loading, setLoading] = useState(false);

  const fetchSalesOrderItem = async () => {
    setLoading(true);
    try {
      const response = await getSalesOrderItems({
        salesOrderId: +salesOrderId!,
      });
      const responseData = response?.data;

      if (responseData?.status) {
        const created = responseData.result.filter(
          (item: IBomSalesOrderItem) => item?.bom !== null
        );
        const yetToCreate = responseData.result.filter(
          (item: IBomSalesOrderItem) => item?.bom === null
        );

        dispatch(
          setBomSalesOrderItemList({
            item: created,
            type: BOMCreationType.CREATED,
          })
        );

        dispatch(
          setBomSalesOrderItemList({
            item: yetToCreate,
            type: BOMCreationType.YET_TO_CREATE,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching sales order items:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (salesOrderId) {
      fetchSalesOrderItem();
    }
  }, [salesOrderId]);

  const handleItemClick = (bomSalesOrderItem: IBomSalesOrderItem) => {
    dispatch(setSelectedBom(bomSalesOrderItem));
    navigate(
      `${sourcingRoute}/bom/create-bom-item/${salesOrderId}?type=created`
    );
  };

  const handleCheckboxChange = (id: number) => {
    dispatch(
      setSelectedYetToCreateBomList(
        selectedYetToCreateBomList.includes(id)
          ? selectedYetToCreateBomList.filter((itemId) => itemId !== id)
          : [...selectedYetToCreateBomList, id]
      )
    );
  };

  const handleCreateButtonClick = () => {
    navigate(
      `${sourcingRoute}/bom/create-bom-item/${salesOrderId}?type=yetToCreate`
    );
  };

  return (
    <div>
      <div className="text-lg mb-4 font-bold flex flex-row gap-2 items-center">
        <span
          onClick={() => navigate(`${sourcingRoute}/bom`)}
          className="cursor-pointer"
        >
          <IoChevronBackOutline style={{ fontWeight: "bold" }} />
        </span>
        <h1 className="text-2xl font-bold">{t("sales_order_bom.create_bom.title")}</h1>
      </div>
      <div className="flex flex-col gap-6 px-4">
        {loading ? (
          Array.from({ length: 3 })?.map(() => (
            <Skeleton.Input
              active
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 80,
              }}
            />
          ))
        ) : (
          <>
            {bomSalesOrderItemList?.created?.length > 0 && (
              <section>
                <h2 className="text-lg font-bold mb-2">
                  {t("sales_order_bom.create_bom.already_created_title")}
                </h2>
                <div className="flex flex-col gap-4">
                  {bomSalesOrderItemList?.created?.map((bomSalesOrderItem) => (
                    <div
                      key={bomSalesOrderItem.id}
                      className="flex flex-row items-center justify-between shadow-md p-2 rounded-lg bg-white"
                    >
                      <div className="flex flex-row gap-4">
                        <img
                          src={
                            bomSalesOrderItem?.item?.imageUrl ||
                            "/svg/NoImage.svg"
                          }
                          alt={bomSalesOrderItem?.item?.name}
                          className="!w-[3rem] !h-[3rem] rounded-md"
                        />
                        <div className="flex flex-col justify-start">
                          <p className="font-semibold">
                            {bomSalesOrderItem?.item?.name}
                          </p>
                          <p className="text-xs text-gray-600">
                            {bomSalesOrderItem?.item?.category?.name}/
                            {bomSalesOrderItem?.item?.subCategory?.name}
                          </p>
                        </div>
                      </div>
                      <div className="mr-5 flex gap-4 items-center">
                        <span className="italic text-gray-700">
                          {bomSalesOrderItem?.amount}
                        </span>
                        <span className="italic text-gray-500">
                          {bomSalesOrderItem?.item?.unit?.name}
                        </span>
                        <span
                          className=" cursor-pointer"
                          onClick={() => handleItemClick(bomSalesOrderItem)}
                        >
                          {canEditBom ? (
                            <FaEdit size={20} />
                          ) : (
                            <FaEye size={20} />
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}

            {bomSalesOrderItemList?.yetToCreate?.length > 0 && (
              <section>
                <h2 className="text-lg font-bold mb-2">
                  {t("sales_order_bom.create_bom.yet_to_create_title")}
                </h2>
                <div className="flex flex-col gap-4">
                  {bomSalesOrderItemList?.yetToCreate?.map(
                    (bomSalesOrderItem) => (
                      <div
                        key={bomSalesOrderItem.id}
                        className="flex flex-row items-center justify-between shadow-md p-2 rounded-lg bg-white"
                      >
                        <div className="flex flex-row gap-4 items-center">
                          <RolePermission module="BOM" action="CREATE">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-primary cursor-pointer"
                              checked={selectedYetToCreateBomList.includes(
                                bomSalesOrderItem.id
                              )}
                              style={{
                                accentColor: palette.secondary,
                              }}
                              onChange={() =>
                                handleCheckboxChange(bomSalesOrderItem.id)
                              }
                            />
                          </RolePermission>
                          <img
                            src={
                              bomSalesOrderItem?.item?.imageUrl ||
                              "/svg/NoImage.svg"
                            }
                            alt={bomSalesOrderItem?.item?.name}
                            className="!w-[3rem] !h-[3rem] rounded-md"
                          />
                          <div className="flex flex-col justify-start">
                            <p className="font-semibold">
                              {bomSalesOrderItem?.item?.name}
                            </p>
                            <p className="text-xs text-gray-600">
                              {bomSalesOrderItem?.item?.category?.name}/
                              {bomSalesOrderItem?.item?.subCategory?.name}
                            </p>
                          </div>
                        </div>
                        <div className="mr-5 flex gap-4">
                          <span className="italic text-gray-700">
                            {bomSalesOrderItem?.amount}
                          </span>
                          <span className="italic text-gray-500">
                            {bomSalesOrderItem?.item?.unit?.name}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <RolePermission module="BOM" action="CREATE">
                  <Button
                    className="mt-4 px-4 py-2 bg-primary  rounded-lg cursor-pointer"
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor:
                        selectedYetToCreateBomList.length === 0
                          ? "#d9d9d9"
                          : palette.secondary,
                      width: "100%",
                      color: palette.white,
                      cursor:
                        selectedYetToCreateBomList.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={handleCreateButtonClick}
                    disabled={selectedYetToCreateBomList.length === 0}
                  >
                    {t("sales_order_bom.create_bom.create_button")}
                  </Button>
                </RolePermission>
              </section>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateBOM;
