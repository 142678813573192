import { Button, Form, message, Modal } from "antd";
import CustomDropdown from "../../../../components/CustomDropdown";
import { useEffect, useState } from "react";
import { factoryList } from "../../../../services/userServiceApi/factoriesApi";
import palette from "../../../../constants/pallete";
import { getBranchesForFactory } from "../../../../services/orderServiceApi/branchApi";
import CustomDropdownWithAddById from "../../../../components/CustomDropdownWithAddById";
import { assignFactoryToOrder } from "../../../../services/orderServiceApi/salesOrderApi";

export default function AssignFactory({
  visible,
  handleClose,
  salesOrderId,
}: any) {
  const [factory, setFactory] = useState<any>(null);
  const [branch, setBranch] = useState<any>(null);

  const handleSubmit = async () => {
    const payload = {
      factoryId: factory?.id,
      branchId: branch?.id,
      salesOrderId: salesOrderId,
    };
    if (payload.factoryId && payload.branchId) {
      const response = await assignFactoryToOrder(payload);
      if (response) {
        if (response?.data?.status) {
          message.success(
            response?.data?.message ?? "Factory Assigned Successfully"
          );
          handleClose();
        }
      }
    }
  };
  const [form] = Form.useForm();
  return (
    <Modal
      title={"Assign Factory"}
      open={visible}
      onCancel={handleClose}
      onClose={handleClose}
      footer={null}
      maskClosable={false}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <CustomDropdown
          label={"Factory"}
          placeholder={"Select Factory"}
          fetchItems={factoryList}
          onSelect={(item) => setFactory(item)}
          name="Factory"
          required={true}
          value={factory?.name}
        />

        <CustomDropdownWithAddById
          label={"Branch"}
          placeholder={"Select Branch"}
          fetchItems={() => getBranchesForFactory(factory?.id)}
          onSelect={(item) => setBranch(item)}
          required={true}
          parentId={factory?.id!}
          value={branch?.name}
          addItem={function (data: Record<string, any>): Promise<void> {
            throw new Error("Function not implemented.");
          }}
          showAddNew={false}
          modalFields={[]}
          formItemName={"branch"}
          form={form}
        />

        <Button
          type="primary"
          className="w-full mt-4"
          onClick={handleSubmit}
          style={{
            backgroundColor: palette.secondary,
            borderColor: palette.secondary,
          }}
        >
          Assign
        </Button>
      </Form>
    </Modal>
  );
}
