import { ORDER_SERVICE_API } from ".";
import { UpdateBillingDetailPayload } from "../../pages/groyyo_plus/Order/detail/EditBillingDetail";
import { IOrderUpdateType } from "../../pages/groyyo_plus/Order/detail/EditOrderDetail";
import { BillingFields } from "../../redux/groyyo_plus/order/createSalesOrderSlice";
import { CreateSalesOrderDto } from "../../types/groyyo_plus/salesOrder";
import { CreateItemRequest } from "../../types/groyyo_plus/salesOrderItem";
import { orderApi } from "./orderService";

const salesOrder = ORDER_SERVICE_API.SALES_ORDER;
const item = ORDER_SERVICE_API.ITEM;

export const getSalesOrder = async () => {
  return orderApi.get(`${salesOrder}`);
};

export const getInternalSalesOrder = async (tab: string) => {
  return orderApi.get(`${salesOrder}/internal?status=${tab}`);
};

export const getSalesOrderById = async (orderId: number) => {
  return orderApi.get(`${salesOrder}/${orderId}`);
};

export const createSalesOrder = async (payload: CreateSalesOrderDto) => {
  return orderApi.post(`${salesOrder}`, payload);
};

export const createInternalSalesOrder = async (payload: any) => {
  return orderApi.post(`${salesOrder}/internal`, payload);
};

export const getSoNumber = async (branchId: number) => {
  return orderApi.get(`${salesOrder}/so-number?branchId=${branchId}`);
};

export const getAllCurrencies = async () => {
  return orderApi.get("currency");
};

export const getSalesOrderDetail = async (salesOrderId: number) => {
  return orderApi.get(`${salesOrder}/sales-order-details?id=${salesOrderId}`);
};

export const getSalesOrderStatus = async () => {
  return orderApi.get(`${salesOrder}?status='COMPLETED'`);
};

export const getItems = async () => {
  return orderApi.get(`${item}`);
};

export const getInternalItems = async () => {
  return orderApi.get(`${item}/internal`);
};

export const uploadFile = async (formData: any) => {
  return orderApi.post(`file-upload/upload`, formData);
};

export const createItem = async (payload: CreateItemRequest) => {
  return orderApi.post(`${item}`, payload);
};

export const createBillingDetail = async (payload: BillingFields) => {
  return orderApi.post(`${salesOrder}/add-billing-info`, payload);
};

export const getBomPendingOrders = async () => {
  return orderApi.get(`${salesOrder}/bom-pending-orders`);
};

export const getInternalBomPendingOrders = async () => {
  return orderApi.get(`${salesOrder}/internal/bom-pending-orders`);
};

export const getMaterialReceivablePendingOrders = async () => {
  return orderApi.get(`${salesOrder}/material-receivable-pending-orders`);
};

export const getMaterialIssuedPendingOrders = async () => {
  return orderApi.get(`${salesOrder}/material-issue-pending-orders`);
};

export const deleteAtachmentFile = async (id: number) => {
  return orderApi.delete(`${salesOrder}/sales-order-attachment?id=${id}`);
};

export const updateAtachmentFile = async ({
  salesOrderId,
  body,
}: {
  salesOrderId: number;
  body: any;
}) => {
  return orderApi.put(
    `${salesOrder}/sales-order-attachment?salesOrderId=${salesOrderId}`,
    body
  );
};

export const getSOListing = async () => {
  return orderApi.get(`${salesOrder}/so-filter-list`);
};

export const updateOrderDetail = async ({
  payload,
}: {
  payload: IOrderUpdateType;
}) => {
  return orderApi.put(`${salesOrder}/sales-order-details`, payload);
};

export const updateBillingDetail = async ({
  payload,
}: {
  payload: UpdateBillingDetailPayload;
}) => {
  return orderApi.put(`${salesOrder}/billing-info`, payload);
};

export const assignFactoryToOrder = async (payload: any) => {
  return orderApi.post(`${salesOrder}/internal/assign-factory`, payload);
};
