import React, { useEffect } from "react";
import { Form, Input, Button, DatePicker, message, Row, Col } from "antd";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import palette from "../../../constants/pallete";
import { addInspection } from "../../../services/orderServiceApi/dispatchApi";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAvailableForDispatch } from "../../../redux/groyyo_plus/dispatch/dispatchSlice";

const StartInspectionScreen = () => {
  const { selectedDispatch } = useSelector((state: RootState) => state.dispatch);
  const { t }: any = useTranslation(); 

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { dispatchId, salesOrderItemId } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!selectedDispatch?.id) {
      navigate(`/dispatch`);
    }
  }, [selectedDispatch]);

  const onFinish = async (values: any) => {
    if (!dispatchId) {
      message.error(t("dispatch.startInspection.errors.missingDispatchId"));
      return;
    }

    const { inspectedQuantity, inspectionPassQuantity, inspectionFailQuantity } = values;

    if (
      +inspectedQuantity !==
      (+inspectionPassQuantity || 0) + (+inspectionFailQuantity || 0)
    ) {
      message.error(t("dispatch.startInspection.errors.quantityMismatch"));
      return;
    }

    const formattedValues = {
      ...values,
      inspectedQuantity: +values?.inspectedQuantity,
      inspectionFailQuantity: +values?.inspectionFailQuantity,
      inspectionPassQuantity: +values?.inspectionPassQuantity,
      inspectionDate: values.inspectionDate
        ? moment(values.inspectionDate).format("DD/MM/YYYY")
        : null,
      dispatchId: +dispatchId!,
    };

    try {
      const response = await addInspection(formattedValues);
      if (response?.data?.status) {
        dispatch(fetchAvailableForDispatch({ dispatchId: +dispatchId! }));
        message.success(t("dispatch.startInspection.success.submitSuccess"));
        navigate(`/dispatch/available-dispatch/${dispatchId}/${salesOrderItemId}`);
      } else {
        message.error(response?.data?.message || t("dispatch.startInspection.errors.submitError"));
      }
    } catch (error) {
      console.error("API Error:", error);
      message.error(t("dispatch.startInspection.errors.submitError"));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Form submission failed:", errorInfo);
    message.error(t("dispatch.startInspection.errors.requiredFields"));
  };

  return (
    <div style={{ margin: "auto" }} className="w-full">
      <div className="flex justify-between items-center mb-4">
        <div className="text-2xl font-bold flex flex-row gap-2 items-center w-full">
          <span
            onClick={() => navigate(`/dispatch/available-dispatch/${dispatchId}/${+salesOrderItemId!}`)}
            className="cursor-pointer"
          >
            <IoChevronBackOutline style={{ fontWeight: "bold" }} />
          </span>
          <p>{t("dispatch.startInspection.title")}</p>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          inspectedQuantity: 0,
          inspectionPassQuantity: 0,
          inspectionFailQuantity: 0,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t("dispatch.startInspection.form.inspectedQuantity.label")}
              name="inspectedQuantity"
              rules={[
                {
                  required: true,
                  message: t("dispatch.startInspection.form.inspectedQuantity.required"),
                },
              ]}
            >
              <Input
                type="number"
                min={0}
                placeholder={t("dispatch.startInspection.form.inspectedQuantity.placeholder")}
                autoFocus
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("dispatch.startInspection.form.passedQuantity.label")}
              name="inspectionPassQuantity"
              rules={[
                {
                  required: true,
                  message: t("dispatch.startInspection.form.passedQuantity.required"),
                },
              ]}
            >
              <Input
                type="number"
                min={0}
                placeholder={t("dispatch.startInspection.form.passedQuantity.placeholder")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t("dispatch.startInspection.form.failedQuantity.label")}
              name="inspectionFailQuantity"
              rules={[
                {
                  required: true,
                  message: t("dispatch.startInspection.form.failedQuantity.required"),
                },
              ]}
            >
              <Input
                type="number"
                min={0}
                placeholder={t("dispatch.startInspection.form.failedQuantity.placeholder")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("dispatch.startInspection.form.date.label")}
              name="inspectionDate"
              rules={[
                {
                  required: true,
                  message: t("dispatch.startInspection.form.date.required"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("dispatch.startInspection.form.date.placeholder")}
                format="DD/MM/YYYY"
                disabledDate={(current) => current && current < dayjs().startOf("day")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ backgroundColor: palette.secondary }}
          >
            {t("dispatch.startInspection.submitButton")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StartInspectionScreen;