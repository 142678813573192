import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Item } from "../../../types/groyyo_plus/salesOrderItem";
import { CreateSalesOrderTabEnum } from "../../../constants/enums";

export type BillingFields = {
    subTotal: number;
    discount: number;
    shipmentCharges: number;
    total: number;
    customerNote: string;
    termsAndCondition: string;
};

interface ICreateSalesOrderSlice {
    orderDetail: {
        buyer: { id: number; name: string } | undefined;
        branch: { id: number; name: string } | undefined;
        SoNumber: string;
        reference: string;
        expectedMargin: number;
        merchandiser: { id: number; name: string } | undefined;
        approver: { id: number; name: string } | undefined;
        soDate: string;
        expectedDate: string;
        paymentTerm: { id: number; name: string } | undefined;
        deliveryType: { id: number; name: string } | undefined;
        productType: { id: number; name: string } | undefined;
        currency: { id: number; name: string } | undefined;
    },
    itemDetail: {
        itemListDetail: {
            itemList: Item[],
        },
        item: {
            unit: { id: number; name: string } | undefined;
            category: { id: number; name: string } | undefined;
            subCategory: { id: number; name: string } | undefined;
            name: string;
            hsnCode: string;
            imageUrl: string | null;
        }
    };
    billingDetail: BillingFields & {
        attachements: Array<{
            url: string
            name: string
            mime: string
        }>
    };
    activeSalesOrderTab: CreateSalesOrderTabEnum;
    submissionStatus: 'idle' | 'loading' | 'success' | 'error';
}

const initialState: ICreateSalesOrderSlice = {
    activeSalesOrderTab: CreateSalesOrderTabEnum.ORDER_DETAILS,
    billingDetail: {
        subTotal: 0,
        discount: 0,
        shipmentCharges: 0,
        total: 0,
        customerNote: "",
        termsAndCondition: "",
        attachements: []
    },
    itemDetail: {
        itemListDetail: {
            itemList: [],
        },
        item: {
            unit: undefined,
            category: undefined,
            subCategory: undefined,
            name: "",
            hsnCode: "",
            imageUrl: null,
        }
    },
    orderDetail: {
        buyer: undefined,
        branch: undefined,
        SoNumber: "",
        reference: "",
        expectedMargin: 0,
        merchandiser: undefined,
        approver: undefined,
        soDate: "",
        expectedDate: "",
        paymentTerm: undefined,
        deliveryType: undefined,
        productType: undefined,
        currency: undefined,
    },
    submissionStatus: 'idle'
};

const createSalesOrderSlice = createSlice({
    name: "createSalesOrder",
    initialState,
    reducers: {
        setActiveSalesOrderTab: (state, action: PayloadAction<CreateSalesOrderTabEnum>) => {
            state.activeSalesOrderTab = action.payload
        },
        setBuyer: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.buyer = action.payload;
        },
        setBranch: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.branch = action.payload;
        },
        setSoNumber: (state, action: PayloadAction<string>) => {
            state.orderDetail.SoNumber = action.payload;
        },
        setReference: (state, action: PayloadAction<string>) => {
            state.orderDetail.reference = action.payload;
        },
        setExpectedMargin: (state, action: PayloadAction<number>) => {
            state.orderDetail.expectedMargin = action.payload;
        },
        setMerchandiser: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.merchandiser = action.payload;
        },
        setApprover: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.approver = action.payload;
        },
        setSoDate: (state, action: PayloadAction<string>) => {
            state.orderDetail.soDate = action.payload;
        },
        setExpectedDate: (state, action: PayloadAction<string>) => {
            state.orderDetail.expectedDate = action.payload;
        },
        setPaymentTerm: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.paymentTerm = action.payload;
        },
        setDeliveryType: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.deliveryType = action.payload;
        },
        setProductType: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.productType = action.payload;
        },
        setCurrency: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.orderDetail.currency = action.payload;
        },
        resetSubmissionStatus: (state) => {
            state.orderDetail = initialState.orderDetail;
        },
        setItemWithId: (state, action: PayloadAction<{ id: number; name: string; field: "unit" | "category" | "subCategory" }>) => {
            const { id, name, field } = action.payload;
            state.itemDetail.item[field] = { id, name };
        },
        setItem: (state, action: PayloadAction<{ name: string; field: "name" | "hsnCode" | "imageUrl" }>) => {
            const { name, field } = action.payload;
            state.itemDetail.item[field] = name;
        },
        resetItem: (state) => {
            state.itemDetail.item = initialState.itemDetail.item
        },
        setItemList: (state, action: PayloadAction<Item>) => {
            state.itemDetail.itemListDetail.itemList.push(action.payload)
        },
        clearItemList: (state) => {
            state.itemDetail.itemListDetail = initialState.itemDetail.itemListDetail
        },
        setItemListDetail: (
            state,
            action: PayloadAction<{ itemId: number; field: "quantity" | "rate" | "tax"; value: any }>
        ) => {
            const { itemId, field, value } = action.payload;

            const itemIndex = state.itemDetail.itemListDetail.itemList.findIndex((i) => i.id === itemId);

            if (itemIndex !== -1) {
                if (field === "tax" && value.id && value.name) {
                    state.itemDetail.itemListDetail.itemList[itemIndex].tax = {
                        id: value.id,
                        name: value.name,
                        percentage: value.percentage
                    };
                } else {
                    state.itemDetail.itemListDetail.itemList[itemIndex][field] = value;
                }

                const item = state.itemDetail.itemListDetail.itemList[itemIndex];
                const quantity = item.quantity || 0;
                const rate = item.rate || 0;
                const baseTotal = quantity * rate;

                const taxPercentage = item.tax?.percentage || 0;
                item.total = baseTotal + (baseTotal * (taxPercentage / 100));
            }
        },
        setBillingFields: (state, action: PayloadAction<{ value: BillingFields[keyof BillingFields]; field: keyof BillingFields }>) => {
            const { field, value } = action.payload;
            state.billingDetail[field] = value as never;
                    
            const { subTotal, discount, shipmentCharges } = state.billingDetail;
            const discountAmount = (subTotal || 0) * ((discount || 0) / 100);
            state.billingDetail.total = (subTotal || 0) - discountAmount + (shipmentCharges || 0);
        },
        setBillingAttachments: (state, action: PayloadAction<Array<{ url: string; name: string; mime: string }>>) => {
            state.billingDetail.attachements = action.payload;
        },
        clearBillingDetails: (state) => {
            state.billingDetail = initialState.billingDetail;
        }
    }
});


export const {
    setActiveSalesOrderTab,
    setBuyer,
    setBranch,
    setSoNumber,
    setReference,
    setExpectedMargin,
    setMerchandiser,
    setApprover,
    setSoDate,
    setExpectedDate,
    setPaymentTerm,
    setDeliveryType,
    setProductType,
    setCurrency,
    resetSubmissionStatus,
    setItem,
    setItemWithId,
    resetItem,
    clearItemList, setItemList,
    setItemListDetail,
    setBillingAttachments, 
    setBillingFields,
    clearBillingDetails
} = createSalesOrderSlice.actions;

export default createSalesOrderSlice.reducer;