import { Button, DatePicker, Form, Input, InputNumber, message } from 'antd';
import { CreateSalesOrderTabEnum, SalesOrderCreationStatus } from '../../../../constants/enums';
import { createBranch, getBranches } from '../../../../services/orderServiceApi/branchApi';
import { createCustomer, getCustomers } from '../../../../services/orderServiceApi/customersApi';
import { createDeliveryType, getDeliveryTypes } from '../../../../services/orderServiceApi/deliveryTypesApi';
import { createPaymentTerm, getPaymentTerms } from '../../../../services/orderServiceApi/paymentTermApi';
import { createProductType, getProductTypes } from '../../../../services/orderServiceApi/productTypesApi';
import { createSalesOrder, getAllCurrencies, getSoNumber } from '../../../../services/orderServiceApi/salesOrderApi';
import dayjs, { Dayjs } from 'dayjs';
import { resetSubmissionStatus, setActiveSalesOrderTab, setApprover, setBranch, setBuyer, setCurrency, setDeliveryType, setExpectedDate, setExpectedMargin, setMerchandiser, setPaymentTerm, setProductType, setReference, setSoDate, setSoNumber } from '../../../../redux/groyyo_plus/order/createSalesOrderSlice';

import { CreateSalesOrderDto } from '../../../../types/groyyo_plus/salesOrder';
import CustomDropdown from '../../../../components/CustomDropdown';
import CustomDropdownWithAdd from '../../../../components/CustomDropdownWithAdd';
import { RootState } from '../../../../redux/store';
import { getUsers } from '../../../../services/userServiceApi/authApi';
import palette from '../../../../constants/pallete';
import { sourcingRoute } from '../../../../routes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const orderDetail = useSelector((state: RootState) => state.createSalesOrder.orderDetail);

  const [form] = Form.useForm();
  const {t}:any = useTranslation();

  const handleSoDateChange = (date: Dayjs | null) => {
    const formattedDate = date ? date.format('DD/MM/YYYY') : '';
    dispatch(setSoDate(formattedDate));
  };

  const handleExpectedDeliveryDateChange = (date: Dayjs | null) => {
    const formattedDate = date ? date.format('DD/MM/YYYY') : '';
    dispatch(setExpectedDate(formattedDate));
  };
  const handleReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReference(e.target.value));
  };

  const fetchSoName = async (branchId: number) => {
    try {
      const response: any = await getSoNumber(branchId);

      const responseData = response?.data;
      if (responseData?.status) {
        dispatch(setSoNumber(responseData?.result));
      }
    } catch (error) {
      console.error(t('sales_order.creation.order_detail.errors.fetch_so_name_failed'), error);
    }
  };

  useEffect(() => {
    if (orderDetail?.branch?.id) {
      fetchSoName(orderDetail?.branch?.id);
    }
  }, [orderDetail?.branch?.id]);

  const handleMarginChange = (value: number | null) => {
    if (value !== null) {
      dispatch(setExpectedMargin(value));
    }
  };

  const createItemWrapper = (createFunction: (data: any) => Promise<any>) => {
    return async (data: any) => {
      await createFunction(data);
    };
  };


  const handleOrderDetailsSubmission = async () => {
    const dto: CreateSalesOrderDto = {
      buyerId: orderDetail.buyer?.id || 0,
      branchId: orderDetail.branch?.id || 0,
      SoNumber: orderDetail.SoNumber,
      reference: orderDetail.reference,
      soDate: orderDetail.soDate,
      expectedDate: orderDetail.expectedDate,
      paymentTermId: orderDetail.paymentTerm?.id || 0,
      deliveryTypeId: orderDetail.deliveryType?.id || 0,
      merchandiserId: orderDetail.merchandiser?.id || 0,
      merchandiser: orderDetail.merchandiser?.name || "",
      approverId: orderDetail.approver?.id || 0,
      approver: orderDetail.approver?.name || "",
      productTypeId: orderDetail.productType?.id || 0,
      expectedMargin: orderDetail.expectedMargin,
      currencyId: orderDetail.currency?.id || 0
    };
    
    try {
      const response = await createSalesOrder(dto);
      const responseData = response?.data;

      if(responseData?.status){
        message.success(responseData?.message || t('sales_order.creation.order_detail.messages.success'));
        dispatch(resetSubmissionStatus());
        navigate(`${sourcingRoute}/sales-order/create`, { state: { status: SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING, id: responseData?.result?.id } });
        dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.ITEM_DETAILS))
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOrderDetailsSubmission}
        initialValues={{
          reference: orderDetail.reference,
          expectedMargin: orderDetail.expectedMargin,
          soDate: orderDetail.soDate ? dayjs(orderDetail.soDate) : null,
          expectedDate: orderDetail.expectedDate ? dayjs(orderDetail.expectedDate) : null,
        }}
        className="flex flex-col gap-4"
      >
        <div className="grid grid-cols-2 gap-4">
          <CustomDropdownWithAdd
            form={form}
            label={t('sales_order.creation.order_detail.fields.customer_name')}
            placeholder={t('sales_order.creation.order_detail.placeholders.customer_name')}
            fetchItems={getCustomers}
            addItem={createItemWrapper(createCustomer)}
            onSelect={(item) => dispatch(setBuyer({ id: item.id, name: item.name }))}
            modalFields={[
              { name: 'name', label: 'Customer Name', required: true }
            ]}
            formItemName='buyer'
            required={true}
            value={orderDetail.buyer?.name}
          />

          <CustomDropdownWithAdd
            form={form}
            label={t('sales_order.creation.order_detail.fields.branch')}
            placeholder={t('sales_order.creation.order_detail.placeholders.branch')}
            fetchItems={getBranches}
            addItem={createItemWrapper(createBranch)}
            onSelect={(item) => dispatch(setBranch({ id: item.id, name: item.name }))}
            modalFields={[
              { name: 'name', label: 'Branch Name', required: true },
              { name: 'shortHand', label: 'Branch Code', required: true }
            ]}
            formItemName='branch'
            required={true}
            value={orderDetail.branch?.name}
          />

          <Form.Item label={t('sales_order.creation.order_detail.fields.so_name')} required>
            <Input
              type="text"
              value={orderDetail.SoNumber}
              readOnly
              placeholder={t('sales_order.creation.order_detail.placeholders.so_name')}
              className="w-full border-gray-300 rounded-md p-2 bg-gray-100 cursor-not-allowed h-10"
            />
          </Form.Item>

          <Form.Item
            label={t('sales_order.creation.order_detail.fields.reference')}
            name="reference"
            rules={[{ required: true, message: t('sales_order.creation.order_detail.errors.reference_required') }]}
          >
            <Input
              placeholder={t('sales_order.creation.order_detail.placeholders.reference')}
              onChange={handleReferenceChange}
              className="w-full border-gray-300 rounded-md p-2 "
              value={orderDetail.reference}
            />
          </Form.Item>

          <Form.Item
            label={t('sales_order.creation.order_detail.fields.expected_margin')}
            name="expectedMargin"
            rules={[{ required: true, message: t('sales_order.creation.order_detail.errors.expected_margin_required') }]}
          >
            <InputNumber
              min={0}
              onChange={handleMarginChange}
              placeholder={t('sales_order.creation.order_detail.placeholders.expected_margin')}
              className="w-full border-gray-300 rounded-md p-1"
              style={{ width: '100%' }}
              value={orderDetail.expectedMargin}
            />
          </Form.Item>

          <CustomDropdown
            label={t('sales_order.creation.order_detail.fields.merchandiser')}
            placeholder={t('sales_order.creation.order_detail.placeholders.merchandiser')}
            fetchItems={getUsers}
            onSelect={(item) => dispatch(setMerchandiser({ id: item.id, name: item.name }))}
            name="merchandiser"
            required={true}
            value={orderDetail.merchandiser?.name}
          />

          <CustomDropdown
            label={t('sales_order.creation.order_detail.fields.approver')}
            placeholder={t('sales_order.creation.order_detail.placeholders.approver')}
            fetchItems={getUsers}
            onSelect={(item) => dispatch(setApprover({ id: item.id, name: item.name }))}
            name="approver"
            required={true}
            value={orderDetail.approver?.name}
          />


          <Form.Item
            label={t('sales_order.creation.order_detail.fields.so_date')}
            name="soDate"
            rules={[{ required: true, message: t('sales_order.creation.order_detail.errors.so_date_required') }]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={orderDetail.soDate ? dayjs(orderDetail.soDate) : null}
              onChange={handleSoDateChange}
              className="w-full border border-gray-300 rounded-md"
              style={{ height: '40px', padding: '0 11px', width: '100%' }}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day');
              }}
            />
          </Form.Item>

          <Form.Item
            label={t('sales_order.creation.order_detail.fields.expected_delivery_date')}
            name="expectedDeliveryDate"
            rules={[{ required: true, message: t('sales_order.creation.order_detail.errors.expected_delivery_date_required') }]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={orderDetail.expectedDate ? dayjs(orderDetail.expectedDate) : null}
              onChange={handleExpectedDeliveryDateChange}
              className="w-full border border-gray-300 rounded-md"
              style={{ height: '40px', padding: '0 11px', width: '100%' }}
              disabledDate={(current) => {
                return current && current < dayjs().startOf('day');
              }}
            />
          </Form.Item>


          <CustomDropdownWithAdd
            form={form}
            label={t('sales_order.creation.order_detail.fields.payment_terms')}
            placeholder={t('sales_order.creation.order_detail.placeholders.payment_terms')}
            fetchItems={getPaymentTerms}
            addItem={createItemWrapper(createPaymentTerm)}
            onSelect={(item) => dispatch(setPaymentTerm({ id: item.id, name: item.name }))}
            modalFields={[
              { name: 'name', label: 'Name', required: true },
              { name: 'advancePercentage', label: 'Advance Percentage', required: true },
              { name: 'dueDay', label: 'Due Day', required: false },
            ]}
            formItemName='paymentTerms'
            required={true}
            value={orderDetail.paymentTerm?.name}
          />

          <CustomDropdownWithAdd
            form={form}
            label={t('sales_order.creation.order_detail.fields.delivery_type')}
            placeholder={t('sales_order.creation.order_detail.placeholders.delivery_type')}
            fetchItems={getDeliveryTypes}
            addItem={createItemWrapper(createDeliveryType)}
            onSelect={(item) => dispatch(setDeliveryType({ id: item.id, name: item.name }))}
            modalFields={[
              { name: 'name', label: 'Delivery Type Name', required: true }
            ]}
            formItemName='deliveryType'
            required={true}
            value={orderDetail.deliveryType?.name}
          />

          <CustomDropdownWithAdd
            form={form}
            label={t('sales_order.creation.order_detail.fields.product_type')}
            placeholder={t('sales_order.creation.order_detail.placeholders.product_type')}
            fetchItems={getProductTypes}
            addItem={createItemWrapper(createProductType)}
            onSelect={(item) => dispatch(setProductType({ id: item.id, name: item.name }))}
            modalFields={[
              { name: 'name', label: 'Product Type Name', required: true }
            ]}
            formItemName='productType'
            required={true}
            value={orderDetail.productType?.name}
          />

          <CustomDropdown
            label={t('sales_order.creation.order_detail.fields.currency')}
            placeholder={t('sales_order.creation.order_detail.placeholders.currency')}
            fetchItems={getAllCurrencies}
            onSelect={(item) => dispatch(setCurrency({ id: item.id, name: item.name }))}
            name="currency"
            required={true}
            value={orderDetail.currency?.name}
          />
        </div>
        <div className='flex justify-center items-center'>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-[5rem]"
              style={{ backgroundColor: palette.secondary, color: "white" }}
            >
              {t('sales_order.creation.order_detail.buttons.save')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default OrderDetails;