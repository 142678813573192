import { jwtDecode } from 'jwt-decode';
import { useMemo } from 'react';

interface Role {
  id: number;
  name: string;
}

interface DecodedToken {
  role?: Role;
  [key: string]: any; 
}

const useRole = (): {
  role: Role | null;
  isGroyyoMerchandiser: boolean;
} => {
  const authToken = localStorage.getItem('authToken');

  const role = useMemo(() => {
    if (!authToken) {
      return null;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(authToken);
      return decodedToken?.role || null;
    } catch (error) {
      console.error('Failed to decode auth token:', error);
      return null;
    }
  }, [authToken]);
  
  const modifiedRole: Role | null = role
    ? {
        id: role.id,
        name: role.name,
      }
    : null;
  
  const isGroyyoMerchandiser = modifiedRole?.name === 'GROYYO_MERCHANDISER';

  return { role: modifiedRole, isGroyyoMerchandiser };
};

export default useRole;