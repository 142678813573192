import React, { useState, useEffect } from "react";
import { Modal, Input, Select, List, Button } from "antd";
import {
  IBomItemForm,
  IFabric,
  ITrim,
} from "../../../../types/groyyo_plus/bomItem";
import {
  createFabric,
  getFabricList,
  getInternalFabricList,
} from "../../../../services/orderServiceApi/fabricApi";
import {
  createTrim,
  getInternalTrimList,
  getTrimList,
} from "../../../../services/orderServiceApi/trimApi";
import palette from "../../../../constants/pallete";
import CreateNewMaterial from "./CreateNewMaterial";
import { BOMCreationType } from "../../../../constants/enums";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import useRole from "../../../../hooks/useRole";

const { Option } = Select;

interface AddMaterialModalProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (selectedMaterial: IBomItemForm) => void;
  bomType: BOMCreationType;
}

const AddMaterialModal: React.FC<AddMaterialModalProps> = ({
  visible,
  onClose,
  onAdd,
  bomType,
}) => {
  const [materialList, setMaterialList] = useState<IFabric[] | ITrim[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState<"Fabric" | "Trim">("Fabric");
  const [filteredMaterials, setFilteredMaterials] = useState<
    IFabric[] | ITrim[]
  >([]);
  const [isNewMaterialModalVisible, setIsNewMaterialModalVisible] =
    useState(false);
  const { t }: any = useTranslation();
  const { isGroyyoMerchandiser } = useRole();

  useEffect(() => {
    if (visible) {
      fetchMaterials(selectedType);
    }
  }, [visible, selectedType]);

  const fetchMaterials = async (type: "Fabric" | "Trim") => {
    try {
      let response: any = [];
      if (type === "Fabric") {
        if (isGroyyoMerchandiser) {
          response = await getInternalFabricList();
        } else {
          response = await getFabricList();
        }
        const fabrics = response?.data?.result || [];
        setMaterialList(fabrics);
        setFilteredMaterials(fabrics);
      } else if (type === "Trim") {
        if (isGroyyoMerchandiser) {
          response = await getInternalTrimList();
        } else {
          response = await getTrimList();
        }
        const trims = response?.data?.result || [];
        setMaterialList(trims);
        setFilteredMaterials(trims);
      }
    } catch (error) {
      console.error(`Error fetching ${type} list:`, error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (selectedType === "Fabric") {
      const filtered = (materialList as IFabric[]).filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMaterials(filtered);
    } else if (selectedType === "Trim") {
      const filtered = (materialList as ITrim[]).filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMaterials(filtered);
    }
  };

  const handleTypeChange = (value: "Fabric" | "Trim") => {
    setSelectedType(value);
    fetchMaterials(value);
  };

  const handleAddMaterial = () => {
    setIsNewMaterialModalVisible(true);
  };

  const resetState = () => {
    setSelectedType("Fabric");
    setMaterialList([]);
    setSearchTerm("");
    setFilteredMaterials([]);
  };

  const handleClose = () => {
    onClose();
    resetState();
  };

  const handleNewMaterialSubmit = async (data: any) => {
    try {
      if (data?.type === "Fabric") {
        const payload = {
          name: data?.name,
          description: data?.description,
          composition: data?.composition,
          imageUrl: data?.imageUrl,
          unitId: data?.unitId,
          type: data?.type,
          trimSizeId: data?.trimSizeId,
          trimColorId: data?.trimColorId,
        };
        const response: any = await createFabric(payload);
        const responseData = response?.data;

        if (responseData?.status) {
          fetchMaterials("Fabric");
        }
      } else if (data?.type === "Trim") {
        const payload = {
          name: data?.name,
          description: data?.description,
          imageUrl: data?.imageUrl,
          unitId: data?.unitId,
          type: data?.type,
          trimSizeId: data?.trimSizeId,
          trimColorId: data?.trimColorId,
        };
        const response: any = await createTrim(payload);
        const responseData = response?.data;

        if (responseData?.status) {
          fetchMaterials("Trim");
        }
      }
      setIsNewMaterialModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMaterialSelection = (item: IFabric | ITrim) => {
    const transformStructure: IBomItemForm = {
      id: uuidv4(),
      quantity: "",
      currency: item?.currency || { id: -1, name: "", code: "" },
      extra: "",
      average: "",
      rate: "",
      inHouseDate: item?.inHouseDate ?? "",
      unit: item?.unit || { id: -1, name: "" },
      supplier: item?.supplier || { id: -1, name: "" },
      materialReceivable: null,
      total: 0,
      material:
        item?.material?.type === "Fabric"
          ? {
              type: "Fabric",
              id: item?.material?.id,
              fabric: {
                id: item?.id,
                name: item?.name,
                description: (item as IFabric)?.description || null,
                imageUrl: item?.imageUrl!,
                composition: (item as IFabric)?.composition,
              },
            }
          : {
              type: "Trim",
              id: item?.material?.id,
              trim: {
                id: item?.id!,
                name: item?.name!,
                description: item?.description || null,
                imageUrl: item?.imageUrl!,
              },
            },
      error: {
        quantity: false,
        unit: false,
        supplier: false,
        currency: false,
        extra: false,
        average: false,
        rate: false,
        inHouseDate: false,
      },
    };
    return onAdd(transformStructure);
  };

  return (
    <>
      <Modal
        title={t("sales_order_bom.add_material_modal.title")}
        open={visible}
        onCancel={handleClose}
        footer={null}
        maskClosable={false}
      >
        <div className="flex flex-col gap-4">
          <Input
            placeholder={t(
              "sales_order_bom.add_material_modal.search_placeholder"
            )}
            value={searchTerm}
            onChange={handleSearchChange}
            allowClear
          />
          <Select
            placeholder={t(
              "sales_order_bom.add_material_modal.type_placeholder"
            )}
            value={selectedType}
            onChange={handleTypeChange}
            className="w-full"
          >
            <Option value="Fabric">
              {t("sales_order_bom.add_material_modal.type_fabric")}
            </Option>
            <Option value="Trim">
              {t("sales_order_bom.add_material_modal.type_trim")}
            </Option>
          </Select>
        </div>
        <div
          style={{
            maxHeight: "20rem",
            overflowY: "auto",
            marginTop: "1rem",
          }}
        >
          <List
            dataSource={filteredMaterials as (IFabric | ITrim)[]}
            renderItem={(item) => (
              <List.Item
                className="flex items-center justify-between cursor-pointer"
                onClick={() => handleMaterialSelection(item)}
              >
                <div className="flex items-center gap-4">
                  <img
                    src={item.imageUrl || "/svg/NoImage.svg"}
                    alt={item.name}
                    className="w-12 h-12 rounded-md object-cover"
                  />
                  <div>
                    <p className="font-medium">{item.name}</p>
                    {selectedType === "Fabric" ? (
                      <p className="text-xs text-gray-500">
                        {(item as IFabric).composition}
                      </p>
                    ) : (
                      <p className="text-xs text-gray-500">
                        {(item as ITrim).description}
                      </p>
                    )}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <div className="flex justify-between mt-4">
          <Button
            type="primary"
            onClick={handleAddMaterial}
            style={{
              backgroundColor: palette.secondary,
              borderColor: palette.secondary,
              width: "100%",
            }}
          >
            {t("sales_order_bom.add_material_modal.add_new_button")}
          </Button>
        </div>
      </Modal>

      <CreateNewMaterial
        visible={isNewMaterialModalVisible}
        onClose={() => setIsNewMaterialModalVisible(false)}
        onSubmit={handleNewMaterialSubmit}
      />
    </>
  );
};

export default AddMaterialModal;
