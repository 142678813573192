import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSalesOrderDetail } from '../services/orderServiceApi/salesOrderApi';
import { setSingleSalesOrder } from '../redux/groyyo_plus/order/singleSalesOrderSlice';

const useFetchSalesOrderDetail = (salesOrderId: number | undefined) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  useEffect(() => {
    const fetchDetail = async () => {
      if (!salesOrderId) return;

      setStatus('loading');
      try {
        const response = await getSalesOrderDetail(salesOrderId);
        const responseData = response?.data;

        if (responseData?.status) {
          dispatch(setSingleSalesOrder(responseData?.result));
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error fetching sales order detail:', error);
        setStatus('error');
      }
    };

    fetchDetail();
  }, [salesOrderId, dispatch]);

  return status;
};

export default useFetchSalesOrderDetail;