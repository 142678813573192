import BillingDetails from "../pages/groyyo_plus/Order/create/billingDetail/BillingDetails";
import { CreateSalesOrderTabEnum } from "../constants/enums";
import CustomTab from "./CustomTab";
import InternalBillingDetails from "../pages/groyyo_merchendaiser/Order/create/billingDetail/InternalBillingDetails";
import InternalItemDetails from "../pages/groyyo_merchendaiser/Order/create/itemDetail/InternalItemDetails";
import InternalSalesOrderCreation from "../pages/groyyo_merchendaiser/Order/create/orderDetail/InternalSalesOrderCreation";
import ItemDetails from "../pages/groyyo_plus/Order/create/itemDetail/ItemDetails";
import OrderDetails from "../pages/groyyo_plus/Order/create/OrderDeatils";
import React from "react";
import { RootState } from "../redux/store";
import { setActiveSalesOrderTab } from "../redux/groyyo_merchendaiser/internalMerchandiserSalesOrderSlice";
import useRole from "../hooks/useRole";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MultiStepForm = () => {
  const activeTab = useSelector(
    (state: RootState) => state.createSalesOrder.activeSalesOrderTab
  );
  const activeInternalTab = useSelector(
    (state: RootState) =>
      state.internalMerchandiserSalesOrderSlice.activeSalesOrderTab
  );
  const { t }: any = useTranslation();
  const { isGroyyoMerchandiser } = useRole();
  const tabs = [
    {
      label: t("sales_order.creation.steps.order_detail"),
      content: isGroyyoMerchandiser ? (
        <InternalSalesOrderCreation />
      ) : (
        <OrderDetails />
      ),
      tabEnum: CreateSalesOrderTabEnum.ORDER_DETAILS,
    },
    {
      label: t("sales_order.creation.steps.item_detail"),
      content: isGroyyoMerchandiser ? <InternalItemDetails /> : <ItemDetails />,
      tabEnum: CreateSalesOrderTabEnum.ITEM_DETAILS,
    },
    {
      label: t("sales_order.creation.steps.billing_detail"),
      content: isGroyyoMerchandiser ? (
        <InternalBillingDetails />
      ) : (
        <BillingDetails />
      ),
      tabEnum: CreateSalesOrderTabEnum.BILLING_DETAILS,
    },
  ];

  return (
    <div className="w-full p-4">
      <CustomTab
        tabs={tabs}
        activeTab={isGroyyoMerchandiser ? activeInternalTab : activeTab}
        setActiveTabAction={(tab) => setActiveSalesOrderTab(tab)}
      />
    </div>
  );
};

export default MultiStepForm;
