import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
const fabricUrl = ORDER_SERVICE_API.FABRIC;

export const getFabricList = async () => {
  return orderApi.get(`${fabricUrl}/list`);
};

export const getInternalFabricList = async () => {
  return orderApi.get(`${fabricUrl}/internal`);
};

export const createFabric = async (payload: any) => {
  return orderApi.post(`${fabricUrl}/create`, payload);
};

export const getFabricColorList = async () => {
  return orderApi.get(`${fabricUrl}/color-list`);
};

export const createFabricColor = async (payload: {
  name: string;
  hexCode: string;
}) => {
  return orderApi.post(`${fabricUrl}/color-create`, payload);
};

export const getFabricSizeList = async () => {
  return orderApi.get(`${fabricUrl}/size-list`);
};

export const createFabricSize = async (payload: { name: string }) => {
  return orderApi.post(`${fabricUrl}/size-create`, payload);
};
