import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { internalRoute, sourcingRoute } from ".";
import { isAuthenticated, restructurePermissions } from "../utilities/helper";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { Layout } from "antd";
import Login from "../pages/Login";
import ModuleRoutes from "./ModuleRoutes";
import Profile from "../pages/groyyo_plus/Profile";
import ProtectedRoute from "../pages/ProtectedRoute";
import Sidebar from "../components/Sidebar";
import { getUserDetail } from "../services/userServiceApi/authApi";
import { useDispatch } from "react-redux";
import useRole from "../hooks/useRole";
const { Content } = Layout;

const AppContent: React.FC = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const navigate = useNavigate();
  const [selectedModule, setSelectedModule] = useState(2);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const fetchUserDetails = useCallback(async () => {
    try {
      if (!isAuthenticated()) return;
      const response = await getUserDetail();
      if (response?.data?.result) {
        const permissionSet = restructurePermissions(
          response?.data?.result?.userVerticals
        );

        if(Object?.entries(permissionSet)?.length > 0){
          localStorage.setItem("userPermissionState", JSON.stringify(permissionSet));
          localStorage.setItem("permissionsLoaded", "true");
        }
      }
    } catch (error) {
      console.error("Failed to fetch user details.");
    }
  }, [dispatch]);

  const {isGroyyoMerchandiser} = useRole();

  useEffect(() => {
    if(isGroyyoMerchandiser) {
      setSelectedModule(3);
      navigate(`${internalRoute}/sales-order`);
    }else {
      navigate(`${sourcingRoute}/dashboard`);
    }
  }, [])

  useEffect(() => {
    if(isAuthenticated()){
      fetchUserDetails();
    }
  }, [fetchUserDetails]);

  const headerHeight = "4rem";

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!isLoginPage && (
        <Sidebar
          selectedModule={selectedModule}
          collapsed={collapsed}
          onToggle={() => setCollapsed(!collapsed)}
          headerHeight={headerHeight}
        />
      )}

      <Layout
        style={{
          marginLeft: isLoginPage ? "0" : collapsed ? "5rem" : "12.5rem",
          transition: "margin-left 0.3s ease",
        }}
      >
        <Header
          setSelectedModule={setSelectedModule}
          selectedModule={selectedModule}
          headerHeight={headerHeight}
        />

        <Content
          style={{
            padding: "1.25rem",
            paddingTop: "4rem",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Routes>
            <Route path="/login" element={<Login headerHeight={headerHeight} />} />
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="profile" element={<Profile />} />
              <Route
                path="*"
                element={<ModuleRoutes selectedModule={selectedModule} />}
              />
            </Route>
          </Routes>
        </Content>

        {isAuthenticated() && <Footer />}
      </Layout>
    </Layout>
  );
};

export default AppContent;
