import { UploadOutlined } from '@ant-design/icons';
import { Collapse, Button, Upload, Modal, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoChevronBackOutline } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import palette from '../../../../constants/pallete';
import { internalRoute } from '../../../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import EditInternalSalesOrderDetail from './EditInternalSalesOrderDetail';
import { setInternalSingleSalesOrder } from '../../../../redux/groyyo_merchendaiser/internalSingleSalesOrderSlice';
import { deleteAtachmentFile, getSalesOrderDetail, updateAtachmentFile, uploadFile } from '../../../../services/orderServiceApi/salesOrderApi';
import EditInternalBillingDetail from './EditInternalBillingDetail';

const { Panel } = Collapse;
const { confirm } = Modal;


const InternalSingleSalesOrderDetail = () => {
    const { t }: any = useTranslation();
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const departments = [
        { id: 1, name: t("sales_order.departments.cutting") },
        { id: 2, name: t("sales_order.departments.sewing") },
        { id: 3, name: t("sales_order.departments.finishing") },
    ];

    const singleSalesOrder = useSelector(
        (state: RootState) => state.internalSingleSalesOrder
    );

    const [attachments, setAttachments] = useState(singleSalesOrder?.attachments || []);

    useEffect(() => {
        setAttachments(singleSalesOrder?.attachments || []);
    }, [singleSalesOrder]);

    const fetchSalesOrderDetail = async (salesOrderId: number) => {
        setLoading(true);
        try {
            const response = await getSalesOrderDetail(salesOrderId);
            const responseData = response?.data;

            if (responseData?.status) {
                dispatch(setInternalSingleSalesOrder(responseData?.result));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (orderId) {
            fetchSalesOrderDetail(+orderId);
        }
    }, [orderId]);

    const getDepartmentNameById = (id: number) => {
        const department = departments.find((dept) => dept.id === id);
        return department ? department.name : t("sales_order.department_not_found");
    };

    const navigateToEditItemDetail = () => {
        navigate(`${internalRoute}/sales-order/edit`)
    }

    const getFileIcon = (mimeType: string): string => {
        switch (mimeType) {
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
                return "/svg/JPGFile.svg";
            case "application/pdf":
                return "/svg/PDFFile.svg";
            case "application/msword":
                return "/svg/WordFile.svg";
            default:
                return "/svg/UnknownFile.svg";
        }
    };

    const renderFileType = (file?: { mime: string; name: string }) => {
        const iconPath = getFileIcon(file?.mime ?? "");
        return (
            <div className="flex gap-2 items-center">
                <img
                    src={iconPath}
                    alt={t("sales_order.file_icon_alt")}
                    className="w-[3rem] h-[3rem] object-contain rounded-lg transition-opacity duration-500 ease-in-out"
                />
                <div>{file?.name}</div>
            </div>
        );
    };


    const handleDeleteFile = async (index: number, attachment: any) => {
        try {
            if (attachment.id) {
                await deleteAtachmentFile(attachment.id);
                message.success(`${t("sales_order.file")} ${attachment.name} ${t("sales_order.delete_success")}`);
            }
            setAttachments((prev: any) => prev.filter((_: any, i: number) => i !== index));
        } catch (error) {
            message.error(`${t("sales_order.delete_error")} ${attachment.name}. ${t("sales_order.try_again")}`);
            console.error(error);
        }
    };

    const confirmDelete = (index: number, attachment: any) => {
        confirm({
            title: t("sales_order.delete_confirm_title"),
            content: `${t("sales_order.file")}: ${attachment.name}`,
            okText: t("global.yes"),
            okType: "danger",
            cancelText: t("global.no"),
            onOk: () => handleDeleteFile(index, attachment),
            onCancel: () => {
                message.info(t("sales_order.deletion_cancelled"));
            },
        });
    };

    const handleFileUpload = async (fileList: File[]) => {
        const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "application/msword"];
    
        const invalidFiles = fileList.filter(
          (file) =>
            file.size > 5 * 1024 * 1024 || !allowedTypes.includes(file.type)
        );
    
        if (invalidFiles.length > 0) {
          invalidFiles.forEach((file) => {
            if (file.size > 5 * 1024 * 1024) {
              message.error(`${file.name} ${t("sales_order.max_file_size")}`);
            } else {
              message.error(`${file.name} ${t("sales_order.invalid_file_format")}`);
            }
          });
          return Upload.LIST_IGNORE;
        }
    
        for (const file of fileList) {
          const formData = new FormData();
          formData.append("file", file);
    
          try {
            const response = await uploadFile(formData);
            const responseData = response?.data;
            const uploadedUrl = responseData?.result?.imageUrl;
    
            if (uploadedUrl) {
              const newAttachment = {
                name: file.name,
                mime: file.type,
                url: uploadedUrl,
              };
    
              await updateAtachmentFile({
                salesOrderId: +orderId!,
                body: {
                  url: uploadedUrl,
                  name: file.name,
                  mime: file.type,
                },
              }).then(() => {
                setAttachments((prev: any) => [...prev, newAttachment]);
              });
    
              message.success(`${file.name} ${t("sales_order.upload_success")}`);
            } else {
              message.error(`${file.name} ${t("sales_order.upload_fail")}`);
            }
          } catch (error) {
            message.error(`${file.name} ${t("sales_order.upload_error")}`);
            console.error(error);
          }
        }
    
        return false;
      };

    return (
        <div>
            <div className="text-lg font-bold flex flex-row gap-2 items-center">
                <span
                    onClick={() => navigate(`${internalRoute}/sales-order`)}
                    className="cursor-pointer"
                >
                    <IoChevronBackOutline style={{ fontWeight: "bold" }} />
                </span>
                <p>{t("sales_order.single_order_detail.order_detail")}</p>
            </div>

            <div className="flex flex-col gap-6 p-4">
                <div className="bg-white rounded-xl p-6 shadow-md">
                    <div className="flex w-full justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">{t("sales_order.single_order_detail.order_details")}</h2>
                        <EditInternalSalesOrderDetail fetchSalesOrderDetail={fetchSalesOrderDetail} />
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-sm text-gray-600">
                                <strong>{t("sales_order.single_order_detail.buyer")}:</strong>
                            </p>
                            <p className="text-base font-medium">{singleSalesOrder?.buyer?.name}</p>

                            <p className="text-sm text-gray-600 mt-4">
                                <strong>{t("sales_order.single_order_detail.so_number")}:</strong>
                            </p>
                            <p className="text-base font-medium">{singleSalesOrder?.soNo}</p>

                            <p className="text-sm text-gray-600 mt-4">
                                <strong>{t("sales_order.single_order_detail.reference")}:</strong>
                            </p>
                            <p className="text-base font-medium">{singleSalesOrder?.reference}</p>
                        </div>

                        <div>
                            <p className="text-sm text-gray-600">
                                <strong>{t("sales_order.single_order_detail.so_date")}:</strong>
                            </p>
                            <p className="text-base font-medium">{singleSalesOrder?.soDate}</p>

                            <p className="text-sm text-gray-600 mt-4">
                                <strong>{t("sales_order.single_order_detail.expected_delivery_date")}:</strong>
                            </p>
                            <p className="text-base font-medium">{singleSalesOrder?.expectedDate}</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col bg-white p-6 gap-4 rounded-xl shadow-md">
                    <div className="flex w-full justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">{t("sales_order.single_order_detail.item_details")}</h2>
                        <MdEdit className="cursor-pointer text-gray-600 text-2xl" onClick={navigateToEditItemDetail} />
                    </div>


                    {singleSalesOrder?.salesOrderItems?.map((item: any) => (
                        <div key={item?.id} className="py-2 bg-[#F3F4F6] px-4 border rounded-lg">
                            <div className="grid grid-cols-5 gap-4 text-center font-bold mb-2">
                                <p>{t("sales_order.single_order_detail.style")}</p>
                                <p>{t("sales_order.single_order_detail.quantity")}</p>
                                <p>{t("sales_order.single_order_detail.rate")}</p>
                                <p>{t("sales_order.single_order_detail.tax")}</p>
                                <p>{t("sales_order.single_order_detail.total")}</p>
                            </div>
                            <div className="grid grid-cols-5 gap-4 text-center items-center">
                                <p>{item?.item.name}</p>
                                <p>{item?.quantity}</p>
                                <p>{item?.rate}</p>
                                <p>{item?.tax?.name}</p>
                                <p>{item?.amount}</p>
                            </div>

                            {item?.bom?.bomItems?.length > 0 && (
                                <Collapse className="mt-4" expandIconPosition="end" style={{ backgroundColor: palette.white }}>
                                    <Panel header={<span className="font-semibold text-gray-700">{t("sales_order.single_order_detail.bom_details")}</span>} key="1">
                                        {item?.bom?.bomItems?.map((bomItem: any) => (
                                            <div key={bomItem.id} className="bg-gray-100 p-4 rounded-lg mb-4 flex flex-col gap-4">
                                                <div className="">
                                                    <div className="grid grid-cols-5 gap-4 text-center font-semibold mb-2">
                                                        <p>{t("sales_order.single_order_detail.material")}</p>
                                                        <p>{t("sales_order.single_order_detail.rate")}</p>
                                                        <p>{t("sales_order.single_order_detail.quantity")}</p>
                                                        <p>{t("sales_order.single_order_detail.extra")}</p>
                                                        <p>{t("sales_order.single_order_detail.total")}</p>
                                                    </div>

                                                    <div className="grid grid-cols-5 gap-4 text-center items-center">
                                                        <p>{bomItem?.material?.fabric?.name || bomItem?.material?.trim?.name}</p>
                                                        <p>{bomItem?.rate}</p>
                                                        <p>{bomItem?.quantity}</p>
                                                        <p>{bomItem?.extra}</p>
                                                        <p>{bomItem?.total}</p>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className="grid grid-cols-3 gap-4 text-center font-semibold mb-2">
                                                        <p>{t("sales_order.single_order_detail.received_quantity")}</p>
                                                        <p>{t("sales_order.single_order_detail.rejected_quantity")}</p>
                                                        <p>{t("sales_order.single_order_detail.issued_quantity")}</p>
                                                    </div>

                                                    <div className="grid grid-cols-3 gap-4 text-center items-center">
                                                        <p>{bomItem?.materialReceivable?.receivedQuantity || 0}</p>
                                                        <p>{bomItem?.materialReceivable?.rejectedQuantity || 0}</p>
                                                        <p>{bomItem?.materialReceivable?.issuedQuantity || 0}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Panel>
                                </Collapse>
                            )}

                            {item?.orderFlows?.length > 0 && (
                                <Collapse className="mb-4 mt-4" expandIconPosition="end" style={{ backgroundColor: palette.white }}>
                                    <Collapse.Panel
                                        header={<span className="font-semibold text-gray-700">{t("sales_order.single_order_detail.production_details")}</span>}
                                        key="1"
                                    >
                                        {item?.orderFlows?.map((flow: any, index: number) => (
                                            <div
                                                key={flow?.id}
                                                className="mb-4 p-4 rounded-lg shadow-sm border border-gray-200 bg-gray-100"
                                            >
                                                <p className="text-sm font-bold text-gray-600 mb-2">
                                                    {t("sales_order.single_order_detail.flow")} {index + 1}
                                                </p>

                                                <div className="bg-blue-100 p-3 rounded-md text-sm font-semibold text-gray-700 mb-4">
                                                    {getDepartmentNameById(flow?.departmentId)}
                                                </div>

                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <p className="text-sm font-semibold text-gray-600 mb-1">
                                                            {t("sales_order.single_order_detail.pass_quantity")}
                                                        </p>
                                                        <p className="text-base font-medium">{flow?.completedQuantity || 0}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-semibold text-gray-600 mb-1">
                                                            {t("sales_order.single_order_detail.rejected_quantity")}
                                                        </p>
                                                        <p className="text-base font-medium">{flow?.rejectedQuantity || 0}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Collapse.Panel>
                                </Collapse>
                            )}

                            {item?.dispatches?.length > 0 && (
                                <Collapse className="mb-4 mt-4" expandIconPosition="end" style={{ backgroundColor: palette.white }}>
                                    <Collapse.Panel
                                        header={<span className="font-semibold text-gray-700">{t("sales_order.single_order_detail.dispatch_details")}</span>}
                                        key="1"
                                    >
                                        {item?.dispatches?.map((dis: any) => {
                                            return (
                                                <div className="flex flex-col gap-2 bg-gray-100 p-2 rounded-lg" key={dis?.id} >
                                                    <div className="flex flex-row justify-between w-full">
                                                        <p className="font-semibold">{t(`sales_order.single_order_detail.dispatch_date`)}</p>
                                                        <p>{moment(item?.dispatchedDate).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                    <div className="flex flex-row justify-between w-full">
                                                        <p className="font-semibold">{t(`sales_order.single_order_detail.dispatch_quantity`)}</p>
                                                        <p className="text-center">{dis?.dispatchedQuantity ?? "-"}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Collapse.Panel>
                                </Collapse>
                            )}
                        </div>
                    ))}
                </div>

                {/* Billing Details Section */}
                <div className="flex flex-col bg-white p-6 rounded-xl shadow-md">
                    <div className="flex w-full justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">{t("sales_order.single_order_detail.billing_details")}</h2>
                        <EditInternalBillingDetail fetchSalesOrderDetail={fetchSalesOrderDetail} />
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                        <div className="border-r pr-4">
                            <p className="text-sm text-gray-600 font-semibold mb-2">
                                {t("sales_order.single_order_detail.customer_note")}:
                            </p>
                            <p className="text-base font-medium text-gray-800">
                                {singleSalesOrder?.customerNote || t("sales_order.single_order_detail.no_customer_note")}
                            </p>
                        </div>

                        <div>
                            <div className="flex justify-between items-center mb-2">
                                <p className="text-sm text-gray-600"><strong>{t("sales_order.single_order_detail.sub_total")}:</strong></p>
                                <p className="text-base font-medium">{singleSalesOrder?.currency?.symbol}{singleSalesOrder?.subTotal}</p>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                                <p className="text-sm text-gray-600"><strong>{t("sales_order.single_order_detail.discount")}:</strong></p>
                                <p className="text-base font-medium">{singleSalesOrder?.discount}%</p>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                                <p className="text-sm text-gray-600"><strong>{t("sales_order.single_order_detail.shipment_charges")}:</strong></p>
                                <p className="text-base font-medium">{singleSalesOrder?.currency?.symbol}{singleSalesOrder?.shipmentCharges}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="text-sm text-gray-600"><strong>{t("sales_order.single_order_detail.total")}:</strong></p>
                                <p className="text-base font-bold text-gray-600">
                                    {singleSalesOrder?.currency?.symbol}{singleSalesOrder?.total}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Attachments Section */}
                <div>
                    <div className="flex flex-col gap-4 bg-white p-6 rounded-xl shadow-md">
                        <h2 className="text-lg font-bold mb-2">{t("sales_order.single_order_detail.attachments")}</h2>
                        {attachments.map((attachment: any, index: number) => (
                            <div
                                key={attachment.id || index}
                                className="bg-gray-100 p-4 rounded-lg mb-4 flex items-center justify-between"
                            >
                                {renderFileType(attachment)}
                                <Button
                                    type="link"
                                    style={{ color: palette.error }}
                                    onClick={() => confirmDelete(index, attachment)}
                                >
                                    <img
                                        src={"/svg/Delete.svg"}
                                        alt={t("sales_order.single_order_detail.delete_icon_alt")}
                                        className="w-[1rem] h-[1rem] transition-opacity duration-500 ease-in-out"
                                    />
                                </Button>
                            </div>
                        ))}
                        <div>
                            <Upload
                                multiple
                                accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
                                customRequest={({ file }) => handleFileUpload([file as File])}
                                onDrop={({ dataTransfer }) => handleFileUpload(Array.from(dataTransfer.files))}
                                beforeUpload={(file) => {
                                    handleFileUpload([file as File]);
                                    return false;
                                }}
                                showUploadList={false}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    style={{
                                        backgroundColor: palette.secondary,
                                        color: "white",
                                    }}
                                >
                                    {t("sales_order.single_order_detail.upload_files")}
                                </Button>
                            </Upload>
                            <p className="text-xs p-2 font-medium">{t("sales_order.single_order_detail.max_file_size")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InternalSingleSalesOrderDetail