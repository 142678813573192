export enum ROUTES {
    ONBOARDING = '/onBoarding',
    SOURCING = '/sourcing',
    INTERNAL = '/internal'
}


const onBoardingRoute = ROUTES.ONBOARDING;
const sourcingRoute = ROUTES.SOURCING;
const internalRoute = ROUTES.INTERNAL;

export {
    onBoardingRoute,
    sourcingRoute,
    internalRoute
}