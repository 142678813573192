import { Button, DatePicker, Form, Input, InputNumber, message } from "antd";
import {
  createCustomer,
  getInternalCustomers,
} from "../../../../../services/orderServiceApi/customersApi";
import {
  createDeliveryType,
  getInternalDeliveryTypes,
} from "../../../../../services/orderServiceApi/deliveryTypesApi";
import {
  createPaymentTerm,
  getInternalPaymentTerms,
} from "../../../../../services/orderServiceApi/paymentTermApi";
import {
  createProductType,
  getInternalProductTypes,
} from "../../../../../services/orderServiceApi/productTypesApi";
import dayjs, { Dayjs } from "dayjs";
import {
  resetSubmissionStatus,
  setActiveSalesOrderTab,
  setApprover,
  setBuyer,
  setBuyerPoNumber,
  setCodeAsPerZoho,
  setCurrency,
  setDeliveryType,
  setExpectedDate,
  setExpectedMargin,
  setGroyyoUniqueId,
  setMerchandiser,
  setPaymentTerm,
  setProductType,
  setReference,
  setSoDate,
} from "../../../../../redux/groyyo_merchendaiser/internalMerchandiserSalesOrderSlice";

import CustomDropdown from "../../../../../components/CustomDropdown";
import CustomDropdownWithAdd from "../../../../../components/CustomDropdownWithAdd";
import { RootState } from "../../../../../redux/store";
import { createItemWrapper } from "../../../../../utilities/helper";
import {
  createInternalSalesOrder,
  getAllCurrencies,
} from "../../../../../services/orderServiceApi/salesOrderApi";
import { getUsers } from "../../../../../services/userServiceApi/authApi";
import palette from "../../../../../constants/pallete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { internalRoute } from "../../../../../routes";
import {
  CreateSalesOrderTabEnum,
  SalesOrderCreationStatus,
} from "../../../../../constants/enums";

const InternalSalesOrderCreation = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetail = useSelector(
    (state: RootState) => state.internalMerchandiserSalesOrderSlice.orderDetail
  );

  const { t }: any = useTranslation();

  const handleSoDateChange = (date: Dayjs | null) => {
    const formattedDate = date ? date.format("DD/MM/YYYY") : "";
    dispatch(setSoDate(formattedDate));
  };

  const handleExpectedDeliveryDateChange = (date: Dayjs | null) => {
    const formattedDate = date ? date.format("DD/MM/YYYY") : "";
    dispatch(setExpectedDate(formattedDate));
  };
  const handleReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReference(e.target.value));
  };

  const handleGroyyoUniqueIdChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setGroyyoUniqueId(e.target.value));
  };

  const handleCodeAsPerZohoChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setCodeAsPerZoho(e.target.value));
  };

  const handleBuyerPoNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setBuyerPoNumber(e.target.value));
  };

  const handleMarginChange = (value: number | null) => {
    if (value !== null) {
      dispatch(setExpectedMargin(value));
    }
  };

  const handleOrderDetailsSubmission = async () => {
    const body = {
      buyerId: orderDetail.buyer?.id,

      reference: orderDetail.reference,
      soDate: orderDetail.soDate,
      expectedDate: orderDetail.expectedDate,
      paymentTermId: orderDetail.paymentTerm?.id || 0,
      deliveryTypeId: orderDetail.deliveryType?.id || 0,
      merchandiser: orderDetail.merchandiser?.name || null,
      merchandiserId: orderDetail.merchandiser?.id || null,
      approverId: orderDetail.approver?.id || null,
      approver: orderDetail.approver?.id || null,
      productTypeId: orderDetail.productType?.id || 0,
      expectedMargin: orderDetail.expectedMargin,
      currencyId: orderDetail.currency?.id,
      groyyoUniqueId: orderDetail.groyyoUniqueId,
      codeAsPerZoho: orderDetail.codeAsPerZoho,
      buyerPoNumber: orderDetail.buyerPoNumber,
    };

    try {
      const response = await createInternalSalesOrder(body);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(
          responseData?.message ||
            t("sales_order.creation.order_detail.messages.success")
        );
        dispatch(resetSubmissionStatus());
        navigate(`${internalRoute}/sales-order/create`, {
          state: {
            status: SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING,
            id: responseData?.result?.salesOrder?.id,
          },
        });
        dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.ITEM_DETAILS));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOrderDetailsSubmission}
        initialValues={{
          reference: orderDetail.reference,
          expectedMargin: orderDetail.expectedMargin,
          soDate: orderDetail.soDate ? dayjs(orderDetail.soDate) : null,
          expectedDate: orderDetail.expectedDate
            ? dayjs(orderDetail.expectedDate)
            : null,
        }}
        className="flex flex-col gap-4"
      >
        <div className="grid grid-cols-2 gap-4">
          <CustomDropdownWithAdd
            form={form}
            label={t("sales_order.creation.order_detail.fields.customer_name")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.customer_name"
            )}
            fetchItems={getInternalCustomers}
            addItem={createItemWrapper(createCustomer)}
            onSelect={(item) =>
              dispatch(setBuyer({ id: item.id, name: item.name }))
            }
            modalFields={[
              { name: "name", label: "Customer Name", required: true },
            ]}
            formItemName="buyer"
            required={true}
            value={orderDetail.buyer?.name}
          />

          <Form.Item
            label={t("sales_order.creation.order_detail.fields.reference")}
            name="reference"
            rules={[
              {
                required: true,
                message: t(
                  "sales_order.creation.order_detail.errors.reference_required"
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                "sales_order.creation.order_detail.placeholders.reference"
              )}
              onChange={handleReferenceChange}
              className="w-full border-gray-300 rounded-md p-2 "
              value={orderDetail.reference}
            />
          </Form.Item>

          <Form.Item
            label={t(
              "sales_order.creation.order_detail.fields.groyyo_unique_id"
            )}
            name="groyyoUniqueId"
            rules={[
              {
                required: true,
                message: t(
                  "sales_order.creation.order_detail.errors.groyyo_unique_id_required"
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                "sales_order.creation.order_detail.placeholders.groyyo_unique_id"
              )}
              onChange={handleGroyyoUniqueIdChange}
              className="w-full border-gray-300 rounded-md p-2"
              value={orderDetail.groyyoUniqueId}
            />
          </Form.Item>

          <Form.Item
            label={t(
              "sales_order.creation.order_detail.fields.code_as_per_zoho"
            )}
            name="codeAsPerZoho"
            rules={[
              {
                required: true,
                message: t(
                  "sales_order.creation.order_detail.errors.code_as_per_zoho_required"
                ),
              },
            ]}
          >
            <Input
              placeholder={t(
                "sales_order.creation.order_detail.placeholders.code_as_per_zoho"
              )}
              onChange={handleCodeAsPerZohoChange}
              className="w-full border-gray-300 rounded-md p-2"
              value={orderDetail.codeAsPerZoho}
            />
          </Form.Item>

          <Form.Item
            label={t(
              "sales_order.creation.order_detail.fields.buyer_po_number"
            )}
            name="buyerPoNumber"
            rules={[
              {
                required: false,
                // message: t(
                //   "sales_order.creation.order_detail.errors.buyer_po_number_required"
                // ),
              },
            ]}
          >
            <Input
              placeholder={t(
                "sales_order.creation.order_detail.placeholders.buyer_po_number"
              )}
              onChange={handleBuyerPoNumberChange}
              className="w-full border-gray-300 rounded-md p-2"
              value={orderDetail.buyerPoNumber}
            />
          </Form.Item>

          <Form.Item
            label={t(
              "sales_order.creation.order_detail.fields.expected_margin"
            )}
            name="expectedMargin"
            rules={[
              {
                required: true,
                message: t(
                  "sales_order.creation.order_detail.errors.expected_margin_required"
                ),
              },
            ]}
          >
            <InputNumber
              min={0}
              onChange={handleMarginChange}
              placeholder={t(
                "sales_order.creation.order_detail.placeholders.expected_margin"
              )}
              className="w-full border-gray-300 rounded-md p-1"
              style={{ width: "100%" }}
              value={orderDetail.expectedMargin}
            />
          </Form.Item>

          <CustomDropdown
            label={t("sales_order.creation.order_detail.fields.merchandiser")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.merchandiser"
            )}
            fetchItems={getUsers}
            onSelect={(item) =>
              dispatch(setMerchandiser({ id: item.id, name: item.name }))
            }
            name="merchandiser"
            required={false}
            value={orderDetail.merchandiser?.name}
          />

          <CustomDropdown
            label={t("sales_order.creation.order_detail.fields.approver")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.approver"
            )}
            fetchItems={getUsers}
            onSelect={(item) =>
              dispatch(setApprover({ id: item.id, name: item.name }))
            }
            name="approver"
            required={false}
            value={orderDetail.approver?.name}
          />

          <Form.Item
            label={t("sales_order.creation.order_detail.fields.so_date")}
            name="soDate"
            rules={[
              {
                required: true,
                message: t(
                  "sales_order.creation.order_detail.errors.so_date_required"
                ),
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={orderDetail.soDate ? dayjs(orderDetail.soDate) : null}
              onChange={handleSoDateChange}
              className="w-full border border-gray-300 rounded-md"
              style={{ height: "40px", padding: "0 11px", width: "100%" }}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>

          <Form.Item
            label={t(
              "sales_order.creation.order_detail.fields.expected_delivery_date"
            )}
            name="expectedDeliveryDate"
            rules={[
              {
                required: true,
                message: t(
                  "sales_order.creation.order_detail.errors.expected_delivery_date_required"
                ),
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={
                orderDetail.expectedDate
                  ? dayjs(orderDetail.expectedDate)
                  : null
              }
              onChange={handleExpectedDeliveryDateChange}
              className="w-full border border-gray-300 rounded-md"
              style={{ height: "40px", padding: "0 11px", width: "100%" }}
              disabledDate={(current) => {
                return current && current < dayjs().startOf("day");
              }}
            />
          </Form.Item>

          <CustomDropdownWithAdd
            form={form}
            label={t("sales_order.creation.order_detail.fields.payment_terms")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.payment_terms"
            )}
            fetchItems={getInternalPaymentTerms}
            addItem={createItemWrapper(createPaymentTerm)}
            onSelect={(item) =>
              dispatch(setPaymentTerm({ id: item.id, name: item.name }))
            }
            modalFields={[
              { name: "name", label: "Name", required: true },
              {
                name: "advancePercentage",
                label: "Advance Percentage",
                required: true,
              },
              { name: "dueDay", label: "Due Day", required: false },
            ]}
            formItemName="paymentTerms"
            required={true}
            value={orderDetail.paymentTerm?.name}
          />

          <CustomDropdownWithAdd
            form={form}
            label={t("sales_order.creation.order_detail.fields.delivery_type")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.delivery_type"
            )}
            fetchItems={getInternalDeliveryTypes}
            addItem={createItemWrapper(createDeliveryType)}
            onSelect={(item) =>
              dispatch(setDeliveryType({ id: item.id, name: item.name }))
            }
            modalFields={[
              { name: "name", label: "Delivery Type Name", required: true },
            ]}
            formItemName="deliveryType"
            required={true}
            value={orderDetail.deliveryType?.name}
          />

          <CustomDropdownWithAdd
            form={form}
            label={t("sales_order.creation.order_detail.fields.product_type")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.product_type"
            )}
            fetchItems={getInternalProductTypes}
            addItem={createItemWrapper(createProductType)}
            onSelect={(item) =>
              dispatch(setProductType({ id: item.id, name: item.name }))
            }
            modalFields={[
              { name: "name", label: "Product Type Name", required: true },
            ]}
            formItemName="productType"
            required={true}
            value={orderDetail.productType?.name}
          />

          <CustomDropdown
            label={t("sales_order.creation.order_detail.fields.currency")}
            placeholder={t(
              "sales_order.creation.order_detail.placeholders.currency"
            )}
            fetchItems={getAllCurrencies}
            onSelect={(item) =>
              dispatch(setCurrency({ id: item.id, name: item.name }))
            }
            name="currency"
            required={true}
            value={orderDetail.currency?.name}
          />
        </div>
        <div className="flex justify-center items-center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-[5rem]"
              style={{ backgroundColor: palette.secondary, color: "white" }}
            >
              {t("sales_order.creation.order_detail.buttons.save")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default InternalSalesOrderCreation;
