import { ORDER_SERVICE_API } from ".";
import { orderApi } from "./orderService";

const supplierUrl = ORDER_SERVICE_API.SUPPLIER;

export const getSupplierList = async () => {
  return orderApi.get(`${supplierUrl}/list`);
};

export const getInternalSupplierList = async () => {
  return orderApi.get(`${supplierUrl}/internal`);
};

export const createSupplier = async (payload: any) => {
  return orderApi.post(`${supplierUrl}/create`, payload);
};
