import { Button, Form, Input, Upload, message } from "antd";
import {
  clearBillingDetails,
  setBillingAttachments,
  setBillingFields,
} from "../../../../../redux/groyyo_merchendaiser/internalMerchandiserSalesOrderSlice";

import { BillingFields } from "../../../../../redux/groyyo_plus/order/createSalesOrderSlice";
import React, { useEffect } from "react";
import { RootState } from "../../../../../redux/store";
import { UploadOutlined } from "@ant-design/icons";
import palette from "../../../../../constants/pallete";
import { roundNumber } from "../../../../../utilities/helper";
import {
  createBillingDetail,
  uploadFile,
} from "../../../../../services/orderServiceApi/salesOrderApi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useFetchSalesOrderDetail from "../../../../../hooks/useFetchSalesOrderDetail";
import { internalRoute } from "../../../../../routes";
import { useNavigate } from "react-router-dom";

const InternalBillingDetails = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t }: any = useTranslation();

  const billingDetail = useSelector(
    (state: RootState) =>
      state.internalMerchandiserSalesOrderSlice.billingDetail
  );
  const singleSalesOrder: any = useSelector(
    (state: RootState) => state.singleSalesOrder
  );

  const navigate = useNavigate();

  const fetchStatus = useFetchSalesOrderDetail(Number(singleSalesOrder?.id));

  useEffect(() => {
    if (fetchStatus === "success") {
      let overall = 0;
      singleSalesOrder?.salesOrderItems?.forEach((i: any) => {
        overall += i?.amount;
      });
      dispatch(setBillingFields({ field: "subTotal", value: overall }));
    }
  }, [fetchStatus, singleSalesOrder, dispatch]);

  const handleFieldChange = (
    field: keyof BillingFields,
    value: string | number
  ) => {
    dispatch(setBillingFields({ field, value: Number(value) }));
  };

  const handleFileUpload = async (fileList: File[]) => {
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "application/msword",
    ];

    const invalidFiles = fileList.filter(
      (file) => file.size > 5 * 1024 * 1024 || !allowedTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        if (file.size > 5 * 1024 * 1024) {
          message.error(
            t("sales_order.creation.billing_details.errors.file_size", {
              file: file.name,
            })
          );
        } else {
          message.error(
            t("sales_order.creation.billing_details.errors.file_format", {
              file: file.name,
            })
          );
        }
      });
      return Upload.LIST_IGNORE;
    }

    for (const file of fileList) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await uploadFile(formData);
        const responseData = response?.data;
        const uploadedUrl = responseData?.result?.imageUrl;

        if (uploadedUrl) {
          const newAttachment = {
            name: file.name,
            mime: file.type,
            url: uploadedUrl,
          };

          dispatch(
            setBillingAttachments([
              ...billingDetail.attachements,
              newAttachment,
            ])
          );

          message.success(
            t("sales_order.creation.billing_details.messages.file_uploaded", {
              file: file.name,
            })
          );
        } else {
          message.error(
            t("sales_order.creation.billing_details.errors.upload_failed", {
              file: file.name,
            })
          );
        }
      } catch (error) {
        message.error(
          t("sales_order.creation.billing_details.errors.upload_network", {
            file: file.name,
          })
        );
        console.error(error);
      }
    }

    return false;
  };

  const handleRemoveFile = (fileName: string) => {
    const updatedAttachments = billingDetail.attachements.filter(
      (file) => file.name !== fileName
    );
    dispatch(setBillingAttachments(updatedAttachments));
  };

  const handleFinish = async (values: any) => {
    if (
      !billingDetail.attachements ||
      billingDetail.attachements.length === 0
    ) {
      message.error(t("sales_order.creation.billing_details.errors.attach_po"));
      return;
    }

    const body = {
      ...values,
      attachments: billingDetail.attachements,
      salesOrderId: singleSalesOrder?.id,
      subTotal: +billingDetail?.subTotal || 0,
      discount: +values?.discount || 0,
      shipmentCharges: +values?.shipmentCharges || 0,
      total: billingDetail?.total,
      customerNote: values?.customerNote || "",
      termsAndConditions: values?.termsAndCondition || "",
    };

    try {
      const response = await createBillingDetail(body);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(
          responseData?.message ??
            t("sales_order.creation.billing_details.messages.order_created")
        );
        dispatch(clearBillingDetails());
        navigate(`${internalRoute}/sales-order`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFileIcon = (mimeType: string): string => {
    switch (mimeType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return "/svg/JPGFile.svg";
      case "application/pdf":
        return "/svg/PDFFile.svg";
      case "application/msword":
        // case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return "/svg/WordFile.svg";
      default:
        return "/svg/UnknownFile.svg";
    }
  };

  const renderFileType = (file?: { mime: string; name: string }) => {
    const iconPath = getFileIcon(file?.mime ?? "");
    return (
      <div className="flex gap-2 items-center">
        <img
          src={iconPath}
          alt="File Icon"
          className="w-[3rem] h-[3rem] object-contain rounded-lg transition-opacity duration-500 ease-in-out"
        />
        <div>{file?.name}</div>
      </div>
    );
  };

  return (
    <div className="p-2">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          discount: 0,
          shipmentCharges: 0,
        }}
        className="flex flex-col gap-4"
      >
        <div className="flex">
          <div className="flex flex-col justify-between flex-1 p-2">
            <div className="flex flex-row justify-between w-full mb-4">
              <div>
                {t("sales_order.creation.billing_details.fields.sub_total")}
              </div>
              <div className="flex flex-row gap-2">
                <span>{roundNumber(billingDetail?.subTotal)}</span>
                <span>{singleSalesOrder?.currency?.code}</span>
              </div>
            </div>

            <Form.Item
              label={t("sales_order.creation.billing_details.fields.discount")}
              layout="horizontal"
              name="discount"
              labelAlign="left"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  validator: (_, value) => {
                    if (value === undefined || value === "") {
                      return Promise.resolve();
                    }
                    const discount = parseFloat(value);
                    if (isNaN(discount)) {
                      return Promise.reject(
                        new Error(t("Please enter a valid number."))
                      );
                    }
                    if (discount < 0 || discount > 100) {
                      return Promise.reject(
                        new Error(t("Range is 0 and 100."))
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <span className="flex">
                <Input
                  placeholder={t(
                    "sales_order.creation.billing_details.placeholders.discount"
                  )}
                  onChange={(e) =>
                    handleFieldChange("discount", e.target.value)
                  }
                  style={{
                    border: "none",
                    borderBottom: "2px solid #ccc",
                    borderRadius: 0,
                    textAlign: "right",
                  }}
                  className="custom-input"
                />
                <span>%</span>
              </span>
            </Form.Item>

            <Form.Item
              label={t(
                "sales_order.creation.billing_details.fields.shipment_charges"
              )}
              name="shipmentCharges"
              layout="horizontal"
              labelAlign="left"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <span className="flex">
                <Input
                  placeholder={t(
                    "sales_order.creation.billing_details.placeholders.shipment_charges"
                  )}
                  onChange={(e) =>
                    handleFieldChange("shipmentCharges", e.target.value)
                  }
                  style={{
                    border: "none",
                    borderBottom: "2px solid #ccc",
                    borderRadius: 0,
                    textAlign: "right",
                  }}
                  className="custom-input"
                />
                <span>{singleSalesOrder?.currency?.code}</span>
              </span>
            </Form.Item>

            <div className="flex flex-row justify-between w-full mb-4">
              <div className="font-bold text-lg">
                {t("sales_order.creation.billing_details.fields.total")}
              </div>
              <div className="flex flex-row gap-2 font-bold">
                <span>{roundNumber(billingDetail?.total)}</span>
                <span>{singleSalesOrder?.currency?.code}</span>
              </div>
            </div>

            <Form.Item
              label={t(
                "sales_order.creation.billing_details.fields.customer_note"
              )}
              name="customerNote"
              rules={[
                {
                  required: true,
                  message: t(
                    "sales_order.creation.billing_details.errors.customer_note_required"
                  ),
                },
              ]}
            >
              <Input.TextArea
                placeholder={t(
                  "sales_order.creation.billing_details.placeholders.customer_note"
                )}
                rows={3}
                onChange={(e) =>
                  handleFieldChange("customerNote", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={t(
                "sales_order.creation.billing_details.fields.terms_and_conditions"
              )}
              name="termsAndConditions"
              rules={[
                {
                  required: true,
                  message: t(
                    "sales_order.creation.billing_details.errors.terms_conditions_required"
                  ),
                },
              ]}
            >
              <Input.TextArea
                placeholder={t(
                  "sales_order.creation.billing_details.placeholders.terms_and_conditions"
                )}
                rows={3}
                onChange={(e) =>
                  handleFieldChange("termsAndCondition", e.target.value)
                }
              />
            </Form.Item>
          </div>

          <div className="flex flex-1 flex-col justify-between p-2">
            <Form.Item
              label={t(
                "sales_order.creation.billing_details.fields.attachments"
              )}
            >
              <Upload
                multiple
                accept=".pdf,.doc,.docx,.jpeg,.jpg,.png"
                customRequest={({ file }) => handleFileUpload([file as File])}
                onDrop={({ dataTransfer }) =>
                  handleFileUpload(Array.from(dataTransfer.files))
                }
                beforeUpload={(file) => {
                  handleFileUpload([file as File]);
                  return false;
                }}
                showUploadList={false}
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    backgroundColor: palette.secondary,
                    width: "100%",
                    color: palette.white,
                  }}
                >
                  {t(
                    "sales_order.creation.billing_details.buttons.upload_files"
                  )}
                </Button>
              </Upload>
              <div className="mt-2">
                {billingDetail.attachements.map((file) => (
                  <div
                    key={file.name}
                    className="flex justify-between items-center mb-2"
                  >
                    {renderFileType(file)}
                    <Button
                      type="link"
                      danger
                      onClick={() => handleRemoveFile(file.name)}
                    >
                      <img
                        src={"/svg/Delete.svg"}
                        className="w-[1rem] h-[1rem] transition-opacity duration-500 ease-in-out"
                      />
                    </Button>
                  </div>
                ))}
              </div>
            </Form.Item>
          </div>
        </div>

        <Form.Item className="w-full">
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: palette.secondary, width: "100%" }}
          >
            {t("sales_order.creation.billing_details.buttons.create_order")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InternalBillingDetails;
