import { Button, Input, Modal, Skeleton, Space } from "antd";
import { useState } from "react";
import NoDataAvailable from "../../../../../components/NoDataAvailable";
import palette from "../../../../../constants/pallete";
import { PlusOutlined } from "@ant-design/icons";
import { Item } from "../../../../../types/groyyo_plus/salesOrderItem";
import {
  getInternalItems,
  getItems,
} from "../../../../../services/orderServiceApi/salesOrderApi";
import CustomFloatButton from "../../../../../components/CustomFloatButton";
import CreateNewItemModal from "../../../../groyyo_plus/Order/create/itemDetail/CreateNewItemModal";

interface ISelectItemFromModal {
  isModalOpen: boolean;
  setIsModalOpen: (val: boolean) => void;
  addToItemList: (item: Item) => void;
}

const SelectItemFromModal = ({
  isModalOpen,
  setIsModalOpen,
  addToItemList,
}: ISelectItemFromModal) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState([]);
  const [loadItems, setLoadItems] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const showModal = async () => {
    setIsModalOpen(true);
    setLoadItems(true);
    try {
      const response = await getInternalItems();
      const responseData = response?.data;
      if (responseData?.status) {
        setItems(responseData?.result);
        setFilteredItems(responseData?.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadItems(false);
    }
  };

  const handleAddItem = () => {
    setIsCreateModalOpen(true);
  };

  const handleSearch = (e: any) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = items.filter((item: any) =>
      item?.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSearchTerm("");
  };

  return (
    <div>
      <CustomFloatButton
        icon={<PlusOutlined style={{ fontSize: "24px" }} />}
        onClick={showModal}
        backgroundColor={palette.secondary}
        color={palette.white}
        bottom={32}
        right={32}
        size={60}
        shadow={true}
      />
      <Modal
        title="Select New Item"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            key="add"
            type="primary"
            onClick={handleAddItem}
            style={{ backgroundColor: palette.secondary, width: "100%" }}
          >
            Add Item
          </Button>,
        ]}
      >
        <Input
          placeholder="Search by item name"
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: "1rem" }}
        />
        <div className="flex gap-4 flex-col">
          {loadItems ? (
            <div className="flex gap-2 flex-col">
              {Array(2)
                .fill(2)
                .map((_, index) => (
                  <Space key={index}>
                    <Skeleton.Image active style={{ width: 70, height: 70 }} />
                    <Skeleton.Node active style={{ width: 300, height: 70 }} />
                  </Space>
                ))}
            </div>
          ) : filteredItems?.length > 0 ? (
            <div className="overflow-y-auto max-h-[50vh] flex gap-2 flex-col">
              {filteredItems.map((item: any) => (
                <div
                  key={item.id}
                  className="flex flex-row items-center justify-between cursor-pointer"
                  onClick={() => {
                    addToItemList(item);
                    handleCancel();
                  }}
                >
                  <div className="flex flex-row gap-4">
                    <img
                      src={item?.imageUrl || "/svg/NoImage.svg"}
                      alt={item?.name}
                      className="!w-[3rem] !h-[3rem] rounded-md"
                    />
                    <div className="flex flex-col justify-start">
                      <p className="font-semibold">{item?.name}</p>
                      <p className="text-xs">
                        {item?.category?.name}/{item?.subCategory?.name}
                      </p>
                    </div>
                  </div>
                  <div className="mr-5">
                    <p className="italic">{item?.unit?.name}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>
      </Modal>

      <CreateNewItemModal
        isCreateModalOpen={isCreateModalOpen}
        setIsCreateModalOpen={setIsCreateModalOpen}
        fetchUpdatedList={showModal}
      />
    </div>
  );
};

export default SelectItemFromModal;
