import { ORDER_SERVICE_API } from ".";
import { orderApi } from "./orderService";

const unitUrl = ORDER_SERVICE_API.UNIT;

export const getUnits = async () => {
  return orderApi.get(`${unitUrl}`);
};

export const getInternalUnits = async () => {
  return orderApi.get(`${unitUrl}/internal`);
};

export const createUnit = async (payload: { name: string }) => {
  return orderApi.post(`${unitUrl}`, payload);
};
