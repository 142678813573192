import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { internalRoute } from '../../../../routes';
import { IoChevronBackOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton } from 'antd';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { IBomSalesOrderItem } from '../../../../types/groyyo_plus/bomSalesOrderItem';
import { setInternalBomSalesOrderItemList, setSelectedInternalBom, setSelectedYetToCreateInternalBomList } from '../../../../redux/groyyo_merchendaiser/createInternalBOMSlice';
import { FaEdit, FaEye } from 'react-icons/fa';
import palette from '../../../../constants/pallete';
import { BOMCreationType } from '../../../../constants/enums';
import { getSalesOrderItems } from '../../../../services/orderServiceApi/salesOrderItemApi';

const CreateInternalBOM = () => {
    const { t }: any = useTranslation();

    const { salesOrderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const { bomSalesOrderItemList, selectedYetToCreateInternalBomList } = useSelector(
        (state: RootState) => state.createInternalBOM
    );

    const fetchSalesOrderItem = async () => {
        setLoading(true);
        try {
          const response = await getSalesOrderItems({
            salesOrderId: +salesOrderId!,
          });
          const responseData = response?.data;
    
          if (responseData?.status) {
            const created = responseData.result.filter(
              (item: IBomSalesOrderItem) => item?.bom !== null
            );
            const yetToCreate = responseData.result.filter(
              (item: IBomSalesOrderItem) => item?.bom === null
            );
    
            dispatch(
                setInternalBomSalesOrderItemList({
                item: created,
                type: BOMCreationType.CREATED,
              })
            );
    
            dispatch(
                setInternalBomSalesOrderItemList({
                item: yetToCreate,
                type: BOMCreationType.YET_TO_CREATE,
              })
            );
          }
        } catch (error) {
          console.error("Error fetching sales order items:", error);
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        if (salesOrderId) {
          fetchSalesOrderItem();
        }
      }, [salesOrderId]);

    const handleItemClick = (bomSalesOrderItem: IBomSalesOrderItem) => {
        dispatch(setSelectedInternalBom(bomSalesOrderItem));
        navigate(
            `${internalRoute}/bom/create-bom-item/${salesOrderId}?type=created`
        );
    };

    const handleCheckboxChange = (id: number) => {
        dispatch(
            setSelectedYetToCreateInternalBomList(
                selectedYetToCreateInternalBomList.includes(id)
                    ? selectedYetToCreateInternalBomList.filter((itemId) => itemId !== id)
                    : [...selectedYetToCreateInternalBomList, id]
            )
        );
    };

    const handleCreateButtonClick = () => {
        navigate(
            `${internalRoute}/bom/create-bom-item/${salesOrderId}?type=yetToCreate`
        );
    };

    return (
        <div>
            <div className="text-lg mb-4 font-bold flex flex-row gap-2 items-center">
                <span
                    onClick={() => navigate(`${internalRoute}/bom`)}
                    className="cursor-pointer"
                >
                    <IoChevronBackOutline style={{ fontWeight: "bold" }} />
                </span>
                <h1 className="text-2xl font-bold">{t("sales_order_bom.create_bom.title")}</h1>
            </div>
            <div className="flex flex-col gap-6 px-4">
                {loading ? (
                    Array.from({ length: 3 })?.map(() => (
                        <Skeleton.Input
                            active
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: 80,
                            }}
                        />
                    ))
                ) : (
                    <>
                        {bomSalesOrderItemList?.created?.length > 0 && (
                            <section>
                                <h2 className="text-lg font-bold mb-2">
                                    {t("sales_order_bom.create_bom.already_created_title")}
                                </h2>
                                <div className="flex flex-col gap-4">
                                    {bomSalesOrderItemList?.created?.map((bomSalesOrderItem) => (
                                        <div
                                            key={bomSalesOrderItem.id}
                                            className="flex flex-row items-center justify-between shadow-md p-2 rounded-lg bg-white"
                                        >
                                            <div className="flex flex-row gap-4">
                                                <img
                                                    src={
                                                        bomSalesOrderItem?.item?.imageUrl ||
                                                        "/svg/NoImage.svg"
                                                    }
                                                    alt={bomSalesOrderItem?.item?.name}
                                                    className="!w-[3rem] !h-[3rem] rounded-md"
                                                />
                                                <div className="flex flex-col justify-start">
                                                    <p className="font-semibold">
                                                        {bomSalesOrderItem?.item?.name}
                                                    </p>
                                                    <p className="text-xs text-gray-600">
                                                        {bomSalesOrderItem?.item?.category?.name}/
                                                        {bomSalesOrderItem?.item?.subCategory?.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mr-5 flex gap-4 items-center">
                                                <span className="italic text-gray-700">
                                                    {bomSalesOrderItem?.amount}
                                                </span>
                                                <span className="italic text-gray-500">
                                                    {bomSalesOrderItem?.item?.unit?.name}
                                                </span>
                                                <span
                                                    className=" cursor-pointer"
                                                    onClick={() => handleItemClick(bomSalesOrderItem)}
                                                >
                                                    <FaEdit size={20} />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}

                        {bomSalesOrderItemList?.yetToCreate?.length > 0 && (
                            <section>
                                <h2 className="text-lg font-bold mb-2">
                                    {t("sales_order_bom.create_bom.yet_to_create_title")}
                                </h2>
                                <div className="flex flex-col gap-4">
                                    {bomSalesOrderItemList?.yetToCreate?.map(
                                        (bomSalesOrderItem) => (
                                            <div
                                                key={bomSalesOrderItem.id}
                                                className="flex flex-row items-center justify-between shadow-md p-2 rounded-lg bg-white"
                                            >
                                                <div className="flex flex-row gap-4 items-center">

                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox h-5 w-5 text-primary cursor-pointer"
                                                        checked={selectedYetToCreateInternalBomList.includes(
                                                            bomSalesOrderItem.id
                                                        )}
                                                        style={{
                                                            accentColor: palette.secondary,
                                                        }}
                                                        onChange={() =>
                                                            handleCheckboxChange(bomSalesOrderItem.id)
                                                        }
                                                    />
                                                    <img
                                                        src={
                                                            bomSalesOrderItem?.item?.imageUrl ||
                                                            "/svg/NoImage.svg"
                                                        }
                                                        alt={bomSalesOrderItem?.item?.name}
                                                        className="!w-[3rem] !h-[3rem] rounded-md"
                                                    />
                                                    <div className="flex flex-col justify-start">
                                                        <p className="font-semibold">
                                                            {bomSalesOrderItem?.item?.name}
                                                        </p>
                                                        <p className="text-xs text-gray-600">
                                                            {bomSalesOrderItem?.item?.category?.name}/
                                                            {bomSalesOrderItem?.item?.subCategory?.name}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mr-5 flex gap-4">
                                                    <span className="italic text-gray-700">
                                                        {bomSalesOrderItem?.amount}
                                                    </span>
                                                    <span className="italic text-gray-500">
                                                        {bomSalesOrderItem?.item?.unit?.name}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                <Button
                                    className="mt-4 px-4 py-2 bg-primary  rounded-lg cursor-pointer"
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        backgroundColor:
                                            selectedYetToCreateInternalBomList.length === 0
                                                ? "#d9d9d9"
                                                : palette.secondary,
                                        width: "100%",
                                        color: palette.white,
                                        cursor:
                                            selectedYetToCreateInternalBomList.length === 0
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                    onClick={handleCreateButtonClick}
                                    disabled={selectedYetToCreateInternalBomList.length === 0}
                                >
                                    {t("sales_order_bom.create_bom.create_button")}
                                </Button>
                            </section>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default CreateInternalBOM