import { BaseSyntheticEvent, useEffect, useState } from "react";
import { GetSalesOrder } from "../../../types/groyyo_plus/salesOrder";
import { getSalesOrder } from "../../../services/orderServiceApi/salesOrderApi";
import { ColumnsType } from "antd/es/table";
import PaginatedTable from "../../../components/PaginatedTable";
import { useNavigate } from "react-router-dom";
import { Button, ConfigProvider, Dropdown, MenuProps } from "antd";
import { useDispatch } from "react-redux";
import { setActiveSalesOrderTab } from "../../../redux/groyyo_plus/order/createSalesOrderSlice";
import {
  CreateSalesOrderTabEnum,
  SalesOrderCreationStatus,
} from "../../../constants/enums";
import palette from "../../../constants/pallete";
import { sourcingRoute } from "../../../routes";
import { ItemType } from "antd/es/menu/interface";
import { DownOutlined } from "@ant-design/icons";
import RolePermission from "../../../utilities/RolePermission/RolePermission";
import { useTranslation } from "react-i18next";
import { getCurrentLocale } from "../../../utilities/language";
import i18n from "../../../internalization/i18n";
import { roundNumber } from "../../../utilities/helper";

const SalesOrder = () => {
  const { t }: any = useTranslation(); 
  const [salesOrders, setSalesOrders] = useState<GetSalesOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const createSalesOrderOption: ItemType[] = [
    { label: t("sales_order.actions.create_single_order"), key: "1" },
    { label: t("sales_order.actions.create_bulk_order"), key: "2" },
  ];

  const onMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "1") {
      handleSalesOrderCreation();
    } else {
      navigate(`${sourcingRoute}/sales-order/bulk`);
    }
  };

  const dispatch = useDispatch();

  const fetchSalesOrder = async () => {
    setLoading(true);
    try {
      const response = await getSalesOrder();
      const responseData = response?.data;
      if (responseData?.status) {
        setSalesOrders(responseData?.result ?? []);
      }
    } catch (error) {
      console.error(t("sales_order.list.messages.fetch_error"), error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSalesOrder();
  }, []);

  const checkAndRedirectToOrderDetail = (
    e: BaseSyntheticEvent,
    record: GetSalesOrder
  ) => {
    e.stopPropagation();
    if (record.status === SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING) {
      navigate(`${sourcingRoute}/sales-order/create`, {
        state: {
          status: SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING,
          id: record?.id,
        },
      });
      dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.ITEM_DETAILS));
    } else if (
      record.status === SalesOrderCreationStatus.SALES_ORDER_BILLING_PENDING
    ) {
      navigate(`${sourcingRoute}/sales-order/create`, {
        state: {
          status: SalesOrderCreationStatus.SALES_ORDER_BILLING_PENDING,
          id: record?.id,
        },
      });
      dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.BILLING_DETAILS));
    } else {
      navigate(`${sourcingRoute}/sales-order/${record.id}`);
    }
  };

  const columns: ColumnsType<GetSalesOrder> = [
    {
      title: t("sales_order.list.columns.so_number"),
      dataIndex: "soNo",
      key: "soNo",
      render: (text, record) => (
        <a
          className="text-blue-400 font-bold"
          onClick={(e) => checkAndRedirectToOrderDetail(e, record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: t("sales_order.list.columns.buyer_name"),
      dataIndex: ["buyer", "name"],
      key: "buyerName",
    },
    {
      title: t("sales_order.list.columns.merchandiser"),
      dataIndex: "merchandiserName",
      key: "merchandiserName",
    },
    {
      title: t("sales_order.list.columns.reference"),
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: t("sales_order.list.columns.so_date"),
      dataIndex: "soDate",
      key: "soDate",
    },
    {
      title: t("sales_order.list.columns.expected_date"),
      dataIndex: "expectedDate",
      key: "expectedDate",
    },
    {
      title: t("sales_order.list.columns.total_amount"),
      key: "totalAmount",
      align: "center",
      render: (_, record) => (
        <span className="font-bold flex justify-end gap-4">
          <p>{roundNumber(record.total)}</p> <p>{record.currency.code}</p>
        </span>
      ),
    },
  ];

  const navigate = useNavigate();

  const handleSalesOrderCreation = () => {
    navigate(`${sourcingRoute}/sales-order/create`, {
      state: { status: SalesOrderCreationStatus.ORDER_DETAIL_PENDING },
    });
  };

  return (
    <ConfigProvider locale={getCurrentLocale(i18n.language)}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">{t("sales_order.title")}</h1>
        <RolePermission action={"CREATE"} module="SALES_ORDERS">
          <div className="flex ">
            <Dropdown.Button
              buttonsRender={(buttons) =>
                buttons?.map((defaultButton, index) =>
                  index === 0 ? (
                    <Button
                      type="text"
                      htmlType="submit"
                      style={{
                        backgroundColor: palette.secondary,
                        borderWidth: 0,
                        color: "white",
                      }}
                      onClick={handleSalesOrderCreation}
                    >
                      {t("sales_order.actions.create_sales_order")}
                    </Button>
                  ) : (
                    <Button
                      type="text"
                      htmlType="submit"
                      style={{
                        backgroundColor: palette.secondary,
                        borderWidth: 0,
                        color: "white",
                      }}
                    >
                      <DownOutlined />
                    </Button>
                  )
                )
              }
              type="text"
              onClick={handleSalesOrderCreation}
              menu={{ items: createSalesOrderOption, onClick: onMenuClick }}
            >
              {t("sales_order.actions.create_sales_order")}
            </Dropdown.Button>
          </div>
        </RolePermission>
      </div>
      <PaginatedTable<GetSalesOrder>
        columns={columns}
        dataSource={salesOrders}
        rowKey="id"
        loading={loading}
      />
    </ConfigProvider>
  );
};

export default SalesOrder;