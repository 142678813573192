import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  IBomItemForm,
  ICreateBomRequestBody,
} from "../../../../types/groyyo_plus/bomItem";
import CollectInternalNewMaterialListItem from "./CollectInternalNewMaterialListItem";
import NoDataAvailable from "../../../../components/NoDataAvailable";
import { Button, message } from "antd";
import {
  calculateIndividualBomTotal,
  formatDate,
} from "../../../../utilities/helper";
import { internalRoute } from "../../../../routes";
import { createBom } from "../../../../services/orderServiceApi/bomApi";
import { setSelectedYetToCreateInternalBomList } from "../../../../redux/groyyo_merchendaiser/createInternalBOMSlice";

interface ICollectInternalNewMaterialsProps {
  collectNewMaterials: IBomItemForm[];
  setCollectNewMaterials: React.Dispatch<React.SetStateAction<IBomItemForm[]>>;
}

const CollectInternalNewMaterials = ({
  collectNewMaterials,
  setCollectNewMaterials,
}: ICollectInternalNewMaterialsProps) => {
  const { selectedYetToCreateInternalBomList } = useSelector(
    (state: RootState) => state.createInternalBOM
  );
  const { salesOrderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t }: any = useTranslation();

  const handleFieldValidation = (
    field: string,
    value: any,
    bomItemId: number | string
  ) => {
    setCollectNewMaterials((prevForms) =>
      prevForms.map((item) =>
        item.id === bomItemId
          ? {
              ...item,
              error: {
                ...item.error,
                [field]:
                  !value ||
                  ((field === "unit" ||
                    field === "currency" ||
                    field === "supplier") &&
                    value?.id === -1),
              },
            }
          : item
      )
    );
  };

  const onValuesChange = (changedValues: any, bomItemId: number | string) => {
    const [field, value] = Object.entries(changedValues)[0];
    setCollectNewMaterials((prevForms) =>
      prevForms.map((data) => {
        if (data.id === bomItemId) {
          const updatedData = { ...data, [field]: value };
          return {
            ...updatedData,
            total: calculateIndividualBomTotal(updatedData),
          };
        }
        return data;
      })
    );

    handleFieldValidation(field, value, bomItemId);
  };

  const validateForms = async () => {
    let isValid = true;
    setCollectNewMaterials((prev) =>
      prev.map((data) => {
        const newErrors = {
          quantity: !data?.quantity,
          unit: !data?.unit?.name,
          supplier: !data?.supplier?.name,
          extra: !data?.extra,
          average: !data?.average,
          currency: !data?.currency.name,
          rate: !data?.rate,
          inHouseDate: !data?.inHouseDate,
        };

        isValid = isValid && !Object.values(newErrors).some((error) => error);

        return { ...data, error: newErrors };
      })
    );
    return isValid;
  };

  const handleCreateBomSubmission = async () => {
    const isValid = await validateForms();
    if (!isValid) {
      message.error(
        t("sales_order_bom.collect_new_materials.validation_failed")
      );
      return;
    }

    let combinedTotal = 0;
    const bomItemCollection = collectNewMaterials?.map((data) => {
      combinedTotal += +data?.total || 0;
      return {
        supplierId: data?.supplier?.id,
        average: +data?.average,
        unitId: data?.unit?.id,
        materialId: data?.material?.id,
        quantity: +data?.quantity,
        rate: +data?.rate,
        extra: +data?.extra,
        total: +data?.total || 0,
        inHouseDate: formatDate(data?.inHouseDate as any),
        currencyId: data?.currency?.id,
      };
    });

    const payload: ICreateBomRequestBody = {
      bomItems: bomItemCollection,
      salesOrderItemIds: selectedYetToCreateInternalBomList,
      total: combinedTotal,
    };

    try {
      const response = await createBom(payload);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(
          t("sales_order_bom.collect_new_materials.success_message")
        );
        dispatch(setSelectedYetToCreateInternalBomList([]));
        setCollectNewMaterials([]);
        navigate(`${internalRoute}/bom/create-bom/${salesOrderId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      {collectNewMaterials?.length > 0 ? (
        collectNewMaterials?.map((collectNewMaterial) => (
          <CollectInternalNewMaterialListItem
            key={collectNewMaterial.id}
            collectNewMaterial={collectNewMaterial}
            formData={collectNewMaterial}
            errors={collectNewMaterial?.error || {}}
            onValuesChange={(changedValues) =>
              onValuesChange(changedValues, collectNewMaterial?.id)
            }
          />
        ))
      ) : (
        <NoDataAvailable text={t("global.no_data")} />
      )}
      {collectNewMaterials?.length > 0 && (
        <Button
          type="primary"
          className="w-full mt-4"
          onClick={handleCreateBomSubmission}
          style={{ backgroundColor: "#CC6A4C", borderColor: "#CC6A4C" }}
        >
          {t("sales_order_bom.collect_new_materials.submit_all")}
        </Button>
      )}
    </div>
  );
};

export default CollectInternalNewMaterials;
