import { Button, Form, Input, message } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import {
  createItemWrapper,
  roundNumber,
} from "../../../../../utilities/helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearItemList,
  setActiveSalesOrderTab,
  setItemList,
  setItemListDetail,
} from "../../../../../redux/groyyo_merchendaiser/internalMerchandiserSalesOrderSlice";
import CustomDropdownWithAdd from "../../../../../components/CustomDropdownWithAdd";
import {
  createTax,
  getInternalTaxes,
  getTaxes,
} from "../../../../../services/orderServiceApi/taxesApi";
import palette from "../../../../../constants/pallete";
import NoDataAvailable from "../../../../../components/NoDataAvailable";
import { useState } from "react";
import { Item } from "../../../../../types/groyyo_plus/salesOrderItem";
import SelectItemFromModal from "./SelectItemFromModal";
import { createSalesOrderItems } from "../../../../../services/orderServiceApi/salesOrderItemApi";
import { internalRoute } from "../../../../../routes";
import {
  CreateSalesOrderTabEnum,
  SalesOrderCreationStatus,
} from "../../../../../constants/enums";

const InternalItemDetails = () => {
  const [form] = Form.useForm();
  const { t }: any = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { itemList } = useSelector(
    (state: RootState) =>
      state.internalMerchandiserSalesOrderSlice.itemDetail.itemListDetail
  );
  const singleSalesOrder: any = useSelector(
    (state: RootState) => state.singleSalesOrder
  );

  const addToItemList = (item: Item) => {
    dispatch(
      setItemList({
        ...item,
        total: 0,
        quantity: 0,
        rate: 0,
        tax: undefined,
      })
    );
  };
  const handleSubmit = async () => {
    const payload = itemList?.map((it) => ({
      itemId: it?.id,
      quantity: it?.quantity,
      rate: it?.rate,
      amount: it?.total,
      taxId: it?.tax?.id,
      salesOrderId: singleSalesOrder?.id,
    }));

    try {
      const response = await createSalesOrderItems(payload);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(
          responseData?.message ??
            t("sales_order.creation.item_details.messages.success")
        );
        dispatch(clearItemList());
        navigate(`${internalRoute}/sales-order/create`, {
          state: {
            status: SalesOrderCreationStatus.SALES_ORDER_BILLING_PENDING,
            id: singleSalesOrder?.id,
          },
        });
        dispatch(
          setActiveSalesOrderTab(CreateSalesOrderTabEnum.BILLING_DETAILS)
        );
      }
    } catch (error) {
      message.error(
        t("sales_order.creation.item_details.errors.submit_failed")
      );
      console.error(error);
    }
  };
  return (
    <div className="p-2">
      {itemList?.length > 0 ? (
        <Form
          layout="vertical"
          className="rounded-md m-2 flex gap-2 flex-col"
          form={form}
          onFinish={handleSubmit}
        >
          {itemList?.map((item) => (
            <div key={item.id} className="border rounded-md p-2">
              <div className="flex flex-row items-center justify-between p-2">
                <div className="flex flex-row gap-4">
                  <img
                    src={item?.imageUrl || "/svg/NoImage.svg"}
                    alt={item?.name}
                    className="!w-[3rem] !h-[3rem] rounded-md"
                  />
                  <div className="flex flex-col justify-start">
                    <p className="font-semibold">{item?.name}</p>
                    <p className="text-xs">
                      {item?.category?.name}/{item?.subCategory?.name}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="font-bold">
                    {t("sales_order.creation.item_details.fields.total")}
                  </p>
                  <span className="flex flex-row gap-2">
                    <p className="italic">{roundNumber(item?.total ?? 0)}</p>
                    {singleSalesOrder?.currency?.code}
                  </span>
                </div>
              </div>

              <div className="flex flex-row space-x-4 items-center p-2">
                <Form.Item
                  label={t("sales_order.creation.item_details.fields.quantity")}
                  name={`quantity_${item.id}`}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "sales_order.creation.item_details.errors.quantity_required"
                      ),
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    autoComplete="off"
                    placeholder={t(
                      "sales_order.creation.item_details.placeholders.quantity"
                    )}
                    onChange={(e) =>
                      dispatch(
                        setItemListDetail({
                          itemId: item.id,
                          field: "quantity",
                          value: Number(e.target.value),
                        })
                      )
                    }
                    className="w-full border-gray-300 rounded-md p-2"
                    value={item.quantity || ""}
                  />
                </Form.Item>

                <Form.Item
                  label={t("sales_order.creation.item_details.fields.rate")}
                  name={`rate_${item.id}`}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "sales_order.creation.item_details.errors.rate_required"
                      ),
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    autoComplete="off"
                    placeholder={t(
                      "sales_order.creation.item_details.placeholders.rate"
                    )}
                    onChange={(e) =>
                      dispatch(
                        setItemListDetail({
                          itemId: item.id,
                          field: "rate",
                          value: Number(e.target.value),
                        })
                      )
                    }
                    className="w-full border-gray-300 rounded-md p-2"
                    value={item.rate || ""}
                  />
                </Form.Item>

                <div className="flex-1">
                  <CustomDropdownWithAdd
                    form={form}
                    label={t("sales_order.creation.item_details.fields.tax")}
                    placeholder={t(
                      "sales_order.creation.item_details.placeholders.tax"
                    )}
                    fetchItems={getInternalTaxes}
                    addItem={createItemWrapper(createTax)}
                    onSelect={(i) =>
                      dispatch(
                        setItemListDetail({
                          itemId: item.id,
                          field: "tax",
                          value: {
                            id: i?.id,
                            name: i?.name,
                            percentage: i?.percentage,
                          },
                        })
                      )
                    }
                    modalFields={[
                      {
                        name: "name",
                        label: t(
                          "sales_order.creation.item_details.fields.tax_name"
                        ),
                        required: true,
                      },
                      {
                        name: "desc",
                        label: t(
                          "sales_order.creation.item_details.fields.tax_desc"
                        ),
                        required: true,
                      },
                      {
                        name: "percentage",
                        label: t(
                          "sales_order.creation.item_details.fields.tax_percentage"
                        ),
                        required: true,
                        type: "number",
                        endIcon: "%",
                      },
                    ]}
                    formItemName={`tax_${item.id}`}
                    required={true}
                    value={item?.tax?.name}
                  />
                </div>
              </div>
            </div>
          ))}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: palette.secondary, width: "100%" }}
            >
              {t("sales_order.creation.item_details.buttons.next")}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <NoDataAvailable />
      )}

      <SelectItemFromModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        addToItemList={addToItemList}
      />
    </div>
  );
};

export default InternalItemDetails;
