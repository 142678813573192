import { Form, Input, Row, Col, DatePicker, Button } from "antd";
import { IBomItemForm } from "../../../../types/groyyo_plus/bomItem";
import dayjs from "dayjs";
import CustomDropdownWithAdd from "../../../../components/CustomDropdownWithAdd";
import { useTranslation } from "react-i18next";
import {
  createSupplier,
  getInternalSupplierList,
  getSupplierList,
} from "../../../../services/orderServiceApi/supplierApi";
import { createItemWrapper } from "../../../../utilities/helper";
import {
  createUnit,
  getInternalUnits,
  getUnits,
} from "../../../../services/orderServiceApi/unitApi";
import CustomDropdown from "../../../../components/CustomDropdown";
import { getAllCurrencies } from "../../../../services/orderServiceApi/salesOrderApi";
import palette from "../../../../constants/pallete";
import { useCallback } from "react";
import { getBomDetail } from "../../../../services/orderServiceApi/bomApi";
import { generateDocDefinition } from "../../../../utilities/generateBomHTML";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

// @ts-ignore
pdfMake.vfs = pdfFonts?.pdfMake?.vfs;

interface InternalBOMListItemProps {
  bomItemForm: IBomItemForm;
  isDisabled: boolean;
  errors: any;
  onValuesChange: (changedValues: any) => void;
}

const InternalBOMListItem = ({
  bomItemForm,
  isDisabled,
  errors,
  onValuesChange,
}: InternalBOMListItemProps) => {
  const [form] = Form.useForm();
  const { t }: any = useTranslation();

  const fetchBomDetail = useCallback(
    async (bomItemId: number) => {
      try {
        const response = await getBomDetail(bomItemId);
        const responseData = response?.data;

        if (responseData?.status) {
          return responseData?.result;
        }
      } catch (error) {
        console.error(
          t("sales_order_bom.bom_list.error_fetching_details"),
          error
        );
      }
    },
    [t]
  );

  const downloadPoDetail = useCallback(
    async (bomItemId: number) => {
      const bomDetails: any = await fetchBomDetail(bomItemId);
      if (!bomDetails) {
        return;
      }
      const docDefinition = generateDocDefinition({
        bomItem: bomDetails?.bomItem,
        user: bomDetails?.user,
      });
      pdfMake
        .createPdf(docDefinition as any)
        .download(t("sales_order_bom.bom_list.download_filename"));
    },
    [fetchBomDetail, t]
  );
  return (
    <div key={bomItemForm?.id} className="shadow-md rounded-lg bg-white p-3">
      <div className="flex flex-row items-center justify-between cursor-pointer p-2">
        <div className="flex flex-row gap-4">
          <img
            src={
              bomItemForm?.material?.type === "Fabric"
                ? bomItemForm?.material?.fabric?.imageUrl || "/svg/NoImage.svg"
                : bomItemForm?.material?.trim?.imageUrl || "/svg/NoImage.svg"
            }
            alt={
              bomItemForm?.material?.type === "Fabric"
                ? bomItemForm?.material?.fabric?.name
                : bomItemForm?.material?.trim?.name
            }
            className="!w-[3rem] !h-[3rem] rounded-md"
          />
          <div className="flex flex-col justify-start">
            <p className="font-semibold">
              {bomItemForm?.material?.type === "Fabric"
                ? bomItemForm?.material?.fabric?.name
                : bomItemForm?.material?.trim?.name}
            </p>
            <p className="text-xs">
              {bomItemForm?.material?.type === "Fabric"
                ? bomItemForm?.material?.fabric?.composition
                : bomItemForm?.material?.trim?.description}
            </p>
          </div>
        </div>
        <div className="mr-5 flex gap-4 font-semibold text-lg">
          <span className="italic">{bomItemForm?.total?.toFixed(2)}</span>
          <span className="italic">{bomItemForm?.currency?.code}</span>
        </div>
      </div>
      <div className="p-2">
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            quantity: bomItemForm?.quantity ?? "",
            unit: bomItemForm?.unit?.name || "",
            supplier: bomItemForm?.supplier?.name || "",
            currency: bomItemForm?.currency?.name || "",
            extra: bomItemForm?.extra ?? "",
            average: bomItemForm?.average ?? "",
            rate: bomItemForm?.rate ?? "",
            inHouseDate: bomItemForm?.inHouseDate
              ? dayjs(bomItemForm?.inHouseDate)
              : null,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <CustomDropdownWithAdd
                form={form}
                label={t("sales_order_bom.bom_list_item.supplier_label")}
                placeholder={t(
                  "sales_order_bom.bom_list_item.supplier_placeholder"
                )}
                fetchItems={getInternalSupplierList}
                addItem={createItemWrapper(createSupplier)}
                onSelect={(item) => onValuesChange({ supplier: item })}
                modalFields={[
                  { name: "name", label: t("common.name"), required: true },
                  {
                    name: "addressLine1",
                    label: t("common.address_line_1"),
                    required: true,
                  },
                  {
                    name: "addressLine2",
                    label: t("common.address_line_2"),
                    required: false,
                  },
                  { name: "city", label: t("common.city"), required: true },
                  { name: "state", label: t("common.state"), required: true },
                  {
                    name: "country",
                    label: t("common.country"),
                    required: true,
                  },
                  {
                    name: "pinCode",
                    label: t("common.pin_code"),
                    required: true,
                  },
                  { name: "gstNo", label: t("common.gst_no"), required: false },
                  {
                    name: "contactPerson",
                    label: t("common.contact_person"),
                    required: false,
                  },
                  {
                    name: "contactNo",
                    label: t("common.contact_no"),
                    required: false,
                  },
                  { name: "email", label: t("common.email"), required: false },
                ]}
                formItemName={`supplier-${bomItemForm.id}`}
                required={true}
                value={bomItemForm?.supplier?.name || ""}
                validateStatus={errors?.supplier ? "error" : undefined}
                help={
                  errors?.supplier
                    ? t("sales_order_bom.bom_list_item.supplier_help")
                    : undefined
                }
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("sales_order_bom.bom_list_item.quantity_label")}
                validateStatus={errors?.quantity ? "error" : ""}
                help={errors?.quantity && "Please enter the quantity"}
              >
                <Input
                  placeholder={t(
                    "sales_order_bom.bom_list_item.quantity_placeholder"
                  )}
                  value={bomItemForm?.quantity}
                  onChange={(e) => onValuesChange({ quantity: e.target.value })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <CustomDropdownWithAdd
                form={form}
                label={t("sales_order_bom.bom_list_item.unit_label")}
                placeholder={t(
                  "sales_order_bom.bom_list_item.unit_placeholder"
                )}
                fetchItems={getInternalUnits}
                addItem={createItemWrapper(createUnit)}
                onSelect={(item) => onValuesChange({ unit: item })}
                modalFields={[
                  {
                    name: "name",
                    label: t("sales_order_bom.bom_list_item.unit_name_label"),
                    required: true,
                  },
                ]}
                formItemName={`unit-${bomItemForm.id}`}
                required={true}
                value={bomItemForm?.unit?.name || ""}
                validateStatus={errors?.unit ? "error" : undefined}
                help={
                  errors?.unit
                    ? t("sales_order_bom.bom_list_item.unit_help")
                    : undefined
                }
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomDropdown
                label={t("sales_order_bom.bom_list_item.currency_label")}
                placeholder={t(
                  "sales_order_bom.bom_list_item.currency_placeholder"
                )}
                fetchItems={getAllCurrencies}
                onSelect={(item) => onValuesChange({ currency: item })}
                name="currency"
                required={true}
                value={bomItemForm?.currency?.name}
                validateStatus={errors?.currency ? "error" : undefined}
                help={
                  errors?.currency
                    ? t("sales_order_bom.bom_list_item.currency_help")
                    : undefined
                }
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("sales_order_bom.bom_list_item.extra_label")}
                validateStatus={errors?.extra ? "error" : ""}
                help={
                  errors?.extra && t("sales_order_bom.bom_list_item.extra_help")
                }
              >
                <Input
                  placeholder={t(
                    "sales_order_bom.bom_list_item.extra_placeholder"
                  )}
                  value={bomItemForm?.extra}
                  onChange={(e) => onValuesChange({ extra: e.target.value })}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("sales_order_bom.bom_list_item.average_label")}
                validateStatus={errors?.average ? "error" : ""}
                help={
                  errors?.average &&
                  t("sales_order_bom.bom_list_item.average_help")
                }
              >
                <Input
                  placeholder={t(
                    "sales_order_bom.bom_list_item.average_placeholder"
                  )}
                  value={bomItemForm?.average}
                  onChange={(e) => onValuesChange({ average: e.target.value })}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("sales_order_bom.bom_list_item.rate_label")}
                validateStatus={errors?.rate ? "error" : ""}
                help={
                  errors?.rate && t("sales_order_bom.bom_list_item.rate_help")
                }
              >
                <Input
                  placeholder={t(
                    "sales_order_bom.bom_list_item.rate_placeholder"
                  )}
                  value={bomItemForm?.rate}
                  onChange={(e) => onValuesChange({ rate: e.target.value })}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("sales_order_bom.bom_list_item.in_house_date_label")}
                validateStatus={errors?.inHouseDate ? "error" : ""}
                help={
                  errors?.inHouseDate &&
                  t("sales_order_bom.bom_list_item.in_house_date_help")
                }
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={t(
                    "sales_order_bom.bom_list_item.in_house_date_placeholder"
                  )}
                  className="w-full"
                  value={bomItemForm?.inHouseDate}
                  onChange={(date) => onValuesChange({ inHouseDate: date })}
                  disabledDate={(current) => {
                    return current && current < dayjs().startOf("day");
                  }}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Button
          type="primary"
          className="w-full mt-4"
          onClick={() =>
            downloadPoDetail(parseInt(bomItemForm?.id?.toString()))
          }
          style={{
            backgroundColor: palette.secondary,
            borderColor: palette.secondary,
          }}
        >
          {t("sales_order_bom.bom_list.download_button")}
        </Button>
      </div>
    </div>
  );
};

export default InternalBOMListItem;
