import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBomSalesOrderItem } from "../../../types/groyyo_plus/bomSalesOrderItem";
import { BOMCreationType } from "../../../constants/enums";

interface InitialState {
    bomSalesOrderItemList: {
        created: IBomSalesOrderItem[],
        yetToCreate: IBomSalesOrderItem[]
    };
    selectedBom: IBomSalesOrderItem | null;
    selectedYetToCreateBomList: number[];
}

const initialState: InitialState = {
    bomSalesOrderItemList: {
        created: [],
        yetToCreate: []
    },
    selectedBom: null,
    selectedYetToCreateBomList: []
}

const createBomSlice = createSlice({
    name: "createBom",
    initialState,
    reducers: {
        setBomSalesOrderItemList: (state, action: PayloadAction<{item: IBomSalesOrderItem[], type:BOMCreationType.CREATED | BOMCreationType.YET_TO_CREATE}>) => {
            const {item, type} = action.payload;

            if(type === BOMCreationType.CREATED) {
                state.bomSalesOrderItemList[type] = item;
            }else if(type === BOMCreationType.YET_TO_CREATE){
                state.bomSalesOrderItemList[type] = item;
            }

        },
        setSelectedBom: (state, action: PayloadAction<IBomSalesOrderItem>) => {
            state.selectedBom = action.payload;
        },
        clearSelectedBom: (state) => {
            state.selectedBom = null;
        },
        setSelectedYetToCreateBomList: (state, action: PayloadAction<number[]>) => {
            state.selectedYetToCreateBomList = action.payload;
        }
    }
});

export const {
    setBomSalesOrderItemList,
    clearSelectedBom,
    setSelectedBom,
    setSelectedYetToCreateBomList
} = createBomSlice.actions;

export default createBomSlice.reducer;