import { Button, DatePicker, Form, Input, InputNumber, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { ISingleSalesOrderResponse } from '../../../../types/groyyo_plus/salesOrder';
import { useState } from 'react';
import palette from '../../../../constants/pallete';
import CustomDropdownWithAdd from '../../../../components/CustomDropdownWithAdd';
import { createCustomer, getCustomers } from '../../../../services/orderServiceApi/customersApi';
import { createItemWrapper } from '../../../../utilities/helper';
import CustomDropdown from '../../../../components/CustomDropdown';
import { getUsers } from '../../../../services/userServiceApi/authApi';
import { setApprover, setBuyer, setCurrency, setDeliveryType, setMerchandiser, setPaymentTerm, setProductType } from '../../../../redux/groyyo_merchendaiser/internalMerchandiserSalesOrderSlice';
import dayjs, { Dayjs } from 'dayjs';
import { createPaymentTerm, getPaymentTerms } from '../../../../services/orderServiceApi/paymentTermApi';
import { createDeliveryType, getDeliveryTypes } from '../../../../services/orderServiceApi/deliveryTypesApi';
import { createProductType, getProductTypes } from '../../../../services/orderServiceApi/productTypesApi';
import { getAllCurrencies } from '../../../../services/orderServiceApi/salesOrderApi';
import { setExpectedDateForOrderDetail, setExpectedMarginForOrderDetail, setReferenceForOrderDetail, setSoDateForOrderDetail } from '../../../../redux/groyyo_merchendaiser/internalSingleSalesOrderSlice';

const EditInternalSalesOrderDetail = ({fetchSalesOrderDetail}:{fetchSalesOrderDetail: any}) => {
    const {t}: any = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const singleSalesOrder: any = useSelector(
        (state: RootState) => state.internalSingleSalesOrder as ISingleSalesOrderResponse | null
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSoDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('DD/MM/YYYY') : '';
        dispatch(setSoDateForOrderDetail(formattedDate));
    };
    const handleExpectedDeliveryDateChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format('DD/MM/YYYY') : '';
        dispatch(setExpectedDateForOrderDetail(formattedDate));
    };
    const handleReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setReferenceForOrderDetail(e.target.value));
    };

    const handleMarginChange = (value: number | null) => {
        if (value !== null) {
              dispatch(setExpectedMarginForOrderDetail(value));
        }
    };

    const handleEditOrderDetailsSubmission = async() => {}

    return (
        <>
            <MdEdit className="cursor-pointer text-gray-600 text-2xl" onClick={showModal} />
            <Modal
                title={t('sales_order.edit_order_detail.edit_order_detail')}

                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {t('sales_order.edit_order_detail.buttons.cancel')}
                    </Button>,
                    <Button
                        key="save"
                        type="primary"
                        onClick={handleEditOrderDetailsSubmission}
                        style={{ backgroundColor: palette.secondary, color: 'white' }}
                    >
                        {t('sales_order.edit_order_detail.buttons.save')}
                    </Button>,
                ]}
                width="80vw"
                maskClosable={false}
            >

                <div className="p-6  overflow-y-scroll h-[80vh]">
                    <Form
                        form={form}
                        layout="vertical"
                        className="flex flex-col gap-4"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <CustomDropdownWithAdd
                                form={form}
                                label={t('sales_order.edit_order_detail.fields.customer_name')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.search_or_select_customer')}
                                fetchItems={getCustomers}
                                addItem={createItemWrapper(createCustomer)}
                                onSelect={(item) => dispatch(setBuyer({ id: item.id, name: item.name }))}
                                modalFields={[
                                    { name: 'name', label: 'Customer Name', required: true }
                                ]}
                                formItemName='buyer'
                                required={true}
                                value={singleSalesOrder?.buyer?.name}
                            />

                            <Form.Item
                                label={t('sales_order.edit_order_detail.fields.reference')}
                                name="reference"
                                rules={[{ required: true, message: t('sales_order.edit_order_detail.placeholder.reference_is_required') }]}
                            >
                                <Input
                                    placeholder={t('sales_order.edit_order_detail.placeholder.enter_reference')}
                                    onChange={handleReferenceChange}
                                    className="w-full border-gray-300 rounded-md p-2 "
                                    value={singleSalesOrder.reference}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('sales_order.edit_order_detail.fields.approver')}
                                name="expectedMargin"
                                rules={[{ required: true, message: t('sales_order.edit_order_detail.placeholder.expected_margin_is_required') }]}
                            >
                                <InputNumber
                                    min={0}
                                    onChange={handleMarginChange}
                                    placeholder="Enter Expected Margin"
                                    className="w-full border-gray-300 rounded-md p-1"
                                    style={{ width: '100%' }}
                                    value={singleSalesOrder.expectedMargin}
                                />
                            </Form.Item>

                            <CustomDropdown
                                label={t('sales_order.edit_order_detail.fields.approver')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.select_merchandiser')}
                                fetchItems={getUsers}
                                onSelect={(item) => dispatch(setMerchandiser({ id: item.id, name: item.name }))}
                                name="merchandiser"
                                required={true}
                                value={singleSalesOrder.merchandiser?.name}
                            />

                            <CustomDropdown
                                label={t('sales_order.edit_order_detail.fields.approver')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.select_approver')}
                                fetchItems={getUsers}
                                onSelect={(item) => dispatch(setApprover({ id: item.id, name: item.name }))}
                                name="approver"
                                required={true}
                                value={singleSalesOrder.approver?.name}
                            />


                            <Form.Item
                                label={t('sales_order.edit_order_detail.fields.so_date')}
                                name="soDate"
                                rules={[{ required: true, message: t('sales_order.edit_order_detail.placeholder.so_date_is_required') }]}
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    value={dayjs(singleSalesOrder?.soDate).isValid() ? dayjs(singleSalesOrder.soDate) : null}
                                    onChange={handleSoDateChange}
                                    className="w-full border border-gray-300 rounded-md"
                                    style={{ height: '40px', padding: '0 11px', width: '100%' }}
                                    disabledDate={(current) => {
                                        return current && current > dayjs().startOf('day');
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('sales_order.edit_order_detail.fields.expected_delivery_date')}
                                name="expectedDeliveryDate"
                                rules={[{ required: true, message: t('sales_order.edit_order_detail.placeholder.expected_delivery_date_is_required') }]}
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    value={dayjs(singleSalesOrder?.expectedDate).isValid() ? dayjs(singleSalesOrder.expectedDate) : null}
                                    onChange={handleExpectedDeliveryDateChange}
                                    className="w-full border border-gray-300 rounded-md"
                                    style={{ height: '40px', padding: '0 11px', width: '100%' }}
                                    disabledDate={(current) => {
                                        return current && current < dayjs().startOf('day');
                                    }}
                                />
                            </Form.Item>


                            <CustomDropdownWithAdd
                                form={form}
                                label={t('sales_order.edit_order_detail.fields.payment_terms')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.search_or_select_payment_terms')}
                                fetchItems={getPaymentTerms}
                                addItem={createItemWrapper(createPaymentTerm)}
                                onSelect={(item) => dispatch(setPaymentTerm({ id: item.id, name: item.name }))}
                                modalFields={[
                                    { name: 'name', label: 'Name', required: true },
                                    { name: 'advancePercentage', label: 'Advance Percentage', required: true },
                                    { name: 'dueDay', label: 'Due Day', required: false },
                                ]}
                                formItemName='paymentTerms'
                                required={true}
                                value={singleSalesOrder.paymentTerm?.name}
                            />

                            <CustomDropdownWithAdd
                                form={form}
                                label={t('sales_order.edit_order_detail.fields.delivery_type')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.search_or_select_delivery_type')}
                                fetchItems={getDeliveryTypes}
                                addItem={createItemWrapper(createDeliveryType)}
                                onSelect={(item) => dispatch(setDeliveryType({ id: item.id, name: item.name }))}
                                modalFields={[
                                    { name: 'name', label: 'Delivery Type Name', required: true }
                                ]}
                                formItemName='deliveryType'
                                required={true}
                                value={singleSalesOrder.deliveryType?.name}
                            />

                            <CustomDropdownWithAdd
                                form={form}
                                label={t('sales_order.edit_order_detail.fields.product_type')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.search_or_select_product_type')}
                                fetchItems={getProductTypes}
                                addItem={createItemWrapper(createProductType)}
                                onSelect={(item) => dispatch(setProductType({ id: item.id, name: item.name }))}
                                modalFields={[
                                    { name: 'name', label: 'Product Type Name', required: true }
                                ]}
                                formItemName='productType'
                                required={true}
                                value={singleSalesOrder.productType?.name}
                            />

                            <CustomDropdown
                                label={t('sales_order.edit_order_detail.fields.currency')}
                                placeholder={t('sales_order.edit_order_detail.placeholder.select_currency')}
                                fetchItems={getAllCurrencies}
                                onSelect={(item) => dispatch(setCurrency({ id: item.id, name: item.name }))}
                                name="currency"
                                required={true}
                                value={singleSalesOrder.currency?.name}
                            />
                        </div>
                        {/* <div className='flex justify-center items-center'>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="w-[5rem]"
                            style={{ backgroundColor: palette.secondary, color: "white" }}
                        >
                            Save
                        </Button>
                    </Form.Item>
                </div> */}
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default EditInternalSalesOrderDetail