import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BOMCreationType } from "../../constants/enums";
import { IBomSalesOrderItem } from "../../types/groyyo_plus/bomSalesOrderItem";

interface InternalBOMState {
    bomSalesOrderItemList: {
        created: IBomSalesOrderItem[];
        yetToCreate: IBomSalesOrderItem[];
    };
    selectedInternalBom: IBomSalesOrderItem | null;
    selectedYetToCreateInternalBomList: number[];
    internalBomNotes: string;
    internalBomApprover: { id: number; name: string } | null;
}

const initialState: InternalBOMState = {
    bomSalesOrderItemList: {
        created: [],
        yetToCreate: []
    },
    selectedInternalBom: null,
    selectedYetToCreateInternalBomList: [],
    internalBomNotes: "",
    internalBomApprover: null,
};

const createInternalBOMSlice = createSlice({
    name: "createInternalBOM",
    initialState,
    reducers: {
        setInternalBomSalesOrderItemList: (
            state,
            action: PayloadAction<{ item: IBomSalesOrderItem[]; type: BOMCreationType.CREATED | BOMCreationType.YET_TO_CREATE }>
        ) => {
            const { item, type } = action.payload;
            state.bomSalesOrderItemList[type] = item;
        },

        setSelectedInternalBom: (state, action: PayloadAction<IBomSalesOrderItem>) => {
            state.selectedInternalBom = action.payload;
        },

        clearSelectedInternalBom: (state) => {
            state.selectedInternalBom = null;
        },

        setSelectedYetToCreateInternalBomList: (state, action: PayloadAction<number[]>) => {
            state.selectedYetToCreateInternalBomList = action.payload;
        },

        setInternalBomNotes: (state, action: PayloadAction<string>) => {
            state.internalBomNotes = action.payload;
        },

        setInternalBomApprover: (state, action: PayloadAction<{ id: number; name: string }>) => {
            state.internalBomApprover = action.payload;
        },

        resetInternalBomState: (state) => {
            state.selectedInternalBom = null;
            state.selectedYetToCreateInternalBomList = [];
            state.internalBomNotes = "";
            state.internalBomApprover = null;
        },
    },
});

export const {
    setInternalBomSalesOrderItemList,
    setSelectedInternalBom,
    clearSelectedInternalBom,
    setSelectedYetToCreateInternalBomList,
    setInternalBomNotes,
    setInternalBomApprover,
    resetInternalBomState,
} = createInternalBOMSlice.actions;

export default createInternalBOMSlice.reducer;